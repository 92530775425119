<style scoped>
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

}
</style>

<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="displaymode=='grouplist'">
              <div class="card-header">
                <h3 class="card-title">Mainboard - Group List</h3>

                <div class="card-tools">
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="grouplist.data"
                  :fields="grouplist_fields"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(actions)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('scoreSheet',row.item)">
                        Score Sheet <br/>
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="grouplist" @pagination-change-page="loadGroupList"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div class="card" v-if="displaymode=='scoreSheet' || displaymode=='scoreSoloDuo' || displaymode=='scoreGroup' || displaymode=='scoreBCostume' || displaymode=='scoreBChoreography'|| displaymode=='scoreBTeamSpirit' || displaymode=='scoreBCharismatic' || displaymode=='scoreOverall' || displaymode=='printScoreSheet' || displaymode=='printAwardSheet'" >
              <div class="card-header">
                <h3 class="card-title">
                  Score Sheet <br/>
                  Group ID: {{sel_competition_group_id}} <br />
                  Event: {{sel_EventName}} <br />
                  Sheet:  {{displaymode}} <br />
                  <!--
                  Dance: {{sel_Dance}} | Age Group : {{sel_age_group}}
                  -->

                  <br/><br/>

                  <div class="no-print">
                      <b-button-group>
                          <b-button v-if="displaymode=='scoreSheet'" variant="primary"  @click="switchScreen('scoreSheet')">All Score</b-button>
                          <b-button v-if="displaymode!='scoreSheet'" variant="success"  @click="switchScreen('scoreSheet')">All Score</b-button>
                          <b-button v-if="displaymode=='printAWardSheet'" variant="primary"  @click="switchScreen('printAwardSheet')">Award</b-button>
                          <b-button v-if="displaymode!='printAwardSheet'" variant="success"  @click="switchScreen('printAwardSheet')">Award</b-button>
                          <b-button v-if="displaymode=='printScoreSheet'" variant="primary"  @click="switchScreen('printScoreSheet')">Print Total</b-button>
                          <b-button v-if="displaymode!='printScoreSheet'" variant="success"  @click="switchScreen('printScoreSheet')">Print Total</b-button>
                          <b-button v-if="displaymode=='scoreSoloDuo'" variant="primary"  @click="switchScreen('scoreSoloDuo')" >Print Cert</b-button>
                          <b-button v-if="displaymode!='scoreSoloDuo'" variant="success"  @click="switchScreen('scoreSoloDuo')" >Print Cert</b-button>
                          <b-button v-if="displaymode=='scoreBCostume'" variant="primary"  @click="switchScreen('scoreBCostume')" >Costume(SD+G)</b-button>
                          <b-button v-if="displaymode!='scoreBCostume'" variant="success"  @click="switchScreen('scoreBCostume')" >Costume(SD+G)</b-button>
                          <b-button v-if="displaymode=='scoreBCharismatic'" variant="primary"  @click="switchScreen('scoreBCharismatic')" >Charismatic(SD)</b-button>
                          <b-button v-if="displaymode!='scoreBCharismatic'" variant="success"  @click="switchScreen('scoreBCharismatic')" >Charismatic(SD)</b-button>
                          <b-button v-if="displaymode=='scoreBChoreography'" variant="primary"  @click="switchScreen('scoreBChoreography')" >Choreography(SD+G)</b-button>
                          <b-button v-if="displaymode!='scoreBChoreography'" variant="success"  @click="switchScreen('scoreBChoreography')" >Choreography(SD+G)</b-button>
                          <b-button v-if="displaymode=='scoreBTeamSpirit'" variant="primary"  @click="switchScreen('scoreBTeamSpirit')" >Team Spirit(G)</b-button>
                          <b-button v-if="displaymode!='scoreBTeamSpirit'" variant="success"  @click="switchScreen('scoreBTeamSpirit')" >Team Spirit(G)</b-button>
                          <b-button v-if="displaymode=='scoreOverall'" variant="primary"  @click="switchScreen('scoreOverall')" >Overall(SD+G)</b-button>
                          <b-button v-if="displaymode!='scoreOverall'" variant="success"  @click="switchScreen('scoreOverall')" >Overall(SD+G)</b-button>
                      </b-button-group>
                  </div>
                  
                </h3>

                <div class="card-tools">
                    <img src='images/logo.png' width="100px">

                    <button type="button" class="btn btn-sm btn-primary no-print" @click="switchScreen('grouplist')">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>                  

                </div>
              </div>

              <!-- /.card-header -->
              <div class="card-body table-responsive p-0" v-if="displaymode=='printAwardSheet'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                <!--
                  <template #cell(actions)="row">
                    
                  </template>
                  -->

                </b-table>
              </div>


              <!-- /.card-header -->
              <div class="card-body table-responsive p-0" v-if="displaymode=='printScoreSheet'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                <!--
                  <template #cell(actions)="row">
                    
                  </template>
                  -->

                </b-table>
              </div>

              <!-- /.card-header -->
              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreSheet'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                <!--
                  <template #cell(actions)="row">
                    
                  </template>
                  -->

                </b-table>
              </div>              

              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreSoloDuo'">
                <div>{{table_header}}</div>
                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>

              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreBCostume'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>

              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreBChoreography'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>



              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreBCharismatic'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>

              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreBTeamSpirit'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>

              <div class="card-body table-responsive p-0" v-if="displaymode=='scoreOverall'">

                <b-table
                  :items="scoreSheet.data"
                  :fields="scoreSheet_fields"
                  primary-key="id"
                  :sort-by="scoreSheet_sortby"
                  :sort-desc="scoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">

                    <button type="button" class="btn btn-sm btn-primary"  @click="newModal(row.item)">
                        Cert
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    
                  </template>

                </b-table>
              </div>              



              <!-- /.card-body -->
              <div class="card-footer">
                  <!--<pagination :data="endlist" @pagination-change-page="loadScoreSheetList"></pagination>-->
              </div>
            </div>
            <!-- /.card -->

            <div class="card" v-if="displaymode=='viewScoreSheet'">
              <div class="card-header">
                <h3 class="card-title">
                  Mainboard - Score Sheet <br/>
                  Group ID: {{sel_competition_group_id}} <br />
                  Event: {{sel_EventName}} <br />
                  Dance: {{sel_Dance}} | Age Group : {{sel_age_group}} <br />
                  EVT: {{sel_EVT}} | Bib: {{sel_bib}} | Competitor: {{sel_competitor}}
                </h3>

                <div class="card-tools">
                    <img src='images/logo.png' width="100px">
                    <button type="button" class="btn btn-sm btn-primary no-print" @click="switchScreen(lastDisplaymode)">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>                  

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="viewScoreSheet.data"
                  :fields="viewScoreSheet_fields"
                  primary-key="id"
                  :sort-by="viewScoreSheet_sortby"
                  :sort-desc="viewScoreSheet_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(award)="row">
                        <b-form-select @change="updateAward(row.item)" v-model="row.item.award" :options="selectedScoreSheetRankOptions"></b-form-select>
                  </template>

                  <template #cell(actions)="row">
                    <!--
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewScoreSheet',row.item)">
                        View
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    -->
                    
                  </template>

                </b-table>

              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <!--<pagination :data="endlist" @pagination-change-page="loadScoreSheetList"></pagination>-->
              </div>
            </div>
            <!-- /.card -->            

          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addCert" tabindex="-1" role="dialog" aria-labelledby="addCert" aria-hidden="true">
            <div style="max-width:1800px; max-height:900px; height:calc(100vh - 225px);" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Certificate Generation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        <button @click.prevent="downloadPdfFile" class="btn btn-success" type="submit">
                            Download [{{ current_pdf_filename }}] <i class="fas fa-download"></i>
                        </button>                        
                        <iframe id="iframeCert" src="" name="modalCert" width="100%" style="height:calc(100vh - 150px);">
                        </iframe>                
                    </div>

                </div>


            </div>
        </div>
        <!-- End of Modal -->           


    </div>
  </section>
</template>


<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    import * as XLSX from 'xlsx';           

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'grouplist',
                lastDisplaymode : 'grouplist',
                editmode: true,
                table_header : '',

                arrGroupScoreName : [ "score_Synchronisation", "score_Technique", "score_Performance", "score_Fluency", "score_Costume", "score_Creativity", "score_Formation", "score_Difficulty", "score_Music" ],
                arrGroup2ScoreName : [ "score_Synchronisation", "score_Technique", "score_Performance", "score_Formation", "score_Costume", "score_Creativity" ],
                arrGroup3ScoreName : [ "score_Synchronisation", "score_Technique", "score_Performance", "score_Formation", "score_Costume", "score_Creativity", "score_Choreography" ],
                arrSoloScoreName : [ "score_Music", "score_Technique", "score_Performance", "score_Fluency", "score_Costume", "score_Creativity", "score_Musicality", "score_Difficulty" ],
                arrSolo2ScoreName : [ "score_Creativity", "score_Technique", "score_Performance", "score_Costume", "score_Charismatic" ],
                arrSolo3ScoreName : [ "score_Creativity", "score_Choreography", "score_Technique", "score_Performance", "score_Costume", "score_Charismatic" ],
                arrGroupScoreTitle : [ "Sync", "Tech", "Perf", "Fluency", "Costume", "Creativity", "Formation", "Difficulty", "Music" ],
                arrGroup2ScoreTitle : [ "Sync", "Tech", "Perf", "Formation", "Costume", "Creativity" ],
                arrGroup3ScoreTitle : [ "Sync", "Tech", "Perf", "Formation", "Costume", "Creativity", "Choreography" ],
                arrSoloScoreTitle : [ "Music", "Tech", "Perf", "Fluency", "Costume", "Creativity", "Musicality", "Difficulty" ],
                arrSolo2ScoreTitle : [ "Creativity", "Tech", "Perf", "Costume", "Charismatic" ],
                arrSolo3ScoreTitle : [ "Creativity", "Choreography", "Tech", "Perf", "Costume", "Charismatic" ],

                selectedScoreSheetRank: '',
                selectedScoreSheetRankOptions: [
                    { value: 'gold(distinction)', text: 'GOLD(DISTINCTION)' },
                    { value: 'gold', text: 'GOLD' },
                    { value: 'silver', text: 'SILVER' },
                    { value: 'bronze', text: 'BRONZE' },
                    { value: 'merit', text: 'MERIT' },
                    { value: '', text: '' },
                    { value: 'The Best Costume Award', text: '最佳服飾' },
                    { value: 'The Most Attractive Award ', text: '最具魅力' },
                    { value: 'The Best Choreography Award', text: '最佳編舞' },
                    { value: 'The Best Team Spirit Award', text: '最優秀團魂' },
                    { value: 'Overall Champion', text: '全場總冠軍' },
                    { value: '', text: '' },
                    { value: 'Champion', text: '冠軍' },
                    { value: '2nd Place', text: '亞軍' },
                    { value: '3rd Place', text: '季軍' },
                ],

                pdf_url: null,
                current_pdf_filename: null,
                current_blob: null,
                current_pdf_url: null,       

                formPDF: new Form({
                    name : 'CHAN Tai Man; 陳小文',
                    eventline1: '兒童及青少年單人表演賽 Solo',
                    eventline2: 'Street Dance - 幼兒組(Age 6 & Under)',
                    award: 'GOLD',
                }),

                grouplist : {},
                grouplist_fields:[
                  {
                    label: 'Gid',
                    key: 'competition_group_id',
                    sortable: false,
                  },
                  {
                    label: 'EventName',
                    key: 'EventName',
                    sortable: false,
                  },
                  {
                    label: 'Dance',
                    key: 'Dance',
                    sortable: false,
                  },
                  {
                    label: 'Age Group',
                    key: 'age_group',
                    sortable: false,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],

                scoreSheet_sortby: '',
                scoreSheet_sortDesc : true,
                scoreSheet : {},
                scoreSheet_fields:[
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,                    
                  },                                      
                ],

                scoreSoloDuo_sortby: '',
                scoreSoloDuo_sortDesc : true,
                scoreSoloDuo : {},
                scoreSoloDuo_fields:[
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,                    
                  },                                      
                ],                

                scoreGroup_sortby: '',
                scoreGroup_sortDesc : true,
                scoreGroup : {},
                scoreGroup_fields:[
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,                    
                  },                                      
                ],                

                viewScoreSheet_sortby: '',
                viewScoreSheet_sortDesc : true,
                viewScoreSheet : {},
                viewScoreSheet_fields:[
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,                    
                  },                                      
                ],


                sel_competition_group_id : '',
                sel_EventName : '',
                sel_Dance : '',
                sel_age_group : '',

                sel_eventid : '',
                sel_eventTitle : '',

                sel_eventid : '',
                sel_bib : '',
                sel_competitor:'',
                sel_EVT : '',

                sel_competition_id : '',
                sel_competition_name : '',
                sel_organisation_id : '',
                sel_organisation_item : [],
                sel_player_id : '',
                sel_player_item : [],
                
                formPayment: new Form({
                    id : '',
                    payment_date : '',
                    payment_type : '',
                    payment_amt: '',
                    remarks: '',
                    payment_file:  [],
                    payment_file_base64: '',
                }),

                
                competition_id:'',
                return_path:'',        
                
                form: new Form({
                    id : '',
                    sort : '',
                    user_dob : '',
                    competition_date : '',
                    user_age : '',
                    competition_id : '',
                    competition_event_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  '',
                    reg_user_ids: [],
                    group_name: '',
                    performance_title:'',
                    performance_description:'',
                    performance_music_path:[],
                    performance_durationInSeconds:'',                    
                    partners : [],
                    reg_user_id : '',
                }),                

                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                partner:  '',
                reg_user_id: '',
                autocompleteItems: [],
                autocompleteApplicantItems: [],
                max_partners: 0,
                debounce: null, 

                performance_music_url:'',
                isPerformanceOptionShow: false,                
                isPerformanceMusicLoaded: false,                 

            }
        },
        methods: {
          switchScreen(screen_id, row_item){
              switch( screen_id ){
                    case 'grouplist':
                        this.loadGroupList();
                        break;
                    case 'scoreSheet':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreSheet();
                        break;
                    case 'printScoreSheet':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadPrintScoreSheet();
                        break;
                    case 'printAwardSheet':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadPrintAwardSheet();
                        break;
                        
                    case 'scoreSoloDuo':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreSoloDuo();
                        break;
                    case 'scoreBCostume':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreBCostume();
                        break;
                    case 'scoreBCharismatic':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreBCharismatic();
                        break;
                    case 'scoreBChoreography':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreBChoreography();
                        break;
                    case 'scoreBTeamSpirit':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreBTeamSpirit();
                        break;
                    case 'scoreOverall':
                        if( row_item != null ){
                            this.sel_competition_group_id = row_item.competition_group_id;                    
                            this.sel_EventName = row_item.EventName;
                            this.sel_Dance = row_item.Dance;
                            this.sel_age_group = row_item.age_group;
                        }
                        this.loadScoreOverall();
                        break;
                    case 'viewScoreSheet':
                        if( row_item != null ){
                            this.sel_eventid = row_item.eventid;                    
                            this.sel_bib = row_item.bib;
                            this.sel_competitor = row_item.competitorName;
                        }                    
                        this.loadViewScoreSheet();    
                        break;                        
                    case 'registration':
                        if( row_item != null ){
                            this.sel_player_id = row_item.id;                    
                            this.sel_player_item = row_item;
                        }                        
                        this.loadRegistrations();
                        break;
                    case 'registrationReport':
                        if( row_item != null ){
                            this.sel_organisation_id = row_item.id;                    
                            this.sel_organisation_item = row_item;
                        }                    
                        this.loadRegistrationReport();    
                        break;                        

              }
              this.lastDisplaymode = this.displaymode;
              this.displaymode = screen_id;
          },
          loadGroupList(page = 1){
            // if(this.$gate.isAdmin()){
              this.grouplist = {};
              let postdata =  { };
              axios.post("api/mainboard/indexGroupList?page=" + page,postdata).then(({ data }) => (this.grouplist = data.data));
            // }
          },          
          loadScoreSheet(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_group_id': [this.sel_competition_group_id]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':
                        this.scoreSheet_fields.push(
                            { label: 'BIB', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Organisation', key: 'organisation', sortable: true, },
                        );

                        var keyfield = ["bib","competitorName","organisation","eventid","EVT","scoremode","EVT","scoremode","total","lastprint","award","actions"];
                        Object.keys(res.data[0]).forEach( ($key) =>{
                          if( ! keyfield.includes($key) ){
                            this.scoreSheet_fields.push(
                                { label: $key, key: $key, sortable: true, },
                            );
                          }
                        });
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = false;

                        break;
                    case 'score_solo':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                /*
                this.scoreSheet_fields.push(
                    { label: 'Action', key: 'actions', sortable: false, },
                );
                */



                this.scoreSheet = res;

                this.scoreSheet_sortby = 'total';


              });
            // }
          },    
          loadPrintScoreSheet(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_group_id': [this.sel_competition_group_id]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;


                this.scoreSheet_fields.push(
                    { label: 'BIB', key: 'bib', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Competitor', key: 'competitorName', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Organisation', key: 'organisation', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Event', key: 'event_type_title', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Dance', key: 'dance', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                );


                switch(score_mode){
                    case 'rank':

                        var keyfield = ["bib","competitorName","organisation","eventid","EVT","scoremode","EVT","scoremode","total","lastprint","award","actions"];
                        Object.keys(res.data[0]).forEach( ($key) =>{
                          if( ! keyfield.includes($key) ){
                            this.scoreSheet_fields.push(
                                { label: $key, key: $key, sortable: true, },
                            );
                          }
                        });

                        this.scoreSheet_sortDesc = false;

                        break;
                    case 'score_solo':


                        /*
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        */

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':
                    case 'score_solo3':

                        /*
                        for(var i = 0; i < this.arrSolo2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':

                        /*
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                    case 'score_group3':

                        /*
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                            );
                        }
                        */

                        this.scoreSheet_sortDesc = true;

                        break;
                }

                this.scoreSheet_fields.push(
                    { label: 'Total', key: 'total', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Last Print', key: 'lastprint', sortable: false, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Award', key: 'award', sortable: true, },
                );


                /*
                this.scoreSheet_fields.push(
                    { label: 'Action', key: 'actions', sortable: false, },
                );
                */



                this.scoreSheet = res;

                this.scoreSheet_sortby = 'total';


              });
            // }
          },    
          loadPrintAwardSheet(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_group_id': [this.sel_competition_group_id]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;


                this.scoreSheet_fields.push(
                    { label: 'BIB', key: 'bib', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Competitor', key: 'competitorName', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Organisation', key: 'organisation', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Event', key: 'event_type_title', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Dance', key: 'dance', sortable: true, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                );


                switch(score_mode){
                    case 'rank':

                        var keyfield = ["bib","competitorName","organisation","eventid","EVT","scoremode","EVT","scoremode","total","lastprint","award","actions"];
                        Object.keys(res.data[0]).forEach( ($key) =>{
                          if( ! keyfield.includes($key) ){
                            this.scoreSheet_fields.push(
                                { label: $key, key: $key, sortable: true, },
                            );
                          }
                        });

                        this.scoreSheet_sortDesc = false;

                        break;
                    case 'score_solo':


                        /*
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        */

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':
                    case 'score_solo3':

                        /*
                        for(var i = 0; i < this.arrSolo2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':

                        /*
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                    case 'score_group3':

                        /*
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                            );
                        }
                        */

                        this.scoreSheet_sortDesc = true;

                        break;
                }

                /*
                this.scoreSheet_fields.push(
                    { label: 'Total', key: 'total', sortable: true, },
                );
                */
                this.scoreSheet_fields.push(
                    { label: 'Last Print', key: 'lastprint', sortable: false, },
                );
                this.scoreSheet_fields.push(
                    { label: 'Award', key: 'award', sortable: true, },
                );


                /*
                this.scoreSheet_fields.push(
                    { label: 'Action', key: 'actions', sortable: false, },
                );
                */



                this.scoreSheet = res;

                this.scoreSheet_sortby = 'total';


              });
            // }
          },             
          loadScoreSoloDuo(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_group_id': [this.sel_competition_group_id]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':
                        this.scoreSheet_fields.push(
                            { label: 'BIB', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Organisation', key: 'organisation', sortable: true, },
                        );

                        var keyfield = ["bib","competitorName","organisation","eventid","EVT","scoremode","EVT","scoremode","total","lastprint","award","actions"];
                        Object.keys(res.data[0]).forEach( ($key) =>{
                          if( ! keyfield.includes($key) ){
                            this.scoreSheet_fields.push(
                                { label: $key, key: $key, sortable: true, },
                            );
                          }
                        });
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = false;

                        break;
                    case 'score_solo':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo2ScoreTitle.length; i++){
                            if( this.arrSolo2ScoreName[i] != "score_Costume"  && this.arrSolo2ScoreName[i] != "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            if( this.arrSolo3ScoreName[i] != "score_Costume"  && this.arrSolo3ScoreName[i] != "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                this.scoreSheet = res;

                this.scoreSheet_sortby = 'total';

                var countDistinction = 0;
                var countGold = 0;
                var countSilver = 0;
                var countBronze = 0;
                var countMerit = 0;
                var countGSB = 0;
                var lastTotal = 0;
                var countNameTotal = 0;
                var lowestGold = 100;
                var lowestSilver = 100;

                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                  if( this.scoreSheet.data[i].total >= 90 ) {
                    this.scoreSheet.data[i].award = 'gold(distinction)';
                    countDistinction++;
                  }else if( this.scoreSheet.data[i].total >= 70 ){
                    countGSB++;
                  }else{
                    this.scoreSheet.data[i].award = 'merit';
                    countMerit++;
                  }
                  countNameTotal += this.scoreSheet.data[i].competitorName.split(';').length;
                }

                if( this.scoreSheet.data.length > 0 ){
                  lastTotal = this.scoreSheet.data[0].total;
                }                
                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                  //console.log("GSB: "+countGSB+",G: " + countGold + "("+Math.floor(countGSB * 0.34)+"),S: " + countSilver + "("+Math.floor(countGSB * 0.33)+"),B: " + countBronze );
                  if( this.scoreSheet.data[i].total >= 90 ) {
                  }else if( this.scoreSheet.data[i].total >= 70 ){
                    //console.log(this.scoreSheet.data[i].total);
                    if( countGold < Math.round(countGSB * 0.34) || (countGold >= Math.floor(countGSB * 0.34) && lowestGold <= this.scoreSheet.data[i].total ) ){
                      this.scoreSheet.data[i].award = 'gold';
                      lowestGold = this.scoreSheet.data[i].total;
                      countGold++;                      
                    }else{
                      if( countSilver < Math.round(countGSB * 0.33) || (countSilver >= Math.floor(countGSB * 0.33) && lowestSilver <= this.scoreSheet.data[i].total ) ){
                        this.scoreSheet.data[i].award = 'silver';
                        lowestSilver = this.scoreSheet.data[i].total;
                        countSilver++;                      
                      }else{
                        this.scoreSheet.data[i].award = 'bronze';
                        countBronze++;                      
                      }
                    }
                  }else{
                  }
                  lastTotal = this.scoreSheet.data[i].total;                  
                } 
                
                this.table_header = "total entry = "+this.scoreSheet.data.length+" | ";
                this.table_header += 'gold(distinction) - ' + countDistinction + ', gold = ' + countGold + ', silver - ' + countSilver + ', bronze - ' + countBronze + ', merit - ' + countMerit + " | ";
                this.table_header += "total cert = " + countNameTotal;

              });
            // }
          },    

          loadScoreBCostume(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              var intSel_competition_group_id = parseInt(this.sel_competition_group_id);
              let postdata =  { 'competition_group_id': [intSel_competition_group_id ]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':
                        this.scoreSheet_fields.push(
                            { label: 'BIB', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Organisation', key: 'organisation', sortable: true, },
                        );

                        var keyfield = ["bib","competitorName","organisation","eventid","EVT","scoremode","EVT","scoremode","total","lastprint","award","actions"];
                        Object.keys(res.data[0]).forEach( ($key) =>{
                          if( ! keyfield.includes($key) ){
                            this.scoreSheet_fields.push(
                                { label: $key, key: $key, sortable: true, },
                            );
                          }
                        });
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = false;

                        break;
                    case 'score_solo':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            if( this.arrSolo2ScoreName[i] == "score_Costume" && this.arrSolo2ScoreName[i] != "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            if( this.arrSolo3ScoreName[i] == "score_Costume" && this.arrSolo3ScoreName[i] != "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            if( this.arrGroup2ScoreName[i] == "score_Costume" && this.arrGroup2ScoreName[i] != "score_Charismatic" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            if( this.arrGroup3ScoreName[i] == "score_Costume" && this.arrGroup3ScoreName[i] != "score_Charismatic" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                this.scoreSheet = res;

                this.scoreSheet_sortby = 'score_Costume';

                var countDistinction = 0;
                var countGold = 0;
                var countSilver = 0;
                var countBronze = 0;
                var countMerit = 0;
                var countGSB = 0;
                var lastTotal = 0;

                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                  if( this.scoreSheet.data[i].total >= 90 ) {
                    this.scoreSheet.data[i].award = 'gold(distinction)';
                    countDistinction++;
                  }else if( this.scoreSheet.data[i].total >= 70 ){
                    countGSB++;
                  }else{
                    this.scoreSheet.data[i].award = 'merit';
                    countMerit++;
                  }
                }

              });
            // }
          },    

          loadScoreBChoreography(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              var intSel_competition_group_id = parseInt(this.sel_competition_group_id);
              let postdata =  { 'competition_group_id': [intSel_competition_group_id ]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':
                        break;
                    case 'score_solo':
                        break;
                    case 'score_solo2':
                        break;
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            if( this.arrSolo3ScoreName[i] == "score_Creativity" || this.arrSolo3ScoreName[i] == "score_Choreography" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'C+C', key: 'cctotal', sortable: true, },
                        );                          
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        for(var i = 0; i < this.scoreSheet.data.length; i++ ){

                            this.scoreSheet.data[i].cctotal = this.scoreSheet.data[i].score_Creativity + this.scoreSheet.data[i].score_Choreography;

                        }

                        this.scoreSheet_sortby = 'cctotal';
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        break;
                    case 'score_group2':
                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            if( this.arrGroup3ScoreName[i] == "score_Creativity" || this.arrGroup3ScoreName[i] == "score_Choreography" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'C+C', key: 'cctotal', sortable: true, },
                        );                        
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                this.scoreSheet = res;


                for(var i = 0; i < this.scoreSheet.data.length; i++ ){

                    this.scoreSheet.data[i].cctotal = this.scoreSheet.data[i].score_Creativity + this.scoreSheet.data[i].score_Choreography;

                }

                this.scoreSheet_sortby = 'cctotal';

              });
            // }
          },              
          loadScoreBTeamSpirit(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              var intSel_competition_group_id = parseInt(this.sel_competition_group_id);
              let postdata =  { 'competition_group_id': [intSel_competition_group_id ]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':
                        break;
                    case 'score_solo':
                        break;
                    case 'score_solo2':
                        break;
                    case 'score_solo3':
                        break;
                    case 'grade':
                    case 'score_group':
                        break;
                    case 'score_group2':
                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            if( this.arrGroup3ScoreName[i] == "score_Synchronisation" || this.arrGroup3ScoreName[i] == "score_Formation" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'S+F', key: 'sftotal', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                this.scoreSheet = res;

                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                    this.scoreSheet.data[i].sftotal = this.scoreSheet.data[i].score_Synchronisation + this.scoreSheet.data[i].score_Formation;
                }

                this.scoreSheet_sortby = 'sftotal';

              });
            // }
          },              

          loadScoreBCharismatic(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_group_id': [this.sel_competition_group_id]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':

                        break;
                    case 'score_solo':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            if( this.arrSolo2ScoreName[i] != "score_Costume" && this.arrSolo2ScoreName[i] == "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );                        
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            if( this.arrSolo3ScoreName[i] != "score_Costume" && this.arrSolo3ScoreName[i] == "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );                        
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            if( this.arrGroup2ScoreName[i] != "score_Costume" && this.arrGroup2ScoreName[i] == "score_Charismatic" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            if( this.arrGroup3ScoreName[i] != "score_Costume" && this.arrGroup3ScoreName[i] == "score_Charismatic" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;                        
                }
                this.scoreSheet = res;

                this.scoreSheet_sortby = 'score_Charismatic';

                var countDistinction = 0;
                var countGold = 0;
                var countSilver = 0;
                var countBronze = 0;
                var countMerit = 0;
                var countGSB = 0;
                var lastTotal = 0;

                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                  if( this.scoreSheet.data[i].total >= 90 ) {
                    this.scoreSheet.data[i].award = 'gold(distinction)';
                    countDistinction++;
                  }else if( this.scoreSheet.data[i].total >= 70 ){
                    countGSB++;
                  }else{
                    this.scoreSheet.data[i].award = 'merit';
                    countMerit++;
                  }
                }

              });
            // }
          },    

          loadScoreOverall(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              var intSel_competition_group_id = parseInt(this.sel_competition_group_id);
              let postdata =  { 'competition_group_id': [intSel_competition_group_id, (intSel_competition_group_id+1) ]  };
              axios.post("api/mainboard/getScoreByCompetitionGroupID",postdata).then(({ data }) => {

                var res = data.data;
                this.scoreSheet = {};
                this.scoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                switch(score_mode){
                    case 'rank':

                        break;
                    case 'score_solo':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        /*
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'score_solo2':
                    case 'score_solo3':

                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        /*
                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            if( this.arrSolo2ScoreName[i] != "score_Costume" && this.arrSolo2ScoreName[i] == "score_Charismatic" ){
                              this.scoreSheet_fields.push(
                                  { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        */
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;
                        
                        break;
                    case 'grade':
                    case 'score_group':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        /*
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.scoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        */
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                    case 'score_group2':
                    case 'score_group3':
                        this.scoreSheet_fields.push(
                            { label: 'bib', key: 'bib', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Event', key: 'event_type_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Dance', key: 'dance', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Age Group', key: 'event_age_group_title', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Competitor', key: 'competitorName', sortable: true, },
                        );
                        /*
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            if( this.arrGroup2ScoreName[i] != "score_Costume" && this.arrGroup2ScoreName[i] == "score_Charismatic" ){                          
                              this.scoreSheet_fields.push(
                                  { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                              );
                            }
                        }
                        */
                        this.scoreSheet_fields.push(
                            { label: 'Total', key: 'total', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Last Print', key: 'lastprint', sortable: false, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Award', key: 'award', sortable: true, },
                        );
                        this.scoreSheet_fields.push(
                            { label: 'Action', key: 'actions', sortable: false, },
                        );

                        this.scoreSheet_sortDesc = true;

                        break;
                }
                this.scoreSheet = res;

                this.scoreSheet_sortby = 'total';

                var countDistinction = 0;
                var countGold = 0;
                var countSilver = 0;
                var countBronze = 0;
                var countMerit = 0;
                var countGSB = 0;
                var lastTotal = 0;

                for(var i = 0; i < this.scoreSheet.data.length; i++ ){
                  if( this.scoreSheet.data[i].total >= 90 ) {
                    this.scoreSheet.data[i].award = 'gold(distinction)';
                    countDistinction++;
                  }else if( this.scoreSheet.data[i].total >= 70 ){
                    countGSB++;
                  }else{
                    this.scoreSheet.data[i].award = 'merit';
                    countMerit++;
                  }
                }

              });
            // }
          },    


          loadViewScoreSheet(page = 1){
            //this.organisations = {};

            // if(this.$gate.isAdmin()){
              let postdata =  { 
                'eventid': this.sel_eventid,
                'bib': this.sel_bib,
              };
              axios.post("api/mainboard/getScoreByEventIDAndBib",postdata).then(({ data }) => {

                var res = data.data;
                this.viewScoreSheet = {};
                this.viewScoreSheet_fields = [];

                if( res.length <= 0 ){
                    return;
                }

                var score_mode = res.data[0].scoremode;
                this.sel_EVT = res.data[0].EVT;

                this.viewScoreSheet_fields.push(
                    { label: 'JID', key: 'jid', sortable: true, },
                );
                this.viewScoreSheet_fields.push(
                    { label: 'Judge', key: 'jname', sortable: true, },
                );
                switch(score_mode){
                    case 'score_solo':

                        for(var i = 0; i < this.arrSoloScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrSoloScoreTitle[i], key: this.arrSoloScoreName[i], sortable: true, },
                            );
                        }
                        break;
                    case 'score_group':
                        for(var i = 0; i < this.arrGroupScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrGroupScoreTitle[i], key: this.arrGroupScoreName[i], sortable: true, },
                            );
                        }
                        break;
                    case 'score_solo2':

                        for(var i = 0; i < this.arrSolo2ScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrSolo2ScoreTitle[i], key: this.arrSolo2ScoreName[i], sortable: true, },
                            );
                        }
                        break;
                    case 'score_group2':
                        for(var i = 0; i < this.arrGroup2ScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrGroup2ScoreTitle[i], key: this.arrGroup2ScoreName[i], sortable: true, },
                            );
                        }
                        break;                        
                    case 'score_solo3':

                        for(var i = 0; i < this.arrSolo3ScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrSolo3ScoreTitle[i], key: this.arrSolo3ScoreName[i], sortable: true, },
                            );
                        }
                        break;
                    case 'score_group3':
                        for(var i = 0; i < this.arrGroup3ScoreTitle.length; i++){
                            this.viewScoreSheet_fields.push(
                                { label: this.arrGroup3ScoreTitle[i], key: this.arrGroup3ScoreName[i], sortable: true, },
                            );
                        }
                        break;                        
                }
                this.viewScoreSheet_fields.push(
                    { label: 'Total', key: 'total', sortable: true, },
                );
                this.viewScoreSheet_fields.push(
                    { label: 'Action', key: 'actions', sortable: false, },
                );


                this.viewScoreSheet = res;

                this.viewScoreSheet_sortby = 'total';


              });
            // }
          },    

          updateAward(row_item){



            switch( this.displaymode ){
                  case 'grouplist':
                      break;
                  case 'scoreSheet':
                      break;
                  case 'scoreSoloDuo':

                      this.$Progress.start();                    
                      axios.post('api/mainboard/updateScoreAward',{
                          action: 'updateAward',
                          eventid: row_item.eventid,
                          ppid: row_item.bib,
                          award: row_item.award,
                      }, ).then((res) => { 

                          if( res.data.success ){
                            $('#addNew').modal('hide');
                            Toast.fire({
                              icon: 'success',
                              title: 'Award updated to ['+ row_item.award +']'
                            });
                          }else{
                            Swal.fire("Failed!", 'Award updated failed', "warning");                  
                          }

                          this.$Progress.finish();              

                      }).catch(() => {
                          Swal.fire("Failed!", 'Award updated failed', "warning");                  
                          this.$Progress.fail();
                      });     
                      break;
                  case 'scoreBCostume':
                      break;      
            }            



          },

          newModal(row_item){

            if( this.current_pdf_url ){
                URL.revokeObjectURL(this.current_pdf_url);
                this.current_blob = null;
                this.current_pdf_filename = "";
                this.current_pdf_url = "";
            }

            $('#addCert').modal('show');

            this.$Progress.start();


            this.$Progress.start();

            switch( this.displaymode ){
                  case 'grouplist':
                      break;
                  case 'scoreSheet':
                      break;
                  case 'scoreSoloDuo':
                      axios.post('api/mainboard/updateScoreAward',{
                          action: 'updateAward',
                          eventid: row_item.eventid,
                          ppid: row_item.bib,
                          award: row_item.award,
                      }, ).then((res) => { 

                          if( res.data.success ){
                            $('#addNew').modal('hide');
                            Toast.fire({
                              icon: 'success',
                              title: 'Award updated to ['+ row_item.award +']'
                            });
                          }else{
                            Swal.fire("Failed!", 'Award updated failed', "warning");                  
                          }

                          this.$Progress.finish();              

                      }).catch(() => {
                          Swal.fire("Failed!", 'Award updated failed', "warning");                  
                          this.$Progress.fail();
                      });            
                      break;
                  case 'scoreBCostume':
                      break;                      
                  case 'viewScoreSheet':
            
                      break;                        
                  case 'registration':
                

                      break;
                  case 'registrationReport':
          

                      break;                        

            }


            axios.post('api/mainboard/updateScoreAward',{
                action: 'updateLastPrint',
                eventid: row_item.eventid,
                ppid: row_item.bib,
            }, ).then((res) => { 

                if( res.data.success ){

                  switch( this.displaymode ){
                        case 'grouplist':
                            this.loadGroupList();
                            break;
                        case 'scoreSheet':

                            this.loadScoreSheet();
                            break;
                        case 'scoreSoloDuo':

                            this.loadScoreSoloDuo();
                            break;
                        case 'viewScoreSheet':
              
                            this.loadViewScoreSheet();    
                            break;                        
                        case 'registration':
                     
                            this.loadRegistrations();
                            break;
                        case 'registrationReport':
                
                            this.loadRegistrationReport();    
                            break;                        

                  }


                  axios.post('/api/pdf/customCert',{
                      name: row_item.competitorName.replaceAll(',',';'),
                      eventline1: row_item.event_type_title,
                      eventline2: row_item.dance + " - " + row_item.event_age_group_title,
                      award: row_item.award.toUpperCase(),
                      bib: row_item.bib,
                  }, { responseType: 'blob' }).then((res) => { 

                      this.current_pdf_filename = decodeURIComponent(res.headers[0]);

                      this.current_blob = new Blob([res.data],{type: res.headers['content-type']});
                      this.current_pdf_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']} ));

                      $('#iframeCert').attr('src', this.current_pdf_url);

                      this.$Progress.finish();              


                  });


                }else{
                  Swal.fire("Failed!", 'Update Last Print Failed', "warning");                  
                }

                this.$Progress.finish();              

            }).catch(() => {
                Swal.fire("Failed!", 'Update Last Print Failed', "warning");                  
                this.$Progress.fail();
            });            

  



          },

          downloadPdfFile(){

            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            //const blobUrl = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = this.current_pdf_url;
            link.download = this.current_pdf_filename;

            // Append link to the body
            document.body.appendChild(link);

            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', { 
                bubbles: true, 
                cancelable: true, 
                view: window 
                })
            );

            // Remove link from body
            document.body.removeChild(link);

          },

          exportXlsx(){

            let export_time = new Date();
            let str_export_tme = export_time.toLocaleString();
            switch( this.displaymode ){
                case 'endlist':

                    break;
                case 'organisation':

                    var tableId = 'organisations_Table';
                    var file_name = this.sel_competition_name + ' - Manage Registration - Organisation List ' + str_export_tme + '.xlsx';
                    var sheet_name = 'Manage Reg - Organisation List';
                    this.render_xlsx( tableId, file_name, sheet_name );

                    break;
                case 'player':
                    var tableId = 'player_Table';
                    var file_name = this.sel_competition_name + ' - Manage Registration - Player List ' + str_export_tme + '.xlsx';
                    var sheet_name = 'Manage Reg - Player List';

                    this.render_xlsx( tableId, file_name, sheet_name );

                  break;
                case 'registrationReport':
                    var tableId = 'registrationReport_Table';
                    var file_name = this.sel_competition_name + ' - '+ this.sel_organisation_item.name1 + " - " + str_export_tme + '.xlsx';
                    var sheet_name = this.sel_organisation_item.name1;

                    this.render_xlsx( tableId, file_name, sheet_name );

                  break;

            }

          },        
          render_xlsx(tableId, file_name, sheetname )  {

            let workbook = XLSX.utils.book_new();
            let tbl = document.getElementById(tableId);                    
            let sheet1 = XLSX.utils.table_to_sheet(tbl);
            let sel_index = 0;
            for( let x = 0; x < this.competitions.data.length; x++ ){
                if( this.competitions.data[x].id == this.sel_competition_id  ){
                    sel_index = x;
                    break;
                }
            }
            let competition_name = this.competitions.data[sel_index].name1;
            XLSX.utils.book_append_sheet(workbook, sheet1, sheetname); 

            XLSX.writeFile(workbook, file_name); 

          },


        },
        mounted() {
            console.log('Mainboard End- Component mounted.')


        },
        created() {
            this.$Progress.start();

            /*
            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;
            
            if( this.$route.params.competition_id !== undefined && this.$route.params.return_path !== undefined ){
              
              this.switchScreen('bib-list',this.competition_id);

            }else{
              this.loadCompetitions();
            }
            */

            //this.loadCategories();
            //this.loadTags();

            this.loadGroupList();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {

        },
    }
</script>

