<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-md-12">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link" href="#20230423" data-toggle="tab">20230423</a></li>&nbsp
                            <li class="nav-item"><a class="nav-link active show" href="#twdc10" data-toggle="tab">TWDC 10th</a></li>
                        </ul>
                    </div><!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <!-- Activity Tab -->
                            <div class="tab-pane" id="20230423">

                                <h3 class="text-center">20230423 3rd TWDSC</h3>

                                <form class="form-horizontal">

                                    <div class="form-group">
                                        <label for="inputName" class="col-sm-2 control-label">Name</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.name" class="form-control" id="inputName" placeholder="Name" :class="{ 'is-invalid': form.errors.has('name') }">
                                        <has-error :form="form" field="name"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputEvent1" class="col-sm-2 control-label">Event Line 1</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.eventline1" class="form-control" id="inputEventLine1" placeholder="EventLine1" :class="{ 'is-invalid': form.errors.has('eventline1') }">
                                        <has-error :form="form" field="eventline1"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputEvent2" class="col-sm-2 control-label">Event Line 2</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.eventline2" class="form-control" id="inputEventLine2" placeholder="EventLine2" :class="{ 'is-invalid': form.errors.has('eventline2') }">
                                        <has-error :form="form" field="eventline2"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputAward" class="col-sm-2 control-label">Award</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.award" class="form-control" id="adward" placeholder="GOLD / SILVER / BROZE" :class="{ 'is-invalid': form.errors.has('award') }">
                                        <has-error :form="form" field="award"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="newModal" type="submit" class="btn btn-success">Generate</button>
                                        </div>
                                    </div>


                                </form>

                            </div>

                            <!-- Activity Tab -->
                            <div class="tab-pane active show" id="twdc10">

                                <h3 class="text-center">20230722-23 10th TWDC</h3>

                                <form class="form-horizontal">

                                    <div class="form-group">
                                        <label for="inputName" class="col-sm-2 control-label">Name 1</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.name1" class="form-control" id="inputName1" placeholder="Name" :class="{ 'is-invalid': form.errors.has('name1') }">
                                        <has-error :form="form" field="name1"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputBib" class="col-sm-2 control-label">Bib</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.bib" class="form-control" id="bib" placeholder="Bib Number" :class="{ 'is-invalid': form.errors.has('bib') }">
                                        <has-error :form="form" field="bib"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputName" class="col-sm-2 control-label">Name 2</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.name" class="form-control" id="inputName2" placeholder="Name" :class="{ 'is-invalid': form.errors.has('name2') }">
                                        <has-error :form="form" field="name2"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="inputEvent1" class="col-sm-2 control-label">Event Line 1</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.eventline1" class="form-control" id="inputEventLine1" placeholder="EventLine1" :class="{ 'is-invalid': form.errors.has('eventline1') }">
                                        <has-error :form="form" field="eventline1"></has-error>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="form-group">
                                        <label for="inputEvent2" class="col-sm-2 control-label">Event Line 2</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.eventline2" class="form-control" id="inputEventLine2" placeholder="EventLine2" :class="{ 'is-invalid': form.errors.has('eventline2') }">
                                        <has-error :form="form" field="eventline2"></has-error>
                                        </div>
                                    </div>
                                    -->
                                    <div class="form-group">
                                        <label for="inputAward" class="col-sm-2 control-label">Award</label>

                                        <div class="col-sm-12">
                                        <input type="" v-model="form.award" class="form-control" id="adward" placeholder="Champion / 2nd place" :class="{ 'is-invalid': form.errors.has('award') }">
                                        <has-error :form="form" field="award"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="newModal" type="submit" class="btn btn-success">Generate</button>
                                        </div>
                                    </div>


                                </form>

                            </div>
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
                </div>
                <!-- end tabs -->   
                
                                         

            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addCert" tabindex="-1" role="dialog" aria-labelledby="addCert" aria-hidden="true">
            <div style="max-width:1800px; max-height:900px; height:calc(100vh - 225px);" class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Certificate Generation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div>
                        <button @click.prevent="downloadPdfFile" class="btn btn-success" type="submit">
                            Download [{{ current_pdf_filename }}] <i class="fas fa-download"></i>
                        </button>                        
                        <iframe id="iframeCert" src="" name="modalCert" width="100%" style="height:calc(100vh - 150px);">
                        </iframe>                
                    </div>

                </div>


            </div>
        </div>                

    </div>
  </section>
</template>


<script>


    export default {
        data(){
            return {
                
                pdf_url: null,
                current_pdf_filename: null,
                current_blob: null,
                current_pdf_url: null,

                form: new Form({
                    name : 'CHAN Tai Man; 陳小文',
                    eventline1: '兒童及青少年單人表演賽 Solo',
                    eventline2: 'Street Dance - 幼兒組(Age 6 & Under)',
                    award: 'GOLD',
                })
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{

          newModal(){

            if( this.current_pdf_url ){
                URL.revokeObjectURL(this.current_pdf_url);
                this.current_blob = null;
                this.current_pdf_filename = "";
                this.current_pdf_url = "";
            }

            $('#addCert').modal('show');

            this.$Progress.start();

            axios.post('/api/pdf/customCert',{
                name: this.form.name,
                eventline1: this.form.eventline1,
                eventline2: this.form.eventline2,
                award: this.form.award,
            }, { responseType: 'blob' }).then((res) => { 

                this.current_pdf_filename = decodeURIComponent(res.headers[0]);

                this.current_blob = new Blob([res.data],{type: res.headers['content-type']});
                this.current_pdf_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']} ));

                $('#iframeCert').attr('src', this.current_pdf_url);

                this.$Progress.finish();              


            });

            /*
            $('#formCert').attr('action','/api/pdf/customCert');
            $('#formCert').attr('name',this.form.name);
            $('#formCert').submit();
            */
     



          },

          downloadPdfFile(){

            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            //const blobUrl = URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set link's href to point to the Blob URL
            link.href = this.current_pdf_url;
            link.download = this.current_pdf_filename;

            // Append link to the body
            document.body.appendChild(link);

            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', { 
                bubbles: true, 
                cancelable: true, 
                view: window 
                })
            );

            // Remove link from body
            document.body.removeChild(link);

          },

        },

        created() {

            this.$Progress.start();
/*
            var $res = axios.get("api/profile")
            .then(
//                ({ data }) => (
                ({ data }) => {
                    this.form.fill(data.data);
                    checkAccessCode(data.data.organisation_access_code);
                }
            );
*/            

            //console.log($res.res.data);
            //console.log(this.form);
            //checkAccessCode(this.form.organisation_access_code);
            this.$Progress.finish();
        }
    }
</script>
