export default class Gate{

    constructor(user){
        this.user = user;
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isUser(){
        return this.user.type === 'user';
    }

    isOrganiser(){
        return this.user.type === 'organiser';
    }    
    isParent(){
        return this.user.type === 'parent';
    }       
    isOpstaff(){
        return this.user.type === 'opstaff';
    }       
    isAdminOrOrganiser(){
        if(this.user.type === 'organiser' || this.user.type === 'admin'){
            return true;
        }
    }    
    isAdminOrOpstaff(){
        if(this.user.type === 'opstaff' || this.user.type === 'admin'){
            return true;
        }
    }    
    
    isAdminOrUser(){
        if(this.user.type === 'user' || this.user.type === 'admin'){
            return true;
        }
    }
}

