<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Event Type List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Sort</th>
                      <th>Title</th>
                      <th>Display Title</th>
                      <th>Dance</th>
                      <th>ppl</th>
                      <th>Fee</th>
                      <th>Mode</th>
                      <th>Action</th>                      
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="EventType in eventtypes.data" :key="EventType.id">

                      <td>{{EventType.id}}</td> 
                      <td>{{EventType.sort}}</td> 
                      <td>{{EventType.title}}</td>
                      <td>{{EventType.display_title}}</td>
                      <td>{{EventType.dance}}</td>
                      <td>{{EventType.ppl}}</td>
                      <td>{{EventType.fee}}</td>
                      <td>{{EventType.grade_mode}}</td>
                      <!-- <td><img v-bind:src="'/' + EventType.photo" width="100" alt="EventType"></td> -->
                      <td>
                        
                        <a href="#" @click="editModal(EventType)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteEventType(EventType.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="eventtypes" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Event Type</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Event Type</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateEventType() : createEventType()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Sort</label>
                            <input v-model="form.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sort') }">
                            <has-error :form="form" field="sort"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Title</label>
                            <input v-model="form.title" type="text" name="title"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('title') }">
                            <has-error :form="form" field="title"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Display Title</label>
                            <input v-model="form.display_title" type="text" name="display_title"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('display_title') }">
                            <has-error :form="form" field="display_title"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Dance</label>
                            <input 
                                v-if="editmode == true"
                                v-model="form.dance" type="text" name="dance"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('dance') }">
                            <select
                                v-if="editmode == false"
                                v-model="form.dance" type="text" name="dance"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('dance') }" multiple>
                                <option>-----Show Dance-----</option>
                                <option>Dancesport</option>
                                <option>Jazz & Ballet</option>
                                <option>Street Dance</option>
                                <option>Folk & Others</option>
                                <option>Other Dance</option>
                                <option>All Dance</option>
                                <option>-----Latin dances-----</option>
                                <option>Cha Cha Cha</option>
                                <option>Rumba</option>
                                <option>Jive</option>
                                <option>Paso Doble</option>
                                <option>Samba</option>
                                <option>CR</option>
                                <option>CJ</option>
                                <option>CRJ</option>
                                <option>CRS</option>
                                <option>SCRJ</option>
                                <option>SCPRJ</option>
                                <option>-----Ballroom dances-----</option>
                                <option>Waltz</option>
                                <option>Tango</option>
                                <option>Foxtrot</option>
                                <option>Quickstep</option>
                                <option>WT</option>
                                <option>WTF</option>
                                <option>WTFQ</option>
                            </select>
                            <has-error :form="form" field="dance"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Number of Participants</label>
                            <input v-model="form.ppl" type="text" name="ppl"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('ppl') }">
                            <has-error :form="form" field="ppl"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Fee_type</label>
                            <input v-model="form.fee_type" type="text" name="fee_type"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('fee_type') }">
                            <has-error :form="form" field="fee_type"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Fee</label>
                            <input v-model="form.fee" type="text" name="fee"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('fee') }">
                            <has-error :form="form" field="fee"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Grade Mode</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                v-model="form.grade_mode"
                                :options="grade_mode_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="grade_mode"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>                             

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge
        },
        data () {
            return {
                editmode: false,
                eventtypes : {},
                form: new Form({
                    id : '',
                    sort : '',
                    title : '',
                    display_title: '',
                    dance: '',
                    ppl: '',
                    fee_type: '',
                    fee: '',
                    grade_mode: '',
                }),
                //categories: [],
              
                //tag:  '',
                //autocompleteItems: [],
                grade_mode_options: [
                    { text: 'Rank', value: 'rank' },
                    { text: 'Score', value: 'score' },
                  ],                    
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/eventType?page=' + page).then(({ data }) => (this.eventtypes = data.data));

              this.$Progress.finish();
          },
          loadEventTypes(){
            // if(this.$gate.isAdmin()){
              axios.get("api/eventType").then(({ data }) => (this.eventtypes = data.data));
            // }
          },
          editModal(EventType){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(EventType);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createEventType(){

              if( this.form.dance.length <= 0 ){
                  Swal.fire("Warning", "No dance selected!", "warning");
                  return;
              }

              this.$Progress.start();

              this.form.post('api/eventType')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadEventTypes();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateEventType(){
              this.$Progress.start();
              this.form.put('api/eventType/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadEventTypes();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteEventType(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/eventType/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadEventTypes();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Event Age Groups - Component mounted.')
        },
        created() {
            this.$Progress.start();

            this.loadEventTypes();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
