<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div v-if="displaymode=='competition'" class="card">
              <div class="card-header">
                <h3 class="card-title">Competition Schedule</h3>

                <div class="card-tools">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Sort</th>
                      <th>Name1</th>
                      <th>Organiser</th>
                      <th>Competition Date</th>
                      <th>Registration Nos.</th>
                      <th>Last Schedule Update</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="Competition in competitions.data" :key="Competition.id">

                      <td>{{Competition.id}}</td> 
                      <td>{{Competition.sort}}</td> 
                      <td>{{Competition.name1}}</td>
                      <td>{{Competition.organiser}}</td>
                      <!--<td>{{Competition.organiser | truncate(90, '...')}}</td>-->
                      <td>{{Competition.competition_date}}</td>
                      <td>{{Competition.count_registrations}}</td>
                      <td>{{Competition.schedule_generated_at}}</td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                       
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->

                        <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('schedule-list',Competition.id)">
                            <i class="fas fa-scroll"></i>
                            View Schedule
                        </button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
        
            <div v-if="displaymode=='schedule-list'" class="card">
              <div class="card-header">
                <h3 class="card-title"><span id="competition_title"></span>Schedule</h3>

                <div class="card-tools">
                  
                    <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition')">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-striped table-responsive p-0">
              <!--<div class="card-body table-responsive p-0">-->
                
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Prg No.</th>
                      <th>Sch. Time</th>
                      <th>Group</th>
                      <th>Type</th>
                      <th>Age Group</th>
                      <th>Dance</th>
                      <th>Zone</th>
                      <th>Participant Nos.</th>
                      <th>Players Bib</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(CompetitionEvent) in competitionEvents.data" :key="CompetitionEvent.id">

                      <td>{{CompetitionEvent.prg_no}}</td> 
                      <td>{{CompetitionEvent.gen_event_est_time}}</td>
                      <td>{{CompetitionEvent.event_group_title}}</td>
                      <td>{{CompetitionEvent.event_type_title}}</td>
                      <td>{{CompetitionEvent.event_age_group_title}}</td>
                      <td>{{CompetitionEvent.dance}}</td>
                      <td>{{CompetitionEvent.zone}}</td>
                      <td>{{CompetitionEvent.count_registrations}}</td>
                      <!-- <td><img v-bind:src="'/' + CompetitionEvent.photo" width="100" alt="CompetitionEvent"></td> -->
                      <td>{{CompetitionEvent.players_bib.replaceAll(',','&nbsp;&nbsp;&nbsp;&nbsp;')}}</td>
                      <td>
                        <!--
                        <a href="#" @click="editScheduleModal(CompetitionEvent)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /                        
                        <a v-if="!(index == 0 && competitionEvents.current_page == 1)" href="#" @click="swapSortCompetitionEvent((competitionEvents.current_page-1)*competitionEvents.per_page+index-1)">
                            <i class="fas fa-arrow-circle-up green"></i>
                        </a>
                        <a v-if="index == 0 && competitionEvents.current_page == 1" href="#">
                            <i class="fas fa-circle green"></i>
                        </a>
                        /
                        <a v-if="!(competitionEvents.current_page == competitionEvents.last_page && competitionEvents.total % competitionEvents.per_page == index+1)" href="#" @click="swapSortCompetitionEvent((competitionEvents.current_page-1)*competitionEvents.per_page+index)">
                            <i class="fas fa-arrow-circle-down purple"></i>
                        </a>
                        <a v-if="competitionEvents.current_page == competitionEvents.last_page && competitionEvents.total % competitionEvents.per_page == index+1" href="#">
                            <i class="fas fa-circle purple"></i>
                        </a>
                        -->

                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitionEvents" @pagination-change-page="getScheduleResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="editSchedule" tabindex="-1" role="dialog" aria-labelledby="editSchedule" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Competition Event</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Competition Event</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="updateCompetitionSchedule()">
                    <div class="modal-body">

                        <input v-model="formEditSch.id" type="hidden" name="id"
                              class="form-control" :class="{ 'is-invalid': form.errors.has('id') }">
                        <input v-model="formEditSch.competition_id" type="hidden" name="competition_id"
                              class="form-control" :class="{ 'is-invalid': form.errors.has('competition_id') }">


                        <div class="form-group">
                            <label>Prg No.</label>
                            <input v-model="formEditSch.prg_no" type="text" name="prg_no"
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('prg_no') }">
                            <has-error :form="formEditSch" field="prg_no."></has-error>
                        </div>

                        <div class="form-group">

                            <label>Schedule Time[HH:MM]</label>

                            <b-form-group
                              label=""
                            >
                              <b-input-group left="Mobile*">
                                <b-form-input
                                  type="text"
                                  v-model="formEditSch.gen_event_est_time"
                                  v-mask="'##:##'"
                                  placeholder="__:__" />
                              </b-input-group>                        

                            </b-form-group>

                        </div>

                        <div class="form-group">
                            <label>Event Group</label>
                            <input v-model="formEditSch.event_group_title" type="text" name="event_group_title" disabled
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('event_group_title') }">
                            <has-error :form="formEditSch" field="event_group_title"></has-error>
                        </div>   

                        <div class="form-group">
                            <label>Event Type</label>
                            <input v-model="formEditSch.event_type_title" type="text" name="event_type_title" disabled
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('event_type_title') }">
                            <has-error :form="formEditSch" field="event_type_title"></has-error>
                        </div>   

                        <div class="form-group">
                            <label>Event Age Group</label>
                            <input v-model="formEditSch.event_age_group_title" type="text" name="event_age_group_title" disabled
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('event_age_group_title') }">
                            <has-error :form="formEditSch" field="event_age_group_title"></has-error>
                        </div>   

                        <div class="form-group">
                            <label>Dance</label>
                            <input v-model="formEditSch.dance" type="text" name="dance" disabled
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('dance') }">
                            <has-error :form="formEditSch" field="dance"></has-error>
                        </div>   

                        <div class="form-group">
                            <label>Zone</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-round"
                                v-model="formEditSch.zone"
                                :options="zone_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>   

                        <div class="form-group">
                            <label>Next Prg No.(e.g. Heats->Final)</label>
                            <input v-model="formEditSch.next_prg_no" type="text" name="next_prg_no"
                                class="form-control" :class="{ 'is-invalid': formEditSch.errors.has('next_prg_no') }">
                            <has-error :form="formEditSch" field="next_prg_no"></has-error>
                        </div>                        


                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success">Update</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- End Modal -->
        <!-- Modal -->
        <div class="modal fade" id="timeSch" tabindex="-1" role="dialog" aria-labelledby="genSch" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Generate Competition Schedule</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="generateSchedule()">
                    <div class="modal-body">

                        <input v-model="formTimeSch.competition_id" type="hidden" name="competition_id"
                              class="form-control" :class="{ 'is-invalid': form.errors.has('competition_id') }">

                        <template>

                        <div class="form-group">
                            <label>Starting Prg No.</label>
                            <input v-model="formTimeSch.prg_no" type="text" name="prg_no"
                                class="form-control" :class="{ 'is-invalid': formTimeSch.errors.has('sort') }">
                            <has-error :form="formTimeSch" field="prg_no"></has-error>
                        </div>

                        <div class="form-group">

                            <label>Start Time[HH:MM]</label>

                            <b-form-group
                              label=""
                              
                            >
                              <b-input-group left="Mobile*">
                                <b-form-input
                                  type="text"
                                  v-model="formTimeSch.startTime"
                                  v-mask="'##:##'"
                                  placeholder="__:__" />
                              </b-input-group>                        

                            </b-form-group>

                        </div>
                        </template>

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success">Run Scheduling</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- End Modal -->

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge
        },
        data () {
            return {
                editmode: false,
                displaymode: 'competition',
                competitionEvents : {},
                competitions : {},                

                competition_id : '',
                return_path:'',       

                numPlayer_bibCols : 8,
                form: new Form({
                    id : '',
                    sort : '',
                    competition_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  '',
                    isopenreg: '',
                    round: '',  
                    zone: '',             
                    gen_event_est_time: '',     
                }),                
                formTimeSch: new Form({
                  competition_id:'',
                  prg_no:'',
                  startTime:'',
                }),     
                formEditSch: new Form({
                  id:'',
                  competition_id:'',
                  prg_no:'',
                  gen_event_est_time:'',
                  event_group_title:'',
                  event_type_title:'',
                  event_age_group_title:'',
                  dance:'',
                  zone:'',
                  next_prg_no:'',
                }),     
                zone_options: [
                    { text: 'Empty', value: '' },
                    { text: 'A', value: 'A' },
                    { text: 'B', value: 'B' },
                    { text: 'C', value: 'C' },
                    { text: 'D', value: 'D' },
                  ],                                        
                round_options: [
                    { text: 'Final', value: 'final' },
                    { text: 'Semi-Final', value: 'semi-final' },
                    { text: 'Heats', value: 'heats' },
                    { text: 'Show', value: 'show' },
                  ],
                isopenreg_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],                  
                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {

          switchScreen(screen_id, competition_id = -1){
              this.competition_id = competition_id;
              switch( screen_id ){
                  case 'competition':

                    if( this.return_path !== undefined ){
                      this.$router.push('/' + this.return_path )                      
                    }                    

                    this.loadCompetitions();
                    break;
                  case 'schedule-list':
                    this.loadCompetitionEvents();
                    break;
              }
              this.displaymode = screen_id;
          },
          generateSchedule(){

              Swal.fire({
                  title: 'Competition Schedule Generation',
                  text: "The existing schedule will be replaced, continue?",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  icon: 'warning',
                  confirmButtonText: 'Yes'
                  }).then((result) => {
                      
                      // Send request to the server
                      
                      if (result.value) {

                        var $postdata = {
                          'competition_id' : this.formTimeSch.competition_id,
                          'startTime' : this.formTimeSch.startTime,
                          'startPrgno' : this.formTimeSch.prg_no,                          
                        };

                        this.$Progress.start();
                        axios.post('/api/competitionEvent/createCompetitionSchedule',$postdata)
                        .then((response) => {
                          
                          if( response.data.success ){
                            Swal.fire(
                              'Competition Schedule Generated!',
                              'The last Prg No is ' + response.data.data.prg_no,
                              'success'
                            );
                            $('#timeSch').modal('hide');
                            this.switchScreen('schedule-list',this.formTimeSch.competition_id)                                
                            this.$Progress.finish();
                          }else{
                            Swal.fire("Competition Schedule Generation", 'Failed!', "warning");
                            this.$Progress.finish();
                          }

                        });

/*                        
                            this.form.delete('/api/competitionEvent/'+id).then(()=>{
                                    Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                    );
                                // Fire.$emit('AfterCreate');
                                this.loadCompetitionEvents();
                            }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                            });
*/                            
                      }
                        
                  })


          },

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/competition/indexBib?page=' + page).then(({ data }) => (this.competitions = data.data));

              this.$Progress.finish();
          },
          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition/indexBib").then(({ data }) => (this.competitions = data.data));
            // }
          },

          getScheduleResults(page = 1) {

              this.$Progress.start();
              
              axios.get('/api/competitionEvent/getCompetitionSchedule', { params: {'page' : page, competition_id: this.competition_id}}).then(({ data }) => (this.competitionEvents = data.data));

              this.$Progress.finish();
          },
          loadCompetiionInfo(){

            axios.post('/api/competition/getCompetitionInfo', { competition_id : this.$route.params.competition_id})
                .then(response => {
                  var $title = "["+response.data.data.organiser+"] / " +
                               "["+response.data.data.competition_date+"] / " +
                               "["+response.data.data.name1+"] - ";
                  $('#competition_title').html($title);

                });

          },
          loadEventGroups(){
              if(this.$gate.isAdmin()){
                axios.post("/api/competitionEvent/getEventGroupInfo").then(({ data }) => (this.eventGroupItems = data.data));
              }
          },     
          loadEventAgeGroups(){
              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.form.competition_id,
                  'event_group_id' : this.form.event_group_id,
                  'event_type_id' : this.form.event_type_id,
                };

                this.$Progress.start();

                axios.post("/api/competitionEvent/getEventAgeGroupInfo",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));

                this.$Progress.finish();    


              }
          },     
          loadEventTypes(){
              if(this.$gate.isAdmin()){
                axios.post("/api/competitionEvent/getEventTypeInfo").then(({ data }) => (this.eventTypeItems = data.data));
              }
          },     
          loadMaxSort(){
              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.form.competition_id,
                };
                axios.post("/api/competitionEvent/getMaxSort",$postdata).then(({ data }) => (this.form.sort = (data.data) ));
              }
          },            
          loadCompetitionEvents(){

              var sel_page = 1;
              if( this.competitionEvent !== undefined ){
                sel_page = this.competitionEvent.current_page;
              }
              axios.get("/api/competitionEvent/getCompetitionSchedule",{ params: {'page' : sel_page, competition_id: this.competition_id}} ).then(({ data }) => (this.competitionEvents = data.data));

          },
          swapSortCompetitionEvent($swapindex){

              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.competition_id,
                  'swap_index' : $swapindex,
                };

                this.$Progress.start();
                axios.post('/api/competitionEvent/swapScheduledProgramNo',$postdata)
                .then((data) => {

                  if(data.data.success){

                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });

                    this.loadCompetitionEvents();

                    this.$Progress.finish();


                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                  }

                })
                .catch(() => {
                    this.$Progress.fail();
                });

              }            
          },
          editScheduleModal(CompetitionEvent){
              this.formEditSch.reset();
              this.formEditSch.fill(CompetitionEvent);
              $('#editSchedule').modal('show');
          },
          editModal(CompetitionEvent){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(CompetitionEvent);

              this.$Progress.start();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.$Progress.finish();    

          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              this.form.competition_id = this.$route.params.competition_id;
              $('#addNew').modal('show');

              this.$Progress.start();
              this.loadMaxSort();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.$Progress.finish();              

          },
          newTimeSchModal(competition_id){
              this.formTimeSch.reset();
              this.formTimeSch.competition_id = competition_id;
              this.formTimeSch.startTime = "10:00";
              this.formTimeSch.prg_no = "1";
              $('#timeSch').modal('show');
          },          
          createCompetitionEvent(){
              this.$Progress.start();

              this.form.post('/api/competitionEvent')
              .then((data)=>{
                if(data.data.success){
                  $('#editSchedule').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });

                  this.loadCompetitionEvents();

                  this.$Progress.finish();


                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCompetitionSchedule(){          

              if(this.$gate.isAdmin()){

                var $postdata = {
                  'id' : this.formEditSch.id,
                  'prg_no' : this.formEditSch.prg_no,
                  'gen_event_est_time' : this.formEditSch.gen_event_est_time,
                  'zone' : this.formEditSch.zone,
                  'next_prg_no' : this.formEditSch.next_prg_no,
                };

                this.$Progress.start();
                axios.post('/api/competitionEvent/updateCompetitionSchedule',$postdata)
                .then((data) => {

                  if(data.data.success){

                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });

                    $('#editSchedule').modal('hide');
                    this.loadCompetitionEvents();

                    this.$Progress.finish();


                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                  }

                });
              }


          },
          updateCompetitionEvent(){
              this.$Progress.start();
              this.form.put('/api/competitionEvent/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });

                  this.loadCompetitionEvents();
                  this.loadEventGroups();

                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteCompetitionEvent(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/competitionEvent/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadCompetitionEvents();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Competition Schedule - Component mounted.')
            //console.log(this.$route.params.competition_id);
            //this.competition_id = this.$route.params.competition_id;
            //this.loadCompetiionInfo();
        },
        created() {
            this.$Progress.start();

            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;            

            //this.loadCompetitionEvents();
            //this.loadCategories();
            //this.loadTags();
            if( this.competition_id > 0 ){
              this.switchScreen('schedule-list', this.competition_id);
            }else{
              this.loadCompetitions();
            }

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
          arrangedPlayer_bib(){

            if( typeof this.competitionEvents.data === 'undefined')
              return;
/*
              return this.competitionEvents.data.reduce((total, item) => {
                return (total + parseInt(item.fee));
              }, 0);
            }else{
              return this.competitionEvents.data.reduce((total, item) => {
                return (total + parseInt(item.reg_nos));
              }, 0);
            }

            console.log(this.competitionEvents);
            const arrangedPlayer_bib = [];
            /*
            this.competitionEvents[index].Player_bib.forEach((item, index) => {
              if (index % this.numPlayer_bibCols === 0) {
                arrangedPlayer_bib.push([])
              }
              arrangedPlayer_bib[arrangedPlayer_bib.length - 1].push(item);
            });
            */
            return arrangedPlayer_bib;

          },
        },
    }
</script>
