<template>
  <section class="content">
    <div class="container-fluid"  v-if="$gate.isAdminOrOpstaff()">
        <div class="row">

          <div class="col-12">
            <div class="card" v-if="displaymode=='masterlist'">
              <div class="card-header">
                <h3 class="card-title">
                    OpStaff - Sign IN <br/> <br/>
                    Competition Date : {{list_competition_date}} <br/>
                    Competition Name : {{list_competition_name}} <br/>
                </h3>

                <div class="card-tools">
                    <Input type="button" id="cmdChangeFilterAll" class="btn btn-sm btn-success" @click="cmdChangeFilter" value="all" />
                    <Input type="button" id="cmdChangeFilterChecked" class="btn btn-sm btn-primary" @click="cmdChangeFilter" value="checked" />
                    <Input type="button" id="cmdChangeFilterUnChecked" class="btn btn-sm btn-primary" @click="cmdChangeFilter" value="unchecked" />
                    <!--
                    <button type="button" class="btn btn-sm btn-primary"  @click="insertBib()">
                        Insert BiB
                    </button>
                    -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <b-row>
                    <b-col sm="12" class="my-1">                
                        <b-form-select v-model="masterlist_filter" :options="optionsOrganisation" size="sm" class="mt-3"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="my-1">                
                        <b-form-group
                          label="Filter"
                          label-for="masterlist-input"
                          label-cols-sm="3"
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="masterlist-input"
                            v-model="masterlist_filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!masterlist_filter" @click="masterlist_filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>     
                    </b-col>
                </b-row>
                
                <b-table
                  :items="masterlist.data"
                  :fields="masterlist_fields"
                  :filter="masterlist_filter"
                  :filter-ignored-fields="masterlist_filterignored_fields"
                  :filter-function="filterTable"
                  primary-key="ppid"
                  sort-by="ppid"
                  responsive="sm"
                  stacked="sm"
                  id="masterlist"
                >

                  <template #cell(img)="row">
                    <img class="img-fluid img-thumbnail" :src="row.item.img" />            
                  </template>                

                  <template #cell(actions)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="signinModal(row.item)">
                        Sign IN <br/>
                        <i class="fas fa-passport"></i>
                        <!--<i class="fas fa-caret-square-right"></i>-->
                    </button>
                    <button type="button" class="btn btn-sm btn-danger"  @click="submitUnsignin(row.item)">
                        Cancel <br/>
                        <i class="fas fa-user-slash"></i>
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="masterlist" @pagination-change-page="loadMasterList"></pagination>
              </div>
            </div>
            <!-- /.card -->

          </div>

        <!-- Modal -->
        <div class="modal fade" id="modalSignin" tabindex="-1" role="dialog" aria-labelledby="modalSignin" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Sign IN</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="onCloseModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="submitSignin()">
                    <div class="modal-body">

                        <div class="form-group">
                            <label>BIB</label>
                            <input v-model="form.ppid" id="ppid" type="text" name="ppid" class="form-control" disabled>
                        </div>
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.CompetitorName" id="CompetitorName" type="text" name="CompetitorName" class="form-control" disabled>
                        </div>
                        <div class="form-group">
                            <label>Organisation</label>
                            <input v-model="form.Organisation" id="Organisation" type="text" name="Organisation" class="form-control" disabled>
                        </div>
                        <div class="form-group">
                            <label>IN Time</label>
                            <input v-model="form.checkinTime" id="checkinTime" type="text" name="checkinTime" class="form-control" disabled>
                        </div>

                        <div class="form-group">
                          <label v-if="isPreviewEnable" >Preview</label>
                          <b-img v-if="isPreviewEnable" id="fileImagePreview" :src="imageSrc" fluid></b-img>
                          <label>Player Photo</label>
                          <b-form-file v-model="form.fileImage" ref="inputFileImage" id="fileImage" name="fileImage" accept="image/*"  capture="environment" @change="onCapturedImage"></b-form-file>
                        </div>                        

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="onCloseModal" data-dismiss="modal">Close</button>
                        <button type="submit" id="cmdSignin" class="btn btn-success">Sign IN</button>
                    </div>
                  </form>
                </div>
            </div>
          </div>          

        </div>
    </div>  
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import Multiselect from 'vue-multiselect';
    import VueRouter from 'vue-router';

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }


    const base64Encode = data =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });    

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, Multiselect
        },
        data () {
            return {

                displaymode: 'masterlist',

                filterSignInMode: 'all',

                list_competition_date: '',
                list_competition_name: '',

                masterlist_filter: null,
                masterlist_filterignored_fields:['img','actions'],
                masterlist : {},
                masterlist_fields:[
                  {
                    label: 'bib',
                    key: 'ppid',
                    sortable: true,
                  },
                  {
                    label: 'Name',
                    key: 'CompetitorName',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'Organisation',
                    sortable: true,
                  },
                  {
                    label: 'IN Time',
                    key: 'checkinTime',
                    sortable: true,
                  },
                  {
                    label: 'img',
                    key: 'img',
                    sortable: false,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],

                form: new Form({
                  ppid: '',
                  CompetitorName:'',
                  Organisation:'',
                  checkinTime:'',
                  fileImage: [],
                }),                

                imageSrc: null,
                isPreviewEnable : false,

                selOrganisation : null,
                optionsOrganisation: [],

            }
        },
        watch: {
          fileImage(newValue, oldValue) {
            console.log('newvalue = ' + newvalue);
            if (newValue !== oldValue) {
              if (newValue) {
                base64Encode(newValue)
                  .then(value => {
                    this.imageSrc = value;
                  })
                  .catch(() => {
                    this.imageSrc = null;
                  });
              } else {
                this.imageSrc = null;
              }
            }
          }
        },        
        methods: {

          loadMasterList(page = 1){
            // if(this.$gate.isAdmin()){
              this.masterlist = {};
              let postdata =  { };
              axios.post("/api/mainboard/indexPPList?page=" + page,postdata).then(({ data }) => {
                this.masterlist = data.data;

                this.list_competition_date = data.message.competition_date;
                this.list_competition_name = data.message.competition_name;
              });
              axios.post("/api/mainboard/opstaffListOrganisation",postdata).then(({ data }) => {
                this.optionsOrganisation = [{value: '', text: ''}];
                data.data.forEach((item) => {
                  this.optionsOrganisation.push({value: item.Organisation, text: item.Organisation});
                });
              });


            // }
          },  
          filterTable(row, keywords) {

            let isPass = false;
            switch(this.filterSignInMode){
                case 'checked':
                  if ( row.checkinTime != null && row.checkinTime != '') {
                    isPass = true;
                  } else {
                    isPass = false;
                  }
                  break;
                case 'unchecked':
                  if ( row.checkinTime == null || row.checkinTime == '') {
                    isPass = true;
                  } else {
                    isPass = false;
                  }
                  break;
                default:
                  isPass = true;
                  break;
            }

            if( isPass == true && ( this.masterlist_filter != 'checked' && this.masterlist_filter != 'unchecked'  ) ){
              if( row.ppid.includes(this.masterlist_filter) || row.CompetitorName.includes(this.masterlist_filter) || row.Organisation.includes(this.masterlist_filter) ){
                  isPass = true;
              }else{
                isPass = false;
              }
            }
            //console.log('['+this.filterSignInMode+'] bbid = '+ row.ppid+', isPass = ' + isPass);
            return isPass;

          },

          signinModal(ppitem){

            if( ppitem.checkinTime != null && ppitem.checkinTime != ''){

              Swal.fire({
                  title: 'Duplicated Sign IN',
                  text: "You want to sign in again?",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, Sign Again!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {

                          $('#modalSignin').modal('show');

                          this.imageSrc = null;
                          this.isPreviewEnable = false;

                          this.form.fill(ppitem);     
                          
                          if( ppitem.img != null ){
                            this.imageSrc = ppitem.img;
                            this.isPreviewEnable = true;                
                          }                          
                        }else{
                            //Swal.fire("Failed!", result.message, "warning");
                        }
                  })      

            }else{
              $('#modalSignin').modal('show');

              this.imageSrc = null;
              this.isPreviewEnable = false;

              this.form.fill(ppitem);     
              
              if( ppitem.img != null ){
                this.imageSrc = ppitem.img;
                this.isPreviewEnable = true;                
              }                    
            }


          },
          onCloseModal(){
          },        
          
          submitUnsignin(ppitem){    

              Swal.fire({
                  title: 'Delete Sign In',
                  text: "You want to revoke sign?",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, Unsign!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {

                        let postdata = ppitem;
                        axios.post("/api/mainboard/opstaffUnSignIN",postdata).then(({ data }) => {

                          if( data.success ){
                            Swal.fire("Success", data.message, "success");                            
                          }else{
                            Swal.fire("Failed", data.message, "warning");                            
                          }
                          this.loadMasterList();
                          this.$Progress.finish();                               

                        });                          

                     
                        }else{
                            //Swal.fire("Failed!", result.message, "warning");
                        }
                  })  

          },      

          submitSignin(){

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'partners'){
                        postdata.append(k,this.form[k]);
                      }else{
                        postdata.append(k,JSON.stringify(this.form[k]));
                      }
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/mainboard/opstaffSignIN', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#modalSignin').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.onCloseModal();

                      this.loadMasterList();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){

                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else if(error.response.status == 413){
                        Toast.fire({
                            icon: 'error',
                            title: 'File Too Large',
                        }); 
                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message,
                        });                    
                      }
                      this.$Progress.fail();
                  });                   

          },
          onCapturedImage(e){
            this.isPreviewEnable = false;
              //console.log(base64Encode(e.target.files[0]));
            //console.log('files = ' + e.target.files.length );
            //console.log( e );
            this.isPreviewEnable = false;
            if( e.target.files.length > 0 ){
              base64Encode(e.target.files[0])
                .then(value => {
                  this.imageSrc = value;
                  this.isPreviewEnable = true;

                })
                .catch(() => {
                  this.imageSrc = null;
                });              
            }
          },

          cmdChangeFilter(e){

            $('#cmdChangeFilterAll').removeClass('btn-success');
            $('#cmdChangeFilterChecked').removeClass('btn-success');
            $('#cmdChangeFilterUnChecked').removeClass('btn-success');
            $('#cmdChangeFilterAll').removeClass('btn-primary');
            $('#cmdChangeFilterChecked').removeClass('btn-primary');
            $('#cmdChangeFilterUnChecked').removeClass('btn-primary');

            switch(e.target.value){
              case 'checked':
                $('#cmdChangeFilterAll').addClass('btn-primary');
                $('#cmdChangeFilterChecked').addClass('btn-success');
                $('#cmdChangeFilterUnChecked').addClass('btn-primary');

                if( this.masterlist_filter == null || this.masterlist_filter == '' || this.masterlist_filter == 'unchecked' ){
                  this.masterlist_filter = 'checked';
                }
                
                break;
              case 'unchecked':
                $('#cmdChangeFilterAll').addClass('btn-primary');
                $('#cmdChangeFilterChecked').addClass('btn-primary');
                $('#cmdChangeFilterUnChecked').addClass('btn-success');
                if( this.masterlist_filter == null || this.masterlist_filter == '' || this.masterlist_filter == 'checked' ){
                  this.masterlist_filter = 'unchecked';
                }                
                break;
              default:
                $('#cmdChangeFilterAll').addClass('btn-success');
                $('#cmdChangeFilterChecked').addClass('btn-primary');
                $('#cmdChangeFilterUnChecked').addClass('btn-primary');
                if( this.masterlist_filter == 'checked' || this.masterlist_filter == 'unchecked' ){
                  this.masterlist_filter = '';
                }                    
                break;
            }
            this.filterSignInMode = e.target.value;

          },


        },
        mounted() {
            console.log('Opuser - SignIN mounted.')
        },
        unmounted() {
        },     
        created() {
            this.$Progress.start();

            this.loadMasterList();

            this.$Progress.finish();
        },        
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {

        },        
    }    

</script>    
