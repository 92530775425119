export default [

    { path: '/test', component: require('./components/test.vue').default },

    { path: '/opstaff/signin', component: require('./components/competition/OpstaffSignin.vue').default },

    { path: '/dashboard/:competition_id', component: require('./components/Dashboard.vue').default },
    { path: '/eventboard', component: require('./components/EventBoard.vue').default },


    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '/competitions', component: require('./components/competition/Competitions.vue').default },
    { path: '/competitionsBib', component: require('./components/competition/CompetitionsBib.vue').default },
    { path: '/competitionEvents/:competition_id', component: require('./components/competition/CompetitionEvents.vue').default },
    { path: '/competitionSchedule', component: require('./components/competition/CompetitionSchedule.vue').default },
    { path: '/competitionsPayment', component: require('./components/competition/CompetitionPayment.vue').default },
    { path: '/organisations', component: require('./components/competition/Organisations.vue').default },
    { path: '/eventGroups', component: require('./components/competition/EventGroups.vue').default },
    { path: '/eventAgeGroups', component: require('./components/competition/EventAgeGroups.vue').default },
    { path: '/eventTypes', component: require('./components/competition/EventTypes.vue').default },
    { path: '/eventTypes', component: require('./components/competition/EventTypes.vue').default },

    { path: '/eventRegistrations', component: require('./components/competition/EventRegistrations.vue').default },
    { path: '/eventRegistrationEvents/:competition_id/:return_path', component: require('./components/competition/EventRegistrationEvents.vue').default },
    { path: '/userRegistrationBib', component: require('./components/competition/UserRegistrationBib.vue').default },
    { path: '/userRegistrationBib/:competition_id/:return_path', component: require('./components/competition/UserRegistrationBib.vue').default },
    { path: '/userRegistrationSchedule', component: require('./components/competition/UserRegistrationSchedule.vue').default },
    { path: '/userRegistrationSchedule/:competition_id/:return_path', component: require('./components/competition/UserRegistrationSchedule.vue').default },
    { path: '/userRegistrationPayment', component: require('./components/competition/UserRegistrationPayment.vue').default },
    { path: '/userRegistrationPayment/:competition_id/:return_path', component: require('./components/competition/UserRegistrationPayment.vue').default },

    { path: '/organisationUsers', component: require('./components/competition/OrganisationUsers.vue').default },
    { path: '/organisationRegistrationPayments', component: require('./components/competition/OrganisationRegistrationPayments.vue').default },
    { path: '/organisationRegistrations', component: require('./components/competition/OrganisationRegistrations.vue').default },
    { path: '/organisationRegistrationEvents/:competition_id', component: require('./components/competition/OrganisationRegistrationEvents.vue').default },
    { path: '/organisationRegistrationEvents/:competition_id/:return_path', component: require('./components/competition/OrganisationRegistrationEvents.vue').default },
    { path: '/organisationRegistrationBib', component: require('./components/competition/OrganisationRegistrationBib.vue').default },
    { path: '/organisationRegistrationBib/:competition_id/:return_path', component: require('./components/competition/OrganisationRegistrationBib.vue').default },
    { path: '/organisationRegistrationSchedule', component: require('./components/competition/OrganisationRegistrationSchedule.vue').default },
    { path: '/organisationRegistrationSchedule/:competition_id/:return_path', component: require('./components/competition/OrganisationRegistrationSchedule.vue').default },

    { path: '/managementRegistrations', component: require('./components/competition/ManagementRegistrations.vue').default },
    { path: '/managementGroupPayment', component: require('./components/competition/ManagementGroupPayments.vue').default },
    { path: '/managementUsers', component: require('./components/competition/ManagementUsers.vue').default },
    { path: '/managementSubmissions', component: require('./components/competition/ManagementSubmissions.vue').default },

    { path: '/mainboardMaster', component: require('./components/competition/MainboardMaster.vue').default },
    { path: '/mainboardEnd', component: require('./components/competition/MainboardEnd.vue').default },
    { path: '/mainboardGroup', component: require('./components/competition/MainboardGroup.vue').default },
    { path: '/mainboardCustomCert', component: require('./components/competition/MainboardCustomCert.vue').default },
    { path: '/mainboardResultCert', component: require('./components/competition/MainboardResultCert.vue').default },
    { path: '/mainboardReport', component: require('./components/competition/MainboardReport.vue').default },

    { path: '/report', component: require('./components/competition/Report.vue').default },
    
    { path: '*', component: require('./components/NotFound.vue').default }
];
