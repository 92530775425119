<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12">
        
              <div class="card" v-if="displaymode=='competition'">
                <div class="card-header">
                    <h3 class="card-title">Report - Competition List</h3>

                    <div class="card-tools">
                    
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">

                    <b-table
                    :items="competitions.data"
                    :fields="competitions_fields"
                    primary-key="id"
                    responsive="sm"
                    stacked="sm"
                    >

                    <template #cell(is_opened)="row">

                        <b-badge v-if="row.item.isopened==='true'" variant="success">Open</b-badge>
                        <b-badge v-if="row.item.isopened==='false'" variant="danger">Close</b-badge>
                        <b-badge v-if="row.item.isvisible==='true'" variant="success">Visible</b-badge>
                        <b-badge v-if="row.item.isvisible==='false'" variant="danger">Hide</b-badge>

                    </template>

                    <template #cell(actions)="row">
                        <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('reportNameList',row.item)">
                            Select
                            <i class="fas fa-caret-square-right"></i>                        
                        </button>
                    </template>

                    </b-table>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <pagination :data="competitions" @pagination-change-page="loadCompetitions"></pagination>
                </div>
              </div>
                <!-- /.card -->

              <div class="card" v-if="displaymode=='reportNameList' || displaymode=='reportChingCheukList' || displaymode=='reportEventList' || displaymode=='reportPlayerList'">
                <div class="card-header">
                    <h3 class="card-title">
                        Report<br>
                        {{ sel_competition_name }}<br>
                        <br/>
                        <div>
                            <b-button-group>
                                <b-button variant="success"  @click="switchScreen('reportNameList')">Name List</b-button>
                                <b-button variant="success"  @click="switchScreen('reportChingCheukList')" >Ching Cheuk List</b-button>
                                <b-button variant="success"  @click="switchScreen('reportEventList')" >Event List</b-button>
                                <b-button variant="success"  @click="switchScreen('reportPlayerList')" >Player List</b-button>
                                <b-button variant="success" disabled>Payment</b-button>
                                <b-button variant="success" disabled>Music</b-button>
                            </b-button-group>
                        </div>

                    </h3>                   

                    <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition',null)">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>    
                    <b-dropdown id="dropdown-right" text="Export" variant="primary" class="m-3">
                      <b-dropdown-item href="#" @click="exportXlsx()">xlsx</b-dropdown-item>
                    </b-dropdown>   
                                        
                    </div>
                </div>

                <!-- /.card-header -->
                <div v-if="displaymode=='reportNameList'">
                  <div class="card-body table-responsive p-0" >

                      <b-row>
                          <b-col lg="6" class="my-1">                
                              <b-form-group
                              label="Filter"
                              label-for="rpt_namelist-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-input-group size="sm">
                                  <b-form-input
                                  id="rpt_namelist-input"
                                  v-model="rpt_namelist_filter"
                                  type="search"
                                  placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                  <b-button :disabled="!rpt_namelist_filter" @click="rpt_namelist_filter = ''">Clear</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                              </b-form-group>     
                          </b-col>
                      </b-row>

                      <b-table
                          :items="rpt_namelist.data"
                          :fields="rpt_namelist_fields"
                          :sort-by.sync="rpt_namelist_sortBy"
                          :sort-desc.sync="rpt_namelist_sortDesc"
                          :filter="rpt_namelist_filter"                    
                          id="rpt_namelist_Table"
                          primary-key="item_no"
                          responsive="sm"
                          stacked="sm"
                      >
                      </b-table>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                      <pagination :data="rpt_namelist" @pagination-change-page="loadRptNameList"></pagination>
                  </div>
                  <!-- /.card -->   
                </div>

                <!-- /.card-header -->
                <div v-if="displaymode=='reportChingCheukList'">
                  <div class="card-body table-responsive p-0" >

                      <b-row>
                          <b-col lg="6" class="my-1">                
                              <b-form-group
                              label="Filter"
                              label-for="rpt_chingcheuklist-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-input-group size="sm">
                                  <b-form-input
                                  id="rpt_chingcheuklist-input"
                                  v-model="rpt_chingcheuklist_filter"
                                  type="search"
                                  placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                  <b-button :disabled="!rpt_chingcheuklist_filter" @click="rpt_chingcheuklist_filter = ''">Clear</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                              </b-form-group>     
                          </b-col>
                      </b-row>

                      <b-table
                          :items="rpt_chingcheuklist.data"
                          :fields="rpt_chingcheuklist_fields"
                          :sort-by.sync="rpt_chingcheuklist_sortBy"
                          :sort-desc.sync="rpt_chingcheuklist_sortDesc"
                          :filter="rpt_chingcheuklist_filter"                    
                          id="rpt_chingcheuklist_Table"
                          primary-key="item_no"
                          responsive="sm"
                          stacked="sm"
                      >
                      </b-table>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                      <pagination :data="rpt_namelist" @pagination-change-page="loadRptNameList"></pagination>
                  </div>
                  <!-- /.card -->   
                </div>

                <!-- /.card-header -->
                <div v-if="displaymode=='reportEventList'">
                  <div class="card-body table-responsive p-0" >

                      <b-row>
                          <b-col lg="6" class="my-1">                
                              <b-form-group
                              label="Filter"
                              label-for="rpt_eventlist-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-input-group size="sm">
                                  <b-form-input
                                  id="rpt_eventlist-input"
                                  v-model="rpt_eventlist_filter"
                                  type="search"
                                  placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                  <b-button :disabled="!rpt_eventlist_filter" @click="rpt_eventlist_filter = ''">Clear</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                              </b-form-group>     
                          </b-col>
                      </b-row>

                      <b-table
                          :items="rpt_eventlist.data"
                          :fields="rpt_eventlist_fields"
                          :sort-by.sync="rpt_eventlist_sortBy"
                          :sort-desc.sync="rpt_eventlist_sortDesc"
                          :filter="rpt_eventlist_filter"                    
                          id="rpt_eventlist_Table"
                          primary-key="item_no"
                          responsive="sm"
                          stacked="sm"
                      >
                      </b-table>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                      <pagination :data="rpt_eventlist" @pagination-change-page="loadRptEventList"></pagination>
                  </div>
                  <!-- /.card -->   
                </div>


                <!-- /.card-header -->
                <div v-if="displaymode=='reportPlayerList'">
                  <div class="card-body table-responsive p-0" >

                      <b-row>
                          <b-col lg="6" class="my-1">                
                              <b-form-group
                              label="Filter"
                              label-for="rpt_playerlist-input"
                              label-cols-sm="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0"
                              >
                              <b-input-group size="sm">
                                  <b-form-input
                                  id="rpt_playerlist-input"
                                  v-model="rpt_playerlist_filter"
                                  type="search"
                                  placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                  <b-button :disabled="!rpt_playerlist_filter" @click="rpt_playerlist_filter = ''">Clear</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                              </b-form-group>     
                          </b-col>
                      </b-row>

                      <b-table
                          :items="rpt_playerlist.data"
                          :fields="rpt_playerlist_fields"
                          :sort-by.sync="rpt_playerlist_sortBy"
                          :sort-desc.sync="rpt_playerlist_sortDesc"
                          :filter="rpt_playerlist_filter"                    
                          id="rpt_playerlist_Table"
                          primary-key="item_no"
                          responsive="sm"
                          stacked="sm"
                      >
                      </b-table>
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                      <pagination :data="rpt_playerlist" @pagination-change-page="loadRptPlayerList"></pagination>
                  </div>
                  <!-- /.card -->   
                </div>
                

              </div>
            </div>

        </div>
        <!-- Modal End -->                

    </div>
  </section>
</template>


<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    import * as XLSX from 'xlsx';        

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'competition',
                editmode: true,

                competitions : {},
                competitions_fields:[
                  {
                    label: 'Name',
                    key: 'name1',
                    sortable: true,
                  },
                  {
                    label: 'Organiser',
                    key: 'organiser',
                    sortable: true,
                  },
                  {
                    label: 'Competition Date',
                    key: 'competition_date',
                    sortable: true,
                  },
                  {
                    label: 'Registered Nos.',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Status',
                    key: 'is_opened',
                    sortable: true,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],


                rpt_namelist_filter: null,                
                rpt_namelist_sortBy : 'sort',
                rpt_namelist_sortDesc : false,
                rpt_namelist : {},
                rpt_namelist_fields: [
                  /*
                  {
                    label: 'item_no',
                    key: 'item_no',
                    sortable: true,
                  },*/
                  {
                    label: 'ID',
                    key: 'id',
                    sortable: true,
                  },
                  {
                    label: 'Event Sort',
                    key: 'sort',
                    sortable: true,
                  },                  
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age',
                    key: 'event_age_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'org_name1',
                    sortable: true,
                  },
                  {
                    label: 'In-Charge',
                    key: 'org_pic',
                    sortable: true,
                  },
                  {
                    label: 'Contact',
                    key: 'org_contact',
                    sortable: true,
                  },
                  {
                    label: 'Group Name',
                    key: 'group_name',
                    sortable: true,
                  },                      
                  {
                    label: 'Performance Title',
                    key: 'performance_title',
                    sortable: true,
                  },                      
                  {
                    label: 'Chi Name',
                    key: 'chi_name',
                    sortable: true,
                  },
                  {
                    label: 'Eng Name',
                    key: 'eng_name',
                    sortable: true,
                  },
                  {
                    label: 'DOB',
                    key: 'dob',
                    sortable: true,
                  },
                  {
                    label: 'Music',
                    key: 'performance_music_filename',
                    sortable: true,
                  },               
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                
                ],                                


                rpt_chingcheuklist_filter: null,                
                rpt_chingcheuklist_sortBy : 'sort',
                rpt_chingcheuklist_sortDesc : false,
                rpt_chingcheuklist : {},
                rpt_chingcheuklist_fields: [
                  
                  {
                    label: 'item_no',
                    key: 'item_no',
                    sortable: true,
                  },
                  {
                    label: 'Event Sort',
                    key: 'sort',
                    sortable: true,
                  },                  
                  {
                    label: 'ID',
                    key: 'id',
                    sortable: true,
                  },
                  {
                    label: 'name1',
                    key: 'name1',
                    sortable: true,
                  },
                  {
                    label: 'name2',
                    key: 'name2',
                    sortable: true,
                  },
                  {
                    label: 'nameall',
                    key: 'nameall',
                    sortable: true,
                  },
                  {
                    label: 'organisation',
                    key: 'org_name1',
                    sortable: true,
                  },
                  {
                    label: 'competition_event_id',
                    key: 'competition_event_id',
                    sortable: true,
                  },
                  {
                    label: 'competition_event_name',
                    key: 'competition_event_name',
                    sortable: true,
                  },
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age',
                    key: 'event_age_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                
                ],        
                
                rpt_eventlist_filter: null,                
                rpt_eventlist_sortBy : 'sort',
                rpt_eventlist_sortDesc : false,
                rpt_eventlist : {},
                rpt_eventlist_fields: [
                  
                  {
                    label: 'item_no',
                    key: 'item_no',
                    sortable: true,
                  },
                  {
                    label: 'Event Sort',
                    key: 'sort',
                    sortable: true,
                  },                  
                  {
                    label: 'EventID',
                    key: 'competition_event_id',
                    sortable: true,
                  },
                  {
                    label: 'competition_event_name',
                    key: 'competition_event_name',
                    sortable: true,
                  },
                  {
                    label: 'nos reg',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age',
                    key: 'event_age_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                
                ],                                
                

                rpt_playerlist_filter: null,                
                rpt_playerlist_sortBy : 'sort',
                rpt_playerlist_sortDesc : false,
                rpt_playerlist : {},
                rpt_playerlist_fields: [
                  
                  {
                    label: 'RegID',
                    key: 'event_registration_id',
                    sortable: true,
                  },
                  {
                    label: 'Bib',
                    key: 'player_bib',
                    sortable: true,
                  },                  
                  {
                    label: 'Applicant',
                    key: 'reg_name',
                    sortable: true,
                  },
                  {
                    label: 'Partners',
                    key: 'partners',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'organisation',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                
                ],                                


                sel_competition_id : '',
                sel_competition_name : '',
                
                competition_id:'',
                return_path:'',                     
            }
        },
        methods: {
          switchScreen(screen_id, row_item){
              switch( screen_id ){
                    case 'competition':
                        this.loadCompetitions();
                        break;
                    case 'reportNameList':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }                        
                        this.loadRptNameList();
                        break;
                    case 'reportChingCheukList':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }                        
                        this.loadRptChingCheukList();
                        break;
                    case 'reportEventList':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }                        
                        this.loadRptEventList();
                        break;
                    case 'reportPlayerList':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }                        
                        this.loadRptPlayerList();
                        break;

              }
              this.displaymode = screen_id;
          },
          loadCompetitions(page = 1){
            // if(this.$gate.isAdmin()){
              this.competitions = {};
              axios.get("api/competition?noimg=true&page=" + page).then(({ data }) => (this.competitions = data.data));
            // }
          },    
          loadRptNameList(page = 1){
            // if(this.$gate.isAdmin()){
              this.rpt_namelist= {};
              let postdata =  { 'competition_id': this.sel_competition_id };
              axios.post("/api/manage/reportNameList?page=" + page,postdata).then(({ data }) => (this.rpt_namelist = data.data));
            // }
          },               
          loadRptChingCheukList(page = 1){
            // if(this.$gate.isAdmin()){
              this.rpt_chingcheuklist= {};
              let postdata =  { 'competition_id': this.sel_competition_id };
              axios.post("/api/manage/reportChingCheukList?page=" + page,postdata).then(({ data }) => (this.rpt_chingcheuklist = data.data));
            // }
          },               
          loadRptEventList(page = 1){
            // if(this.$gate.isAdmin()){
              this.rpt_eventlist= {};
              let postdata =  { 'competition_id': this.sel_competition_id };
              axios.post("/api/manage/reportEventList?page=" + page,postdata).then(({ data }) => (this.rpt_eventlist = data.data));
            // }
          },               
          loadRptPlayerList(page = 1){
            // if(this.$gate.isAdmin()){
              this.rpt_playerlist= {};
              let postdata =  { 'competition_id': this.sel_competition_id };
              axios.post("/api/manage/reportPlayerList?page=" + page,postdata).then(({ data }) => (this.rpt_playerlist = data.data));
            // }
          },               
          exportXlsx(){


            switch( this.displaymode ){
                case 'competition':

                    break;
                case 'reportNameList':

                    var workbook = XLSX.utils.book_new();

                    var tbl = document.getElementById('rpt_namelist_Table');                    

                    var sheet1 = XLSX.utils.table_to_sheet(tbl);

                    //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

                    var sel_index = 0;
                    for( let x = 0; x < this.competitions.data.length; x++ ){
                        if( this.competitions.data[x].id == this.sel_competition_id  ){
                            sel_index = x;
                            break;
                        }
                    }

                    var competition_name = this.competitions.data[sel_index].name1;
                    var export_time = new Date();
                    var str_export_tme = export_time.toLocaleString();
                    var file_name = competition_name + ' - Report - Name List ' + str_export_tme + '.xlsx';


                    XLSX.utils.book_append_sheet(workbook, sheet1, 'Name List'); 

                    XLSX.writeFile(workbook, file_name); 


                    break;
                case 'reportChingCheukList':

                    var workbook = XLSX.utils.book_new();

                    var tbl = document.getElementById('rpt_chingcheuklist_Table');                    

                    var sheet1 = XLSX.utils.table_to_sheet(tbl);

                    //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

                    var sel_index = 0;
                    for( let x = 0; x < this.competitions.data.length; x++ ){
                        if( this.competitions.data[x].id == this.sel_competition_id  ){
                            sel_index = x;
                            break;
                        }
                    }

                    var competition_name = this.competitions.data[sel_index].name1;
                    var export_time = new Date();
                    var str_export_tme = export_time.toLocaleString();
                    var file_name = competition_name + ' - Report - Ching Cheuk List ' + str_export_tme + '.xlsx';


                    XLSX.utils.book_append_sheet(workbook, sheet1, 'Ching Cheuk List'); 

                    XLSX.writeFile(workbook, file_name); 


                    break;
                case 'reportEventList':

                    var workbook = XLSX.utils.book_new();

                    var tbl = document.getElementById('rpt_eventlist_Table');                    

                    var sheet1 = XLSX.utils.table_to_sheet(tbl);

                    //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

                    var sel_index = 0;
                    for( let x = 0; x < this.competitions.data.length; x++ ){
                        if( this.competitions.data[x].id == this.sel_competition_id  ){
                            sel_index = x;
                            break;
                        }
                    }

                    var competition_name = this.competitions.data[sel_index].name1;
                    var export_time = new Date();
                    var str_export_tme = export_time.toLocaleString();
                    var file_name = competition_name + ' - Report - Event List ' + str_export_tme + '.xlsx';


                    XLSX.utils.book_append_sheet(workbook, sheet1, 'Event List'); 

                    XLSX.writeFile(workbook, file_name); 


                    break;                    
                case 'reportPlayerList':

                    var workbook = XLSX.utils.book_new();

                    var tbl = document.getElementById('rpt_playerlist_Table');                    

                    var sheet1 = XLSX.utils.table_to_sheet(tbl);

                    //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

                    var sel_index = 0;
                    for( let x = 0; x < this.competitions.data.length; x++ ){
                        if( this.competitions.data[x].id == this.sel_competition_id  ){
                            sel_index = x;
                            break;
                        }
                    }

                    var competition_name = this.competitions.data[sel_index].name1;
                    var export_time = new Date();
                    var str_export_tme = export_time.toLocaleString();
                    var file_name = competition_name + ' - Report - Player List ' + str_export_tme + '.xlsx';


                    XLSX.utils.book_append_sheet(workbook, sheet1, 'Player List'); 

                    XLSX.writeFile(workbook, file_name); 


                    break;
            }

          },

        },
        mounted() {
            console.log('Report - Component mounted.')


        },
        created() {
            this.$Progress.start();

            this.loadCompetitions();

            this.$Progress.finish();
        },
        filters: {

        },
        computed: {

        },
    }
</script>

