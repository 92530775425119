<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="displaymode=='competition'">
              <div class="card-header">
                <h3 class="card-title">Manage Group Payments - Competition List</h3>

                <div class="card-tools">
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="competitions.data"
                  :fields="competitions_fields"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >

                  <template #cell(is_opened)="row">

                    <b-badge v-if="row.item.isopened==='true'" variant="success">Open</b-badge>
                    <b-badge v-if="row.item.isopened==='false'" variant="danger">Close</b-badge>
                    <b-badge v-if="row.item.isvisible==='true'" variant="success">Visible</b-badge>
                    <b-badge v-if="row.item.isvisible==='false'" variant="danger">Hide</b-badge>

                  </template>

                  <template #cell(actions)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('payment_group',row.item)">
                        Select
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="loadCompetitions"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div class="card" v-if="displaymode=='payment_group'">
              <div class="card-header">
                <h3 class="card-title">
                    Manage Group Payments - Payment List<br>
                    {{ sel_competition_name }}
                </h3>                

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition', null)">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>    
                  <button type="button" class="btn btn-sm btn-primary" @click="newModalPaymentGroup()">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="payment_groups.data"
                  :fields="payment_groups_fields"
                  :sort-by.sync="payment_groups_sortBy"
                  :sort-desc.sync="payment_groups_sortDesc"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >

                  <template #cell(actions)="row">
                    <a href="#" @click="editModalPaymentGroup(row.item)">
                        <i class="fa fa-edit blue"></i>
                    </a>
                    /
                    <a href="#" @click="deleteGroupPayment(row.item)">
                        <i class="fa fa-trash red"></i>
                    </a>                    
                    /                    
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('player',row.item)">
                        Select
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="payment_groups" @pagination-change-page="loadPaymentGroups"></pagination>
              </div>
            </div>
            <!-- /.card -->            

            <div class="card" v-if="displaymode=='player'">
              <div class="card-header">
                <h3 class="card-title">
                    Manage Registration - Player List<br>
                    {{ sel_competition_name }}<br>
                    {{ sel_payment_group_item.player_organisation_name }} | Contact Person : {{ sel_payment_group_item.player_organisation_contact_person }} | Contact Number : {{ sel_payment_group_item.player_organisation_contact_number }} <br>
                    Payment Date: {{ sel_payment_group_item.payment_date}} | Payment Type : {{sel_payment_group_item.payment_type}}
                </h3>                

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('payment_group', null)">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>    
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <b-row>
                  <b-col lg="6" class="my-1">                
                    <b-form-group
                      label="Filter"
                      label-for="players_filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="players_filter-input"
                          v-model="players_filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!players_filter" @click="players_filter = ''">Clear</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>     
                  </b-col>
                  <b-col lg="6" class="my-1"> 
                    <button type="button" class="btn btn-sm btn-primary"  @click="applyGroupPayment()">
                        Apply ${{ players_applied_amt }}
                        <i class="far fa-save"></i>
                    </button>                    
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" class="my-1">                
                    <b-form-group
                      label="Approve Amount"
                      label-for="players_approve_amt"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="players_approve_amt"
                          v-model="players_approve_amt"
                          type="text"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>     
                  </b-col>          
                  <b-col lg="6" class="my-1">                
                    <b-form-group
                      label="Applied Amount"
                      label-for="players_applied_amt"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="players_applied_amt"
                          v-model="players_applied_amt"
                          type="text"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>     
                  </b-col>                                       
                </b-row>

                <div>

                <b-table
                  :items="players.data"
                  :fields="players_fields"
                  :sort-by.sync="players_sortBy"
                  :sort-desc.sync="players_sortDesc"
                  :tbody-tr-class="players_rowClass"
                  :filter="players_filter"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                  :select-mode="players_selectMode"
                  ref="players_Table"
                  @row-selected="onPlayersRowSelected"                  
                  selectable
                >

                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <template #cell(actions)="row">
                    <!--
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('payment',row.item)" disabled>
                        <i class="fas fa-dollar-sign"></i>
                    </button>
                    /
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('registration',row.item)">
                        Registration
                        <i class="fas fa-clipboard-check"></i>                    
                    </button>
                    -->
                  </template>

                </b-table>
                </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="players" @pagination-change-page="loadPlayers"></pagination>
              </div>
            </div>
            <!-- /.card -->     

            <div class="card" v-if="displaymode=='registration'">
              <div class="card-header">
                <h3 class="card-title">
                    Manage Registration - Registration List<br>
                    {{ sel_competition_name }}<br>
                    {{ sel_payment_group_item.name1 }} | Contact Person : {{ sel_payment_group_item.contact_person }} | Contact Number : {{ sel_payment_group_item.contact_number }}
                    {{ sel_player_item.name }} | Chi Name: {{ sel_player_item.chi_name }} | DOB: {{ sel_player_item.dob }} <br />
                    Email: {{ sel_player_item.email }} <br>
                </h3>                   

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('player',null)">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>    
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                
                <b-table
                  :items="registrations.data"
                  :fields="registrations_fields"
                  :sort-by.sync="registrations_sortBy"
                  :sort-desc.sync="registrations_sortDesc"
                  :tbody-tr-class="registrations_rowClass"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >

                    <template #cell(actions)="row">
                        <button type="button" class="btn btn-sm btn-primary"  @click="newModalRegDetail(row.item)" >
                            Show Detail
                            <i class="fas fa-caret-square-right"></i>                        
                        </button>
                    </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="registrations" @pagination-change-page="loadRegistrations"></pagination>
              </div>
            </div>
            <!-- /.card -->        

          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalGroupPayment" tabindex="-1" role="dialog" aria-labelledby="modalGroupPayment" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Group Payment</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Group Payment</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateGroupPayment() : createGroupPayment()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Organisation</label>
                            <select name="organisation_access_code" @change="loadGpUserList()" v-model="formGroupPayment.organisation_access_code" id="organisation_access_code" class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('organisation_access_code') }">
                                <option v-for="option in organisationListItems" v-bind:value="option.organisation_access_code" v-bind:key="option.organisation_access_code" v-bind:selected="option.organisation_access_code == formGroupPayment.organisation_access_code" >
                                    {{ option.name1 }}
                                </option>
                            </select>
                            <has-error :form="formGroupPayment" field="organisation_access_code"></has-error>
                        </div>
                        <div class="form-group">
                            <label>User</label>
                            <select name="user_id" v-model="formGroupPayment.user_id" id="_user_id" class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('suser_id') }">
                                <option v-for="option in userListItems" v-bind:value="option.user_id" v-bind:key="option.user_id" v-bind:selected="option.user_id == formGroupPayment.user_id" >
                                    [{{ option.type }}] {{ option.name }} / {{ option.chi_name }}
                                </option>
                            </select>
                            <has-error :form="formGroupPayment" field="user_id"></has-error>
                        </div>                        

                        <div class="form-group">
                            <label>Payment Date</label>
                            <input v-model="formGroupPayment.payment_date" type="date" name="payment_date"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('payment_date') }">
                            <has-error :form="formGroupPayment" field="payment_date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Payment Type</label>
                              <b-form-select v-model="formGroupPayment.payment_type" name="payment_type" class="mb-3">
                                <b-form-select-option value="ATM Transfer">ATM Transfer</b-form-select-option>
                                <b-form-select-option value="Online/Mobile Banking">Online/Mobile Banking</b-form-select-option>
                                <b-form-select-option value="Chqeue Deposit">Chqeue Deposit</b-form-select-option>
                                <b-form-select-option value="Cash Deposit">Cash Deposit</b-form-select-option>
                                <b-form-select-option value="Others">Others</b-form-select-option>
                              </b-form-select>                              
                            <has-error :form="formGroupPayment" field="payment_type"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Remarks</label>
                            <input v-model="formGroupPayment.remarks" type="text" name="remarks"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('remarks') }">
                            <has-error :form="formGroupPayment" field="remarks"></has-error>
                        </div>

                        <div class="form-group">
                          <label>Replace Payment File</label>
                          <b-form-file v-model="formGroupPayment.payment_file" name="payment_file" accept="image/*"></b-form-file>
                        </div>                        

                        <div class="form-group">
                            <label>Payment File Preivew</label>
                            <img style="width: 100%;" v-bind:src="formGroupPayment.payment_file_base64"/>
                        </div>               
                        <div class="form-group">
                            <label>Approve Date</label>
                            <input v-model="formGroupPayment.approval_date" type="date" name="approval_date"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('approval_date') }">
                            <has-error :form="formGroupPayment" field="approval_date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Payment Amount</label>
                            <input v-model="formGroupPayment.payment_amt" type="text" name="payment_amt"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('payment_amt') }">
                            <has-error :form="formGroupPayment" field="payment_amt"></has-error>
                        </div>
                        <div class="form-group" >
                            <label>Approve Amount</label>
                            <input v-model="formGroupPayment.approval_amt" type="text" name="approval_amt"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('approval_amt') }">
                            <has-error :form="formGroupPayment" field="approval_amt"></has-error>
                        </div>                        
                        <div class="form-group">
                            <label>Approve Remarks</label>
                            <input v-model="formGroupPayment.approval_remarks" type="text" name="approval_remarks"
                                class="form-control" :class="{ 'is-invalid': formGroupPayment.errors.has('approval_remarks') }">
                            <has-error :form="formGroupPayment" field="approval_remarks"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->             

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'competition',
                editmode: true,

                competitions : {},
                competitions_fields:[
                  {
                    label: 'Name',
                    key: 'name1',
                    sortable: true,
                  },
                  {
                    label: 'Organiser',
                    key: 'organiser',
                    sortable: true,
                  },
                  {
                    label: 'Competition Date',
                    key: 'competition_date',
                    sortable: true,
                  },
                  {
                    label: 'Registered Nos.',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Status',
                    key: 'is_opened',
                    sortable: true,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],

                payment_groups_sortBy : 'payment_date',
                payment_groups_sortDesc : true,
                payment_groups : {},
                payment_groups_fields: [
                  
                  {
                    label: 'Name',
                    key: 'player_name',
                    sortable: true,
                  },
                  {
                    label: 'Organiser',
                    key: 'player_organisation_name',
                    sortable: true,
                  },
                  {
                    label: 'Payment Date',
                    key: 'payment_date',
                    sortable: true,
                  },
                  {
                    label: 'Payment_type',
                    key: 'payment_type',
                    sortable: true,
                  },
                  {
                    label: 'Approve Amount',
                    key: 'approval_amt',
                    sortable: true,
                  },
                  {
                    label: 'Applied Amount',
                    key: 'applied_amt',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                  

                ],

                players_selected_user_list : [],
                players_approve_amt : 0,
                players_applied_amt : 0,
                players_selectMode: 'multi',
                players_selected: [],
                players_filter: null,
                players_filterOn: [],
                players_totalRows: 1,
                players_currentPage: 1,                
                players_sortBy : 'count_registrations',
                players_sortDesc : true,
                players : {},
                players_fields: [
                  
                  {
                    label: 'selected',
                    key: 'selected',
                    //sortable: true,
                  },
                  {
                    label: 'Name',
                    key: 'name',
                    sortable: true,
                  },
                  {
                    label: 'Chi Name',
                    key: 'chi_name',
                    sortable: true,
                  },
                  {
                    label: 'Email',
                    key: 'email',
                    sortable: true,
                  },
                  {
                    label: 'DOB',
                    key: 'dob',
                    sortable: true,
                  },/*
                  {
                    label: 'Nick Name',
                    key: 'nick_name',
                    sortable: true,
                  },*/
                  {
                    label: 'Registered Nos.',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Total Amount',
                    key: 'total_fee',
                    sortable: true,
                  },
                  {
                    label: 'Approve Amount',
                    key: 'total_fee_approved',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                  

                ],

                registrations_sortBy : 'count_registrations',
                registrations_sortDesc : true,
                registrations : {},
                registrations_fields: [
                  
                  {
                    label: 'ID',
                    key: 'id',
                    sortable: true,
                  },
                  {
                    label: 'Applicant',
                    key: 'reg_name',
                    sortable: true,
                  },
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age',
                    key: 'event_age_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Max Pp',
                    key: 'max_ppl',
                    sortable: true,
                  },                  
                  {
                    label: 'Partners',
                    key: 'count_partners',
                    sortable: true,
                  },                  
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                  

                ],                

                sel_competition_id : '',
                sel_competition_name : '',
                sel_payment_group_id : '',
                sel_payment_group_item : [],
                sel_player_id : '',
                sel_player_item : [],
                
                formPayment: new Form({
                    id : '',
                    payment_date : '',
                    payment_type : '',
                    payment_amt: '',
                    remarks: '',
                    payment_file:  [],
                    payment_file_base64: '',
                }),

                
                competition_id:'',
                return_path:'',        
                
                formGroupPayment: new Form({
                    id : '',
                    organisation_access_code : '',
                    user_id : '',
                    player_name : '',
                    player_chi_name : '',
                    player_organisation_name : '',
                    payment_date : '',
                    payment_type : '',
                    payment_amt: '',
                    remarks: '',
                    payment_file:  [],
                    payment_file_base64: '',
                    approval_date : '',
                    approval_amt : '',
                    approval_remarks : '',
                }),

                organisationListItems : [],
                userListItems : [],

                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                partner:  '',
                reg_user_id: '',
                autocompleteItems: [],
                autocompleteApplicantItems: [],
                max_partners: 0,
                debounce: null, 

                performance_music_url:'',
                isPerformanceOptionShow: false,                
                isPerformanceMusicLoaded: false,                 

            }
        },
        methods: {
          switchScreen(screen_id, row_item){
              switch( screen_id ){
                    case 'competition':
                        this.loadCompetitions();
                        break;
                    case 'payment_group':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }
                        this.loadPaymentGroups();
                        break;
                    case 'player':
                        if( row_item != null ){
                            this.sel_payment_group_id = row_item.id;                    
                            this.sel_payment_group_item = row_item;
                            this.players_approve_amt = row_item.approval_amt;
                        }                    
                        this.loadPlayers();    
                        break;                        
                    case 'registration':
                        if( row_item != null ){
                            this.sel_player_id = row_item.id;                    
                            this.sel_player_item = row_item;
                        }                        
                        this.loadRegistrations();
                        break;

              }
              this.displaymode = screen_id;
          },
          loadCompetitions(page = 1){
            // if(this.$gate.isAdmin()){
              this.competitions = {};
              axios.get("api/competition?page=" + page).then(({ data }) => (this.competitions = data.data));
            // }
          },          
          loadPaymentGroups(page = 1){
            this.organisations = {};
            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_id': this.sel_competition_id  };
              axios.post("api/competition/getGroupPaymentList?page=" + page,postdata).then(({ data }) => (this.payment_groups = data.data));
            // }
          },    
          loadPlayers(page = 1){
            this.players = {};
            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_id': this.sel_competition_id, 'organisation_access_code' : this.sel_payment_group_item.organisation_access_code };
              axios.post("api/manage/getPlayerList?page=" + page,postdata).then(({ data }) => {
                this.players = data.data;
                this.loadPaymentGroupUserList();                
              });
            // }
          },    
          loadPaymentGroupUserList(){
            this.players_selected_user_list = {};
            let postdata =  { 'competition_id': this.sel_competition_id, 'competition_payment_group_id' : this.sel_payment_group_id };
            axios.post("api/competitionPayment/getGroupPaymentUserList", postdata).then(({ data }) => {
              this.players_selected_user_list = data.data;
              for( var j = 0; j < this.players_selected_user_list.length; j++ ){
                for(var i = 0; i < this.players.data.length; i++ ){
                  if( this.players.data[i].id == this.players_selected_user_list[j].user_id ){
                    this.$refs.players_Table.selectRow(i);
                  }
                }
              }
            });
          },    
          loadRegistrations(page = 1){
            // if(this.$gate.isAdmin()){
              this.registrations = {};
              let postdata =  { 'competition_id': this.sel_competition_id, 'organisation_access_code' : this.sel_payment_group_item.access_code, 'user_id': this.sel_player_item.id };
              axios.post("api/manage/getRegistrationList?page=" + page,postdata).then(({ data }) => (this.registrations = data.data));
            // }
          },    
          players_rowClass(item, type) {
                if (!item || type !== 'row') return
                if ( parseFloat(item.total_fee) > parseFloat(item.total_fee_approved) ) return 'table-danger'
            }, 
          registrations_rowClass(item, type) {
                if (!item || type !== 'row') return
                if ( parseInt(item.max_ppl) - parseInt(item.count_partners) != 1 ) return 'table-danger'
            },
          
          newModalPaymentGroup(){

            this.editmode = false;
            this.formGroupPayment.reset();   
            this.userListItems = [];
            this.organisationListItems = [];
            
            this.loadGpOrganisations();

            $('#modalGroupPayment').modal('show');

          },
          editModalPaymentGroup(row_item){
            this.editmode = true;
            this.formGroupPayment.reset();   
            this.userListItems = [];
            this.organisationListItems = [];
            
            this.formGroupPayment.fill(row_item);
            this.loadGpOrganisations();
            this.loadGpUserList();

            $('#modalGroupPayment').modal('show');                        
          },
           async editModalRegDetail(row_item){

                this.$Progress.start();

                try{

                    this.editmode = true;
                    this.form.reset();
                    this.partner = '';
                    this.partners = [];
                    this.form.partners = [];
                    this.autocompleteItems = [];
                    this.isPerformanceOptionShow = false;              
                    this.isPerformanceMusicLoaded = false;


                    let postdata = {'event_registration_id' : row_item.id};
                    let post = await axios.post('/api/eventRegistration/getEventRegistration', postdata);
                    let { data } = post;

                    this.form.id = data.data.id;
                    ///this.form.sort = data.data.sort;
                    //this.form.user_dob = data.data.user_dob;
                    //this.form.competition_date = data.data.competition_date;
                    //this.  = data.data.user_age;
                    this.form.competition_id = data.data.competition_id;
                    this.form.competition_event_id = data.data.competition_event_id;
                    this.form.event_group_id = data.data.event_group_id;
                    this.form.event_type_id = data.data.event_type_id;
                    this.form.event_age_group_id = data.data.event_age_group_id;
                    //this.form.reg_user_ids = data.data.,
                    this.form.group_name = data.data.group_name;
                    this.form.performance_title = data.data.performance_title;
                    this.form.performance_description = data.data.performance_description;
                    this.form.performance_durationInSeconds = data.data.performance_durationInSeconds;
                    //this.form.partners = data.data.partners;
                    //this.form.reg_user_id = data.data.reg_user_id;

                    //this.form.fill(data.data);

                    $('#regDetail').modal('show');

                    //this.loadFormDefaultValues();
                    this.loadEventGroups();
                    this.loadEventTypes();
                    this.loadEventAgeGroups();
                    this.loadEventPartners();

                    axios.post('/api/eventRegistration/getMusicFile/'+row_item.id,{}, { responseType: 'blob' }).then((res) => { 

                        const current_blob = new Blob([res.data],{type: res.headers['content-type']});
                        //console.table(new Blob([res.data],{type: res.headers['content-type']}).type);
                        //this.performance_music_url = URL.createObjectURL(current_blob);
                        this.performance_music_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                        //this.performance_music_url = URL.createObjectURL(new Blob([res.data]));

                        this.$refs.player.load();
                        this.$refs.player.controls = true;
                        this.isPerformanceMusicLoaded = true;

                        this.$Progress.finish();                  

                    });

                }catch (error) {

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
                  this.$Progress.fail();             

                }

           },
          loadGpOrganisations(){
              this.organisationListItems = [];
              let postdata =  { };
              axios.post('/api/organisation/getOrganisationList', postdata).then(({ data }) => (this.organisationListItems = data.data));
          },     
          loadGpUserList(){
              console.log(this.formGroupPayment.organisation_access_code);
              this.userListItems = [];
              let postdata =  { 'organisation_access_code' : this.formGroupPayment.organisation_access_code  };
              axios.post('/api/user/getOrganisationUserList', postdata).then(({ data }) => (this.userListItems = data.data));
          },
         createGroupPayment(){

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.formGroupPayment){
                  if (this.formGroupPayment.hasOwnProperty(k)) {
                      if( k != 'payment_file_base64'){
                        postdata.append(k,this.formGroupPayment[k]);
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }
              postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/competitionPayment/createGroupPayment', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#modalGroupPayment').modal('hide');

                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.loadPaymentGroups();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                  

          },          
          updateGroupPayment(){
              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.formGroupPayment){
                  if (this.formGroupPayment.hasOwnProperty(k)) {
                      if( k != 'payment_file_base64'){
                        postdata.append(k,this.formGroupPayment[k]);
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/competitionPayment/updateGroupPayment', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#modalGroupPayment').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.loadPaymentGroups();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                  

          },          
          deleteGroupPayment(row_item){
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {

                    // Send request to the server
                      if (result.value) {

                        let postdata = { 'id' : row_item.id };
                        axios.post('/api/competitionPayment/deleteGroupPayment',  postdata).then(response => { 

                          Swal.fire(
                            'Deleted!',
                            'The payment has been deleted.',
                            'success'
                          );
                          // Fire.$emit('AfterCreate');
                          this.loadPaymentGroups();

                      }).catch((error) => {
                        Swal.fire("Failed!", error.message, "warning")
                      });
                    }
                })                       
          },            
          applyGroupPayment(){

            let diff_amt = parseFloat(this.players_approve_amt) - parseFloat(this.players_applied_amt);
            if( diff_amt != 0 ){
                Toast.fire({
                  icon: 'error',
                  title: 'Applied Amount not match! Approve = ' + this.players_approve_amt + ', Applied = ' + this.players_applied_amt,
                });
                return;
            }

            let applyUserList = [];

            this.players_selected.forEach( k => applyUserList.push ( { 'user_id' : k.id, 'apply_amt' : k.total_fee } ) );

            let postdata = { 
              'competition_id' : this.sel_competition_id, 
              'applyUserList' : JSON.stringify(applyUserList),
              'competition_payment_group_id' : this.sel_payment_group_id,
            };

            axios.post('/api/competitionPayment/applyGroupPayment', postdata).then(response => {

                    // success
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadPaymentGroups();

                })
                .catch((error) => {

                    if(error.response.status == 422){
                      /*
                      console.log(Object.keys(error.response.data.errors));
                      console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                      */
                      /*
                      $.each(error.response.data.errors, function(key, value) {
                        this.form.errors[key] = value;
                      });
                      //this.form.errors = error.response.data.errors;
                      console.log(this.form.errors);
                      */
                      Toast.fire({
                          icon: 'error',
                          title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                      });                    


                    }else{
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });                    
                    }
                    this.$Progress.fail();
                });               

          },
          
          onCloseModal(){
            this.$refs.player.pause();
            URL.revokeObjectURL(this.performance_music_url);
            this.performance_music_url = '';
          },              

          onPlayersFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.players_totalRows = filteredItems.length
            this.players_currentPage = 1
          },
          onPlayersRowSelected(items) {
            this.players_selected = items;
            let total_sum = 0;

            this.players_selected.forEach( k => total_sum += parseFloat(k.total_fee) );

            this.players_applied_amt = total_sum;
          },          


        },
        mounted() {
            console.log('Management - Component mounted.')


        },
        created() {
            this.$Progress.start();

            /*
            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;
            
            if( this.$route.params.competition_id !== undefined && this.$route.params.return_path !== undefined ){
              
              this.switchScreen('bib-list',this.competition_id);

            }else{
              this.loadCompetitions();
            }
            */

            //this.loadCategories();
            //this.loadTags();

            this.loadCompetitions();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.partner.toLowerCase()) !== -1;
            });
          },
          filteredApplicantItems() {
            return this.autocompleteApplicantItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.reg_user_id.toLowerCase()) !== -1;
            });
          },          
          totalAmountPayment: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + isNaN(parseFloat(item.payment_amt)) ? 0 : parseFloat(item.payment_amt) );
            }, 0);   
          },
          totalAmountApproval: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + isNaN(parseFloat(item.approval_amt)) ? 0 : parseFloat(item.approval_amt) );
            }, 0);          
          },
        },
    }
</script>
