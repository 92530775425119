<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="displaymode=='competition'">
              <div class="card-header">
                <h3 class="card-title">Manage Submission - Competition List</h3>

                <div class="card-tools">
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="competitions.data"
                  :fields="competitions_fields"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >

                  <template #cell(is_opened)="row">

                    <b-badge v-if="row.item.isopened==='true'" variant="success">Open</b-badge>
                    <b-badge v-if="row.item.isopened==='false'" variant="danger">Close</b-badge>
                    <b-badge v-if="row.item.isvisible==='true'" variant="success">Visible</b-badge>
                    <b-badge v-if="row.item.isvisible==='false'" variant="danger">Hide</b-badge>

                  </template>

                  <template #cell(actions)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('registration',row.item)">
                        Select
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="loadCompetitions"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div class="card" v-if="displaymode=='registration'">
              <div class="card-header">
                <h3 class="card-title">
                    Manage Submission - Registration List<br>
                    {{ sel_competition_name }}<br>
                </h3>                   

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition',null)">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>    
                  <b-dropdown id="dropdown-right" text="Export" variant="primary" class="m-3">
                    <b-dropdown-item href="#" @click="exportXlsx()">xlsx</b-dropdown-item>
                  </b-dropdown>   

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-row>
                    <b-col lg="6" class="my-1">                
                        <b-form-group
                        label="Filter"
                        label-for="registrations-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="rpt_namelist-input"
                            v-model="registrations_filter"
                            type="search"
                            placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!registrations_filter" @click="registrations_filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>     
                    </b-col>
                </b-row>                

                <b-table
                  :items="registrations.data"
                  :fields="registrations_fields"
                  :sort-by.sync="registrations_sortBy"
                  :sort-desc.sync="registrations_sortDesc"
                  :tbody-tr-class="registrations_rowClass"
                  :filter="registrations_filter"
                  id="registrations_Table"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >


                    <template #cell(count_partners)="row"  >
                      <div class="table-danger" v-if="( parseInt(row.item.max_ppl) - parseInt(row.item.count_partners) != 1 )">
                        {{row.item.count_partners}}                      
                      </div>
                      <div v-else>
                        {{row.item.count_partners}}                      
                      </div>
                    </template>
                    <template #cell(performance_title)="row"  >
                      <div class="table-danger" v-if=" ( typeof row.item.performance_title === 'object' || row.item.performance_title == 'null') ">
                        <pre>            </pre>
                      </div>
                      <div v-else>
                        {{row.item.performance_title}}                      
                      </div>
                    </template>        
                    <template #cell(total_fee_approved)="row"  >
                      <div class="table-danger" v-if="parseFloat(row.item.total_fee_approved) != parseFloat(row.item.total_fee)">
                        {{row.item.total_fee_approved}}                      
                      </div>
                      <div v-else>
                        {{row.item.total_fee_approved}}                      
                      </div>
                    </template>                                    

                    <template #cell(performance_music_filename)="row">
                        <span :inner-html.prop="row.item.performance_music_filename | yesno"></span>
                    </template>
                    <template #cell(actions)="row">
                        <button type="button" class="btn btn-sm btn-primary"  @click="row.toggleDetails" >
                            {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                            <i class="fas fa-caret-square-right"></i>                        
                        </button>
                        /
                        <button type="button" class="btn btn-sm btn-primary"  @click="newModalRegDetail(row.item)" >
                            Edit
                            <i class="fas fa-caret-square-right"></i>                        
                        </button>
                    </template>

                    <template #row-details="row">
                        <b-card>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Organisation Contact Person</b></b-col>
                            <b-col>{{ row.item.reg_organisation_contact_person }}</b-col>
                            <b-col sm="3" class="text-sm-right"><b>Organisation Contact Number</b></b-col>
                            <b-col>{{ row.item.reg_organisation_contact_number }}</b-col>
                        </b-row>   <!--                         
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Max ppl</b></b-col>
                            <b-col>{{ row.item.max_ppl }}</b-col>
                            <b-col sm="3" class="text-sm-right"><b>Count Partners</b></b-col>
                            <b-col>{{ row.item.count_partners }}</b-col>
                        </b-row>      -->                      
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Chi Name</b></b-col>
                            <b-col>{{ row.item.reg_chi_name }}</b-col>
                            <b-col sm="3" class="text-sm-right"><b>DOB</b></b-col>
                            <b-col>{{ row.item.reg_dob }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Email</b></b-col>
                            <b-col>{{ row.item.reg_email }}</b-col>
                            <b-col sm="3" class="text-sm-right"><b>Nickname</b></b-col>
                            <b-col>{{ row.item.reg_nick_name }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Group Name</b></b-col>
                            <b-col>{{ row.item.group_name }}</b-col>
                            <b-col sm="3" class="text-sm-right"><b>Applicant Phone</b></b-col>
                            <b-col>{{ row.item.reg_phone }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Peformance Title</b></b-col>
                            <b-col>{{ row.item.performance_title }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Peformance Description</b></b-col>
                            <b-col>{{ row.item.performance_description }}</b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Peformance Music File Name</b></b-col>
                            <b-col>{{ row.item.performance_music_filename }}</b-col>
                        </b-row>
                        <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                        </b-card>
                    </template>                    

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="registrations" @pagination-change-page="loadRegistrations"></pagination>
              </div>
            </div>
            <!-- /.card -->        

          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="regDetail" tabindex="-1" role="dialog" aria-labelledby="regDetail" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Registration Detail</h5>
                        <button type="button" class="close" @click="onCloseModal" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="editmode ? updateEventRegistration() : createEventRegistration()">
                        <div class="modal-body">

                            <input v-model="form.competition_id" type="hidden" name="competition_id">
                            <input v-model="form.id" type="hidden" name="id">

                            <div class="form-group">
                                <label>Applicant </label>
                                <vue-tags-input
                                  v-model="reg_user_id"
                                  placeholder="Add Nick Name"
                                  :tags="form.reg_user_ids"
                                  :add-only-from-autocomplete="true"
                                  :autocomplete-items="filteredApplicantItems"
                                  :max-tags="1"
                                  @tags-changed="newTags => updateApplicants(newTags)"
                                />
                                <has-error :form="form" field="reg_user_ids"></has-error>
                            </div>

                            <div class="form-group">
                                <label>Event Group</label>
                                <select name="event_group_id" @change="loadEventTypes()" v-model="form.event_group_id" id="event_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_group_id') }">
                                    <option v-for="option in eventGroupItems" v-bind:value="option.event_group_id" v-bind:key="option.event_group_id" v-bind:selected="option.event_group_id == form.event_group_id" >
                                        {{ option.title }}
                                    </option>
                                </select>
                                <has-error :form="form" field="event_group_id"></has-error>
                            </div>

                            <div class="form-group">
                                <label>Event Type</label>
                                <select name="event_type_id" @change="loadEventAgeGroups()" v-model="form.event_type_id" id="event_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_type_id') }">
                                    <option v-for="option in eventTypeItems" v-bind:value="option.event_type_id" v-bind:key="option.event_type_id" v-bind:selected="option.event_type_id == form.event_type_id" >
                                        {{ option.title }} - {{ option.dance }}
                                    </option>
                                </select>
                                <has-error :form="form" field="event_type_id"></has-error>
                            </div>

                            <div class="form-group">
                                <label>Event Age Group</label>
                                <select name="event_age_group_id" @change="onChangeEventAgeGroup()" v-model="form.event_age_group_id" id="event_age_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_age_group_id') }">
                                    <option v-for="option in eventAgeGroupItems" v-bind:value="option.event_age_group_id" v-bind:key="option.event_age_group_id" v-bind:selected="option.event_age_group_id == form.event_age_group_id" >
                                        {{ option.title }} [{{ option.age_min }} - {{ option.age_max }}]
                                    </option>
                                </select>
                                <has-error :form="form" field="event_age_group_id"></has-error>
                            </div>

                            <div class="form-group" v-show="max_partners > 1">
                                <label>Group Name</label>
                                <input v-model="form.group_name" id="group_name" type="text" name="group_name" class="form-control">
                            </div>

                            <div class="form-group" v-show="isPerformanceOptionShow">
                                <label>Performance Title 表演名稱</label>
                                <input v-model="form.performance_title" id="performance_title" type="text" name="performance_title" class="form-control">
                            </div>
                            <div class="form-group" v-show="isPerformanceOptionShow">
                                <label>Performance Description 表演簡介</label>
                                <input v-model="form.performance_description" id="performance_description" type="text" name="performance_description" class="form-control">
                            </div>
                            <div class="form-group" v-show="isPerformanceOptionShow">
                                <label>Estimated Performance Time (Seconds) 預計時間</label>
                                <input v-model="form.performance_durationInSeconds" id="performance_durationInSeconds" type="text" name="performance_durationInSeconds" class="form-control">
                            </div>
                            <div class="form-group" v-show="isPerformanceOptionShow">
                            <label>Performance Music (Max 8MB)</label>
                            <b-form-file v-model="form.performance_music_path" name="performance_music_path" accept="audio/*"></b-form-file>
                            </div>                        

                            <div class="form-group" v-show="isPerformanceMusicLoaded">
                                <label>Performance Music Preivew</label>
                                <audio ref="player" controls="" style="vertical-align: middle">
                                    <source v-bind:src="performance_music_url">
                                    Your browser does not support the audio element.
                                </audio>                            
                            </div>                               

                            <div class="form-group">
                                <label>Partner / Teamate <b-badge variant="success"><span id="max_ppl"></span></b-badge> </label>
                                <vue-tags-input
                                v-model="partner"
                                placeholder="Add Nick Name"
                                :tags="form.partners"
                                :add-only-from-autocomplete="true"
                                :autocomplete-items="filteredItems"
                                :max-tags="max_partners"
                                @tags-changed="updatePartners => form.partners = updatePartners"
                                />
                                <has-error :form="form" field="partners"></has-error>
                            </div>

                        </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="onCloseModal" data-dismiss="modal">Close</button>
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        </div>
                    </form>                    

                </div>
            </div>
        </div>
        <!-- Modal End -->                

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    import * as XLSX from 'xlsx';        

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'competition',
                editmode: true,

                competitions : {},
                competitions_fields:[
                  {
                    label: 'Name',
                    key: 'name1',
                    sortable: true,
                  },
                  {
                    label: 'Organiser',
                    key: 'organiser',
                    sortable: true,
                  },
                  {
                    label: 'Competition Date',
                    key: 'competition_date',
                    sortable: true,
                  },
                  {
                    label: 'Registered Nos.',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Status',
                    key: 'is_opened',
                    sortable: true,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],

                registrations_filter : null,
                registrations_sortBy : 'count_registrations',
                registrations_sortDesc : true,
                registrations : {},
                registrations_fields: [
                  
                  {
                    label: 'ID',
                    key: 'id',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'reg_organisation_name1',
                    sortable: true,
                  },
                  {
                    label: 'Applicant',
                    key: 'reg_name',
                    sortable: true,
                  },/*
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },*/
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age',
                    key: 'event_age_group_title',
                    sortable: true,
                  },/*
                  {
                    label: 'Max Pp',
                    key: 'max_ppl',
                    sortable: true,
                  },                  
                  {
                    label: 'Partners',
                    key: 'count_partners',
                    sortable: true,
                  },  */             
                  {
                    label: 'Performance Title',
                    key: 'performance_title',
                    sortable: true,
                  },               
                  {
                    label: 'Max ppl',
                    key: 'max_ppl',
                    sortable: true,
                  },        
                  {
                    label: 'Partners',
                    key: 'count_partners',
                    sortable: true,
                  },               
                  {
                    label: 'Amt',
                    key: 'total_fee',
                    sortable: true,
                  },               
                  {
                    label: 'Amt proved',
                    key: 'total_fee_approved',
                    sortable: true,
                  },               
                  {
                    label: 'Music',
                    key: 'performance_music_filename',
                    sortable: true,
                  },     
                  {
                    label: 'Duration',
                    key: 'performance_music_duration',
                    sortable: true,
                  },     
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                
                ],                

                sel_competition_id : '',
                sel_competition_name : '',
                
                competition_id:'',
                return_path:'',        
                
                form: new Form({
                    id : '',
                    sort : '',
                    user_dob : '',
                    competition_date : '',
                    user_age : '',
                    competition_id : '',
                    competition_event_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  '',
                    reg_user_ids: [],
                    group_name: '',
                    performance_title:'',
                    performance_description:'',
                    performance_music_path:[],
                    performance_durationInSeconds:'',                    
                    partners : [],
                    reg_user_id : '',
                }),                

                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                partner:  '',
                reg_user_id: '',
                autocompleteItems: [],
                autocompleteApplicantItems: [],
                max_partners: 0,
                debounce: null, 

                performance_music_url:'',
                isPerformanceOptionShow: false,                
                isPerformanceMusicLoaded: false,                 

            }
        },
        methods: {
          switchScreen(screen_id, row_item){
              switch( screen_id ){
                    case 'competition':
                        this.loadCompetitions();
                        break;
                    case 'registration':
                        if( row_item != null ){
                            this.sel_competition_id = row_item.id;                    
                            this.sel_competition_name = row_item.name1;
                        }                        
                        this.loadRegistrations();
                        break;

              }
              this.displaymode = screen_id;
          },
          loadCompetitions(page = 1){
            // if(this.$gate.isAdmin()){
              this.competitions = {};
              axios.get("api/competition?page=" + page).then(({ data }) => (this.competitions = data.data));
            // }
          },          
          loadRegistrations(page = 1){
            // if(this.$gate.isAdmin()){
              this.registrations = {};
              let postdata =  { 'competition_id': this.sel_competition_id };
              axios.post("api/manage/getRegistrationList?page=" + page,postdata).then(({ data }) => (this.registrations = data.data));
            // }
          },    
          players_rowClass(item, type) {
                if (!item || type !== 'row') return
                if ( parseFloat(item.total_fee) > parseFloat(item.total_fee_approved) ) return 'table-danger'
            }, 
          registrations_rowClass(item, type) {
            /*
                if (!item || type !== 'row') return
                if ( parseInt(item.max_ppl) - parseInt(item.count_partners) != 1 ) return 'table-danger'
            */                
            },
           async newModalRegDetail(row_item){

                this.$Progress.start();

                try{

                    this.editmode = true;
                    this.form.reset();
                    this.partner = '';
                    this.partners = [];
                    this.form.partners = [];
                    this.autocompleteItems = [];
                    this.isPerformanceOptionShow = false;              
                    this.isPerformanceMusicLoaded = false;


                    let postdata = {'event_registration_id' : row_item.id};
                    let post = await axios.post('/api/eventRegistration/getEventRegistration', postdata);
                    let { data } = post;

                    this.form.id = data.data.id;
                    ///this.form.sort = data.data.sort;
                    //this.form.user_dob = data.data.user_dob;
                    //this.form.competition_date = data.data.competition_date;
                    //this.form.user_age  = data.data.user_age;
                    this.form.competition_id = data.data.competition_id;
                    this.form.competition_event_id = data.data.competition_event_id;
                    this.form.event_group_id = data.data.event_group_id;
                    this.form.event_type_id = data.data.event_type_id;
                    this.form.event_age_group_id = data.data.event_age_group_id;
                    //this.form.reg_user_ids = data.data.,
                    this.form.group_name = data.data.group_name;
                    this.form.performance_title = data.data.performance_title;
                    this.form.performance_description = data.data.performance_description;
                    this.form.performance_durationInSeconds = data.data.performance_durationInSeconds;
                    //this.form.partners = data.data.partners;
                    //this.form.reg_user_id = data.data.reg_user_id;

                    //this.form.fill(data.data);

                    $('#regDetail').modal('show');

                    //this.loadFormDefaultValues();
                    this.loadEventGroups();
                    this.loadEventTypes();
                    this.loadEventAgeGroups();
                    this.loadEventPartners();

                    axios.post('/api/eventRegistration/getMusicFile/'+row_item.id,{}, { responseType: 'blob' }).then((res) => { 

                        const current_blob = new Blob([res.data],{type: res.headers['content-type']});
                        //console.table(new Blob([res.data],{type: res.headers['content-type']}).type);
                        //this.performance_music_url = URL.createObjectURL(current_blob);
                        this.performance_music_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                        //this.performance_music_url = URL.createObjectURL(new Blob([res.data]));

                        this.$refs.player.load();
                        this.$refs.player.controls = true;
                        this.isPerformanceMusicLoaded = true;

                        this.$Progress.finish();                  

                    });

                }catch (error) {

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
                  this.$Progress.fail();             

                }

           },
          loadEventGroups(){

            if( this.editmode ){
              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfoA",$postdata).then(({ data }) => (this.eventGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfoA",$postdata).then(({ data }) => (this.eventGroupItems = data.data));

            }
          },     
          loadEventAgeGroups(){

            if( this.editmode ){
              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'event_type_id' : this.form.event_type_id,
              };

              axios.post("/api/eventRegistration/getEventAgeGroupInfoA",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'event_type_id' : this.form.event_type_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                 
              };

              this.$Progress.start();
              this.loadEventTypes();
              axios.post("/api/eventRegistration/getEventAgeGroupInfoO",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));

            }


            this.$Progress.finish();    

          },     
          loadEventTypes(){

            if( this.editmode ){
              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
              };

              axios.post("/api/eventRegistration/getEventTypeInfoA",$postdata).then(({ data }) => {
                this.eventTypeItems = data.data;

                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );

                    if( a.grade_mode == 'rank'){
                      this.isPerformanceOptionShow = false;
                    }else{
                      this.isPerformanceOptionShow = true;
                    }                    
                  }
                });

              });

            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                
              };

              axios.post("/api/eventRegistration/getEventTypeInfoA", $postdata).then(({ data }) => {
                this.eventTypeItems = data.data;
                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );
                  }
                });

              });
            }



          },     
          loadEventPartners(){

            if( this.editmode ){
              var $postdata = {
                'event_registration_id' : this.form.id,
              };


              axios.post("/api/eventRegistration/getEventRegistrationPlayerU", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $partner_input = [];

                  this.autocompleteItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $partner_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $partner_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });
                  this.form.partners = $partner_input;
              })

              axios.post("/api/eventRegistration/getEventRegistrationApplicantO", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $applicant_input = [];

                  this.autocompleteApplicantItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $applicant_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $applicant_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });

                  this.form.reg_user_ids = $applicant_input;
                  if( this.form.reg_user_ids.length > 0 ){
                    this.form.reg_user_id = this.form.reg_user_ids[0].id;
                  }                  
              })


              //axios.post("/api/eventRegistration/getEventRegistrationPlayerU",$postdata).then(({ data }) => (this.form.partners = data.data));
            }else{
            }

          },      
          updateEventRegistration(){

              if( this.form.reg_user_ids.length < 1 ){
                Toast.fire({
                    icon: 'error',
                    title: 'Applicant is missing!'
                });
                return;
              }

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'partners'){
                        postdata.append(k,this.form[k]);
                      }else{
                        postdata.append(k,JSON.stringify(this.form[k]));
                      }
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/eventRegistration/updateEntry', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#regDetail').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.onCloseModal();

                      this.loadEventRegistrations();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else if(error.response.status == 413){
                        Toast.fire({
                            icon: 'error',
                            title: 'File Too Large',
                        }); 
                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                   

              /*
              this.$Progress.start();
              this.form.put('/api/eventRegistration/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });

                  this.loadEventRegistrations();
                  this.loadEventGroups();

                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

              })
              .catch(() => {
                  this.$Progress.fail();
              });
              */

          },          


          /* Tags */
          updatePartners(newTags) {
            this.autocompleteItems = [];
            this.partners = newTags;
          },          
          updateApplicants(newTags) {
            this.autocompleteApplicantItems = [];
            this.form.reg_user_ids = newTags;

            if( this.form.reg_user_ids.length > 0 ){
              this.form.reg_user_id = this.form.reg_user_ids[0].id;
            }
            
            this.loadEventGroups();
          },            

          
          onCloseModal(){
            this.$refs.player.pause();
            URL.revokeObjectURL(this.performance_music_url);
            this.performance_music_url = '';
          },              

          exportXlsx(){

            switch( this.displaymode ){
                case 'competition':

                    break;
                case 'registration':

                    let workbook = XLSX.utils.book_new();

                    let tbl = document.getElementById('registrations_Table');                    

                    let sheet1 = XLSX.utils.table_to_sheet(tbl);

                    //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

                    let sel_index = 0;
                    for( let x = 0; x < this.competitions.data.length; x++ ){
                        if( this.competitions.data[x].id == this.sel_competition_id  ){
                            sel_index = x;
                            break;
                        }
                    }

                    let competition_name = this.competitions.data[sel_index].name1;
                    let export_time = new Date();
                    let str_export_tme = export_time.toLocaleString();
                    let file_name = competition_name + ' - Submission - Registration List ' + str_export_tme + '.xlsx';


                    XLSX.utils.book_append_sheet(workbook, sheet1, 'Submission - Registration List'); 

                    XLSX.writeFile(workbook, file_name); 


                    break;

            }

          },



        },
        mounted() {
            console.log('Management - Component mounted.')


        },
        created() {
            this.$Progress.start();

            /*
            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;
            
            if( this.$route.params.competition_id !== undefined && this.$route.params.return_path !== undefined ){
              
              this.switchScreen('bib-list',this.competition_id);

            }else{
              this.loadCompetitions();
            }
            */

            //this.loadCategories();
            //this.loadTags();

            this.loadCompetitions();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.partner.toLowerCase()) !== -1;
            });
          },
          filteredApplicantItems() {
            return this.autocompleteApplicantItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.reg_user_id.toLowerCase()) !== -1;
            });
          },          
          totalAmountPayment: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + isNaN(parseFloat(item.payment_amt)) ? 0 : parseFloat(item.payment_amt) );
            }, 0);   
          },
          totalAmountApproval: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + isNaN(parseFloat(item.approval_amt)) ? 0 : parseFloat(item.approval_amt) );
            }, 0);          
          },
        },
    }
</script>
