<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
          <div class="card" style="position: sticky; z-index: 100;width: 100%;top:0px;" v-show="isPerformanceMusicLoaded">
              <label>Performance Music For Event: {{sel_EVT}}, Registration ID: {{sel_registration_id}}</label>
              <audio ref="player" controls="" style="vertical-align: middle">
                  <source v-bind:src="performance_music_url">
                  Your browser does not support the audio element.
              </audio>                            
          </div>                  
        
            <div class="card" v-if="displaymode=='masterlist'">
              <div class="card-header">
                <h3 class="card-title">
                    Mainboard - Master List <br/> <br/>
                    Competition Date : {{list_competition_date}} <br/>
                    Competition Name : {{list_competition_name}} <br/>
                    Last Generate Bib : {{last_gen_bib}} <br/>
                </h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-sm btn-primary"  @click="syncBib()">
                        Sync BiB from mainboard
                    </button>
                    <!--
                    <button type="button" class="btn btn-sm btn-primary"  @click="insertBib()">
                        Insert BiB
                    </button>
                    -->
                    <b-dropdown id="dropdown-right" text="Export" variant="primary" class="m-3">
                      <b-dropdown-item href="#" @click="exportXlsx()">xlsx</b-dropdown-item>
                    </b-dropdown>                     
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <b-table
                  :items="masterlist.data"
                  :fields="masterlist_fields"
                  primary-key="eid"
                  sort-by="eid"
                  responsive="sm"
                  stacked="sm"
                  id="mainboard_master_Table"
                >
                  <template #cell(music)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="clickMusic('play',row.item)">
                        <i class="far fa-play-circle"></i>
                    </button>
                  </template>

                  <template #cell(actions)="row">
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('viewEntry',row.item)">
                        View Entry <br/>
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="masterlist" @pagination-change-page="loadMasterList"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div class="card" v-if="displaymode=='viewEntry'">
              <div class="card-header">
                <h3 class="card-title">
                    Mainboard - Master List (View Entry) <br/> <br/>
                    Competition Date : {{list_competition_date}} <br/>
                    Competition Name : {{list_competition_name}} <br/> <br/>
                    EVT : {{sel_EVT}} | Event Name : {{ sel_EventName}}
                </h3>

                <div class="card-tools">

                    <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('masterlist')">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>                  

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="viewEntry.data"
                  :fields="viewEntry_fields"
                  primary-key="id"
                  :sort-by="viewEntry_sortby"
                  :sort-desc="viewEntry_sortDesc"
                  responsive="sm"
                  stacked="sm"
                >
                  <template #cell(actions)="row">
                    <!--
                    <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('getScore',row.item)">
                        Edit
                        <i class="fas fa-caret-square-right"></i>                        
                    </button>
                    -->
                  </template>

                </b-table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <!--<pagination :data="endlist" @pagination-change-page="loadScoreSheetList"></pagination>-->
              </div>
            </div>
            <!-- /.card -->

          </div>
        </div>

    </div>
  </section>
</template>


<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    import * as XLSX from 'xlsx';           

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'masterlist',
                editmode: true,

                performance_music_url:'',
                isPerformanceOptionShow: false,       
                isPerformanceMusicLoaded: false,                       
                sel_EVT: '',
                sel_registration_id: '',

                list_competition_date: '',
                list_competition_name: '',
                last_gen_bib : '',

                masterlist : {},
                masterlist_fields:[
                  {
                    label: 'eid',
                    key: 'eid',
                    sortable: true,
                  },
                  {
                    label: 'Prg',
                    key: 'Prg',
                    sortable: true,
                  },
                  {
                    label: 'Status',
                    key: 'Status',
                    sortable: false,
                  },
                  {
                    label: 'Est Time',
                    key: 'EstTime',
                    sortable: false,
                  },
                  {
                    label: 'End Time',
                    key: 'ActualETime',
                    sortable: false,
                  },
                  {
                    label: 'EVT',
                    key: 'EVT',
                    sortable: false,
                  },
                  {
                    label: 'Zone',
                    key: 'Zone',
                    sortable: false,
                  },
                  {
                    label: 'EventName',
                    key: 'EventName',
                    sortable: false,
                  },
                  {
                    label: 'Dance',
                    key: 'Dance',
                    sortable: false,
                  },
                  {
                    label: 'Type',
                    key: 'Type',
                    sortable: false,
                  },
                  {
                    label: 'NumPP',
                    key: 'NumPP',
                    sortable: false,
                  },
                  {
                    label: 'Bibs',
                    key: 'bibs',
                    sortable: false,
                  },
                  {
                    label: 'Reg Entry',
                    key: 'total_reg_entry',
                    sortable: false,
                  },
                  {
                    label: 'Music',
                    key: 'music',
                    //sortable: true,                    
                  },                                      
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],

                viewEntry_sortby: '',
                viewEntry_sortDesc : true,
                viewEntry : {},
                viewEntry_fields:[
                  {
                    label: 'Bib',
                    key: 'bib',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'reg_organisation_name1',
                    sortable: true,
                  },
                  {
                    label: 'Name',
                    key: 'reg_name',
                    sortable: true,
                  },
                  {
                    label: 'Chi Name',
                    key: 'reg_chi_name',
                    sortable: true,
                  },
                  {
                    label: 'DOB',
                    key: 'reg_dob',
                    sortable: true,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,                    
                  },                                      
                ],

                sel_competition_event_id : '',
                sel_eid : '',
                sel_EVT : '',
                sel_EventName : '',

                sel_eventid : '',
                sel_eventTitle : '',

                sel_competition_id : '',
                sel_competition_name : '',
                sel_organisation_id : '',
                sel_organisation_item : [],
                sel_player_id : '',
                sel_player_item : [],
                
                formPayment: new Form({
                    id : '',
                    payment_date : '',
                    payment_type : '',
                    payment_amt: '',
                    remarks: '',
                    payment_file:  [],
                    payment_file_base64: '',
                }),

                
                competition_id:'',
                return_path:'',        
                
                form: new Form({
                    id : '',
                    sort : '',
                    user_dob : '',
                    competition_date : '',
                    user_age : '',
                    competition_id : '',
                    competition_event_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  '',
                    reg_user_ids: [],
                    group_name: '',
                    performance_title:'',
                    performance_description:'',
                    performance_music_path:[],
                    performance_durationInSeconds:'',                    
                    partners : [],
                    reg_user_id : '',
                }),                

                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                partner:  '',
                reg_user_id: '',
                autocompleteItems: [],
                autocompleteApplicantItems: [],
                max_partners: 0,
                debounce: null, 

                performance_music_url:'',
                isPerformanceOptionShow: false,                
                isPerformanceMusicLoaded: false,                 

            }
        },
        methods: {
          switchScreen(screen_id, row_item){
              switch( screen_id ){
                    case 'masterlist':
                        this.loadMasterList();
                        break;
                    case 'viewEntry':
                        if( row_item != null ){
                            this.sel_competition_event_id = row_item.competition_event_id;                    
                            this.sel_EVT = row_item.EVT;
                            this.sel_EventName = row_item.EventName;
                        }
                        this.loadViewEntryList();
                        break;
                    case 'player':
                        if( row_item != null ){
                            this.sel_organisation_id = row_item.id;                    
                            this.sel_organisation_item = row_item;
                        }                    
                        this.loadPlayers();    
                        break;                        
                    case 'registration':
                        if( row_item != null ){
                            this.sel_player_id = row_item.id;                    
                            this.sel_player_item = row_item;
                        }                        
                        this.loadRegistrations();
                        break;
                    case 'registrationReport':
                        if( row_item != null ){
                            this.sel_organisation_id = row_item.id;                    
                            this.sel_organisation_item = row_item;
                        }                    
                        this.loadRegistrationReport();    
                        break;                        

              }
              this.displaymode = screen_id;
          },
          loadMasterList(page = 1){
            // if(this.$gate.isAdmin()){
              this.masterlist = {};
              let postdata =  { };
              axios.post("api/mainboard/indexMasterList?page=" + page,postdata).then(({ data }) => {
                this.masterlist = data.data;

                this.list_competition_date = data.message.competition_date;
                this.list_competition_name = data.message.competition_name;
                this.last_gen_bib = data.message.last_gen_bib;
              });
            // }
          },          
          loadViewEntryList(page = 1){
            // if(this.$gate.isAdmin()){
              this.viewEntry = {};
              let postdata =  {
                competition_event_id : this.sel_competition_event_id,
              };
              axios.post("api/mainboard/indexViewEntryList?page=" + page,postdata).then(({ data }) => {
                this.viewEntry = data.data;
              });
            // }
          },          
          loadScoreSheet(page = 1){
            //this.organisations = {};
            var res;
            // if(this.$gate.isAdmin()){
              let postdata =  { 'eventid': this.sel_eventid  };
              axios.post("api/mainboard/getScoreByEventID",postdata).then(({ data }) => {

                //this.res = data.data;

                if( data.data['mainboard-run'] == null || data.data['mainboard-run'].length <= 0 ){
                    Swal.fire("Error", "Event not selected!", "warning");
                    return;
                }


                var $eventdata = data.data['mainboard-run'];

                var arrRunData, arrLiveScoreData;
                var arrRenderedLiveData = [];
                var arrRenderedColumn = [];
                var selEventid = 0;
                var arrScoreName = [ "score_Synchronisation", "score_Technique", "score_Performance", "score_Fluency", "score_Costume", "score_Creativity", "score_Formation", "score_Difficulty", "score_Music" ];
                var arrScoreTitle = [ "Sync", "Tech", "Perf", "Fluency", "Costume", "Creativity", "Formation", "Difficulty", "Music" ];

                var arrGroupScoreName = [ "score_Synchronisation", "score_Technique", "score_Performance", "score_Fluency", "score_Costume", "score_Creativity", "score_Formation", "score_Difficulty", "score_Music" ];
                var arrSoloScoreName = [ "score_Music", "score_Technique", "score_Performance", "score_Fluency", "score_Costume", "score_Creativity", "score_Musicality", "score_Difficulty" ];
                var arrGroupScoreTitle = [ "Sync", "Tech", "Perf", "Fluency", "Costume", "Creativity", "Formation", "Difficulty", "Music" ];
                var arrSoloScoreTitle = [ "Music", "Tech", "Perf", "Fluency", "Costume", "Creativity", "Musicality", "Difficulty" ];       


                arrLiveScoreData = data.data;
                arrRenderedColumn = [];
                arrRenderedLiveData = [];

                if( $eventdata.scoremode == "rank" ){

                    arrRenderedColumn.push( { key : 'ppid', label : 'Bib', sortable: false });
                    arrRenderedColumn.push( { key : 'CompetitorName', label : 'Competitor Name', sortable: false });

                    for(var i = 0; i < arrLiveScoreData.judge.length; i++ ){
                        htmlLiveScoreHeader += '<th>'+arrLiveScoreData.judge[i].judgename+'</th>';
                        arrRenderedColumn.push({ key : arrLiveScoreData.judge[i].judgeshortname, title : arrLiveScoreData.judge[i].judgeshortname, sortable: false  });
                    }

                    //$('#modelscore_scorelistTitle').html(htmlLiveScoreHeader);


                    for( var i = 0; i < arrLiveScoreData['mainboard-running-entrylist'].length; i++ ){

                        var datarow = [];
                        datarow['ppid'] = arrLiveScoreData['mainboard-running-entrylist'][i].ppid;
                        datarow['CompetitorName'] = arrLiveScoreData['mainboard-running-entrylist'][i].CompetitorName;

                        for(var j = 0; j < arrLiveScoreData.judge.length; j++ ){
                            var isFound = false;
                            if( arrLiveScoreData.score.length > 0 ){						
                                for(var k = 0; k < arrLiveScoreData.score.length; k++ ){
                                    if( arrLiveScoreData.score[k].judgeid == arrLiveScoreData.judge[j].judgeid && arrLiveScoreData.score[k].eid == $eventdata.eid ){
                                        var tmp = JSON.parse(arrLiveScoreData.score[k].scorejson);
                                        datarow[arrLiveScoreData.judge[j].judgeshortname] = tmp[arrLiveScoreData['mainboard-running-entrylist'][i].ppid];
                                        isFound = true;
                                    }
                                    
                                }
                            }
                            if ( isFound == false ) {
                                datarow[arrLiveScoreData.judge[j].judgeshortname] = "";
                            }
                        }
                        arrRenderedLiveData.push(datarow);
                        
                    }


                    this.sel_eventTitle = '[' + $eventdata.eid +  '] ' +$eventdata.EVT + ' - ' + $eventdata.EventName;

                    arrRenderedColumn.push( { key : 'actions', label : 'Action', sortable: false  });

                    this.scoreSheet_fields = arrRenderedColumn;
                    this.scoreSheet.data = arrRenderedLiveData;

                }

                if( $eventdata.scoremode == "score_solo" || $eventdata.scoremode == "score_group" ){


                    arrRenderedColumn.push( { key : 'judgeshortname', label : 'JID', sortable: false });
                    arrRenderedColumn.push( { key : 'judgename', label : 'Adjudicator', sortable: false });


                    switch($eventdata.scoremode){
                        case "score_solo":
                            for(var i = 0; i < arrSoloScoreName.length; i++ ){
                                arrRenderedColumn.push({ key : arrSoloScoreName[i], label : arrSoloScoreTitle[i], sortable: false });
                            }
                            break;
                        case "score_group":
                            for(var i = 0; i < arrGroupScoreName.length; i++ ){
                                arrRenderedColumn.push({ key : arrGroupScoreName[i], label : arrGroupScoreTitle[i], sortable: false });
                            }
                            break;
                    }                    

                    arrRenderedColumn.push( { key : 'totalscore', label : 'Total Score', sortable: true  });
                    arrRenderedColumn.push( { key : 'actions', label : 'Action', sortable: false  });
                    
                    //$('#modelscore_scorelistTitle').html(htmlLiveScoreHeader);

                    for( var i = 0; i <  arrLiveScoreData.judge.length; i++ ){

                        var datarow = [];
                        datarow['judgeshortname'] = arrLiveScoreData.judge[i].judgeshortname;
                        datarow['judgename'] = arrLiveScoreData.judge[i].judgename;

                        if( arrLiveScoreData.score.length > 0 ){		
                            
                            var isFound = false;
                            for(var k = 0; k < arrLiveScoreData.score.length; k++ ){
                                if( arrLiveScoreData.score[k].judgeid == arrLiveScoreData.judge[i].judgeid && arrLiveScoreData.score[k].eid == $eventdata.eid ){
                                    isFound = true;
                                    var tmp = JSON.parse(arrLiveScoreData.score[k].scorejson);
                                    var totalscore = 0;
                                    /*
                                    for( var l = 0; l < arrScoreName.length; l++ ){
                                        datarow[arrScoreName[l]] = tmp[arrScoreName[l]];
                                        totalscore += tmp[arrScoreName[l]];
                                    }
                                    */

                                    switch($eventdata.scoremode){
                                        case "score_solo":
                                            for(var m = 0; m < arrSoloScoreName.length; m++ ){
                                                datarow[arrSoloScoreName[m]] = tmp[arrSoloScoreName[m]];
                                                totalscore += tmp[arrSoloScoreName[m]];
                                            }
                                            break;
                                        case "score_group":
                                            for(var m = 0; m < arrGroupScoreName.length; m++ ){
                                                datarow[arrGroupScoreName[m]] = tmp[arrGroupScoreName[m]];
                                                totalscore += tmp[arrGroupScoreName[m]];
                                            }
                                            break;
                                    }   
                                    
                                    datarow['totalscore'] = totalscore;
                                }
                            }
                            if( isFound == false ){
                                /*
                                for( var l = 0; l < arrScoreName.length; l++ ){
                                    datarow[arrScoreName[l]] = "";
                                }												
                                */
                                switch($eventdata.scoremode){
                                    case "score_solo":
                                        for(var i = 0; i < arrSoloScoreName.length; i++ ){
                                            datarow[arrSoloScoreName[i]] = "";
                                        }
                                        break;
                                    case "score_group":
                                        for(var i = 0; i < arrGroupScoreName.length; i++ ){
                                            datarow[arrGroupScoreName[i]] = "";
                                        }
                                        break;
                                }   
                               
                                datarow['totalscore'] = 0;
                            }

                        }else{
                            /*
                            for( var l = 0; l < arrScoreName.length; l++ ){
                                datarow[arrScoreName[l]] = "";
                            }
                            */
                            switch($eventdata.scoremode){
                                case "score_solo":
                                    for(var i = 0; i < arrSoloScoreName.length; i++ ){
                                        datarow[arrSoloScoreName[i]] = "";
                                    }
                                    break;
                                case "score_group":
                                    for(var i = 0; i < arrGroupScoreName.length; i++ ){
                                        datarow[arrGroupScoreName[i]] = "";
                                    }
                                    break;
                            }                              
                            datarow['totalscore'] = "";                            
                        }

                        arrRenderedLiveData.push(datarow);
                        
                    }


                    this.sel_eventTitle = '[' + $eventdata.eid +  '] ' +$eventdata.EVT + ' - ' + $eventdata.EventName;

                    arrRenderedColumn.push( { key : 'actions', label : 'Action', sortable: false  });

                    this.scoreSheet_fields = arrRenderedColumn;
                    this.scoreSheet.data = arrRenderedLiveData;
                    this.scoreSheet_sortby = 'totalscore';
                    this.scoreSheet_sortDesc = true;


                }

              });
            // }
          },    

          insertBib(){

            Swal.fire({
                title: 'Overwrite existing bibs?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Insert Bib now!'
                }).then((result) => {

                    // Send request to the server
                      if (result.value) {

                        this.$Progress.start();

                        let postdata =  { };
                        axios.post("api/mainboard/insertBib",postdata).then(({ data }) => {

                          if( data.success ){
                            Toast.fire({
                              icon: 'success',
                              title: "Insert Bib Okay"
                            });
                            this.loadMasterList();
                            this.$Progress.finish();                  

                          }
                          

                        });
                        
                      }else{
                          Swal.fire("Failed!", result.message, "warning");
                          this.$Progress.fail();                  
                      }
                })
            
          },    
          syncBib(){

            Swal.fire({
                title: 'Overwrite existing bibs?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Sync Bib now!'
                }).then((result) => {

                    // Send request to the server
                      if (result.value) {

                        this.$Progress.start();

                        let postdata =  { };
                        axios.post("api/mainboard/syncBib",postdata).then(({ data }) => {

                          if( data.success ){
                            Toast.fire({
                              icon: 'success',
                              title: "Sync Bib Okay"
                            });
                            this.loadMasterList();
                            this.$Progress.finish();                  

                          }
                          

                        });
                        
                      }else{
                          Swal.fire("Failed!", result.message, "warning");
                          this.$Progress.fail();                  
                      }
                })
            
          },    

          clickMusic(action, row_item){

            this.$Progress.start();

            URL.revokeObjectURL(this.performance_music_url);
            this.performance_music_url = '';
            this.isPerformanceMusicLoaded = false;

            switch(action){
              case 'play':

                  axios.post('/api/eventRegistration/getMusicFile/'+row_item.eventid,{}, { responseType: 'blob' }).then((res) => { 

                    this.sel_EVT = row_item.EVT;
                    this.sel_registration_id = row_item.eventid;

                    const current_blob = new Blob([res.data],{type: res.headers['content-type']});
                    //console.table(new Blob([res.data],{type: res.headers['content-type']}).type);
                    //this.performance_music_url = URL.createObjectURL(current_blob);
                    this.performance_music_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                    //this.performance_music_url = URL.createObjectURL(new Blob([res.data]));

                    this.$refs.player.load();
                    this.$refs.player.controls = true;
                    this.isPerformanceMusicLoaded = true;
                    this.$refs.player.play();

                    axios.post('/api/mainboard/updateSysparam',{ data : {
                        'MasterMusic_eid' : row_item.eid,
                        'MasterMusic_EVT' : row_item.EVT,
                        'MasterMusic_registration_id' : this.sel_registration_id,
                    }});
                    

                    this.$Progress.finish();        
                    
                  }).catch((error) => {
                        Toast.fire({
                            icon: 'error',
                            title: error,
                        });                        
                    this.$Progress.fail();                    
                  });   

                break;
              case 'pause':
                  if( this.isPerformanceMusicLoaded ){
                    this.$refs.player.pause();
                  }
                  this.$Progress.finish();                        
                break;
            }

          },


          loadPlayers(page = 1){
            this.players = {};
            // if(this.$gate.isAdmin()){
              let postdata =  { 'competition_id': this.sel_competition_id, 'organisation_access_code' : this.sel_organisation_item.access_code };
              axios.post("api/manage/getPlayerList?page=" + page,postdata).then(({ data }) => (this.players = data.data));
            // }
          },    
          loadRegistrations(page = 1){
            // if(this.$gate.isAdmin()){
              this.registrations = {};
              let postdata =  { 'competition_id': this.sel_competition_id, 'organisation_access_code' : this.sel_organisation_item.access_code, 'user_id': this.sel_player_item.id };
              axios.post("api/manage/getRegistrationList?page=" + page,postdata).then(({ data }) => (this.registrations = data.data));
            // }
          },    
          loadRegistrationReport(page = 1){
            // if(this.$gate.isAdmin()){
              this.registrationReport = {};
              let postdata =  { 'competition_id': this.sel_competition_id, 'organisation_access_code' : this.sel_organisation_item.access_code };
              axios.post("api/manage/getRegistrationReport?page=" + page,postdata).then(({ data }) => (this.registrationReport = data.data));
            // }
          },    
          players_rowClass(item, type) {
                if (!item || type !== 'row') return
                if ( parseFloat(item.total_fee) > parseFloat(item.total_fee_approved) ) return 'table-danger'
            }, 
          registrations_rowClass(item, type) {
                if (!item || type !== 'row') return
                if ( parseInt(item.max_ppl) - parseInt(item.count_partners) != 1 ) return 'table-danger'
            },
           async newModalRegDetail(row_item){

                this.$Progress.start();

                try{

                    this.editmode = true;
                    this.form.reset();
                    this.partner = '';
                    this.partners = [];
                    this.form.partners = [];
                    this.autocompleteItems = [];
                    this.isPerformanceOptionShow = false;              
                    this.isPerformanceMusicLoaded = false;


                    let postdata = {'event_registration_id' : row_item.id};
                    let post = await axios.post('/api/eventRegistration/getEventRegistration', postdata);
                    let { data } = post;

                    this.form.id = data.data.id;
                    ///this.form.sort = data.data.sort;
                    //this.form.user_dob = data.data.user_dob;
                    //this.form.competition_date = data.data.competition_date;
                    //this.form.user_age  = data.data.user_age;
                    this.form.competition_id = data.data.competition_id;
                    this.form.competition_event_id = data.data.competition_event_id;
                    this.form.event_group_id = data.data.event_group_id;
                    this.form.event_type_id = data.data.event_type_id;
                    this.form.event_age_group_id = data.data.event_age_group_id;
                    //this.form.reg_user_ids = data.data.,
                    this.form.group_name = data.data.group_name;
                    this.form.performance_title = data.data.performance_title;
                    this.form.performance_description = data.data.performance_description;
                    this.form.performance_durationInSeconds = data.data.performance_durationInSeconds;
                    //this.form.partners = data.data.partners;
                    //this.form.reg_user_id = data.data.reg_user_id;

                    //this.form.fill(data.data);

                    $('#regDetail').modal('show');

                    //this.loadFormDefaultValues();
                    this.loadEventGroups();
                    this.loadEventTypes();
                    this.loadEventAgeGroups();
                    this.loadEventPartners();

                    axios.post('/api/eventRegistration/getMusicFile/'+row_item.id,{}, { responseType: 'blob' }).then((res) => { 

                        const current_blob = new Blob([res.data],{type: res.headers['content-type']});
                        //console.table(new Blob([res.data],{type: res.headers['content-type']}).type);
                        //this.performance_music_url = URL.createObjectURL(current_blob);
                        this.performance_music_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                        //this.performance_music_url = URL.createObjectURL(new Blob([res.data]));

                        this.$refs.player.load();
                        this.$refs.player.controls = true;
                        this.isPerformanceMusicLoaded = true;

                        this.$Progress.finish();                  

                    });

                }catch (error) {

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
                  this.$Progress.fail();             

                }

           },
          loadEventGroups(){

            if( this.editmode ){
              var $postdata = {
                'event_group_id' : this.form.event_group_id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfo",$postdata).then(({ data }) => (this.eventGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfoO",$postdata).then(({ data }) => (this.eventGroupItems = data.data));

            }
          },     
          loadEventAgeGroups(){

            if( this.editmode ){
              var $postdata = {
                'event_age_group_id' : this.form.event_age_group_id,
              };

              axios.post("/api/eventRegistration/getEventAgeGroupInfo",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'event_type_id' : this.form.event_type_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                 
              };

              this.$Progress.start();
              this.loadEventTypes();
              axios.post("/api/eventRegistration/getEventAgeGroupInfoO",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));

            }


            this.$Progress.finish();    

          },     
          loadEventTypes(){

            if( this.editmode ){
              var $postdata = {
                'event_type_id' : this.form.event_type_id,
              };

              axios.post("/api/eventRegistration/getEventTypeInfo",$postdata).then(({ data }) => {
                this.eventTypeItems = data.data;

                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );

                    if( a.grade_mode == 'rank'){
                      this.isPerformanceOptionShow = false;
                    }else{
                      this.isPerformanceOptionShow = true;
                    }                    
                  }
                });

              });

            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                
              };

              axios.post("/api/eventRegistration/getEventTypeInfoO", $postdata).then(({ data }) => {
                this.eventTypeItems = data.data;
                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );
                  }
                });

              });
            }



          },     
          loadEventPartners(){

            if( this.editmode ){
              var $postdata = {
                'event_registration_id' : this.form.id,
              };


              axios.post("/api/eventRegistration/getEventRegistrationPlayerU", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $partner_input = [];

                  this.autocompleteItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $partner_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $partner_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });
                  this.form.partners = $partner_input;
              })

              axios.post("/api/eventRegistration/getEventRegistrationApplicantO", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $applicant_input = [];

                  this.autocompleteApplicantItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $applicant_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $applicant_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });

                  this.form.reg_user_ids = $applicant_input;
             
              })


              //axios.post("/api/eventRegistration/getEventRegistrationPlayerU",$postdata).then(({ data }) => (this.form.partners = data.data));
            }else{
            }

          },      
          updateEventRegistration(){

              if( this.form.reg_user_ids.length < 1 ){
                Toast.fire({
                    icon: 'error',
                    title: 'Applicant is missing!'
                });
                return;
              }

              this.form.reg_user_id = this.form.reg_user_ids[0].id;

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'partners'){
                        postdata.append(k,this.form[k]);
                      }else{
                        postdata.append(k,JSON.stringify(this.form[k]));
                      }
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/eventRegistration/updateEntry', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#regDetail').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.onCloseModal();

                      this.loadEventRegistrations();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else if(error.response.status == 413){
                        Toast.fire({
                            icon: 'error',
                            title: 'File Too Large',
                        }); 
                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                   

              /*
              this.$Progress.start();
              this.form.put('/api/eventRegistration/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });

                  this.loadEventRegistrations();
                  this.loadEventGroups();

                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

              })
              .catch(() => {
                  this.$Progress.fail();
              });
              */

          },          


          /* Tags */
          updatePartners(newTags) {
            this.autocompleteItems = [];
            this.partners = newTags;
          },          
          updateApplicants(newTags) {
            this.autocompleteApplicantItems = [];
            this.form.reg_user_ids = newTags;

            if( this.form.reg_user_ids.length > 0 ){
              this.form.reg_user_id = this.form.reg_user_ids[0].id;
            }
            
            this.loadEventGroups();
          },            

          
          onCloseModal(){
            this.$refs.player.pause();
            URL.revokeObjectURL(this.performance_music_url);
            this.performance_music_url = '';
          },              

          exportXlsx(){

            let export_time = new Date();
            let str_export_tme = export_time.toLocaleString();
            switch( this.displaymode ){
                case 'masterlist':

                    var tableId = 'mainboard_master_Table';
                    var file_name = this.list_competition_date + " " + this.list_competition_name + ' - Mainboard - Master List ' + str_export_tme + '.xlsx';
                    var sheet_name = 'Mainboard - Master List';
                    this.render_xlsx( tableId, file_name, sheet_name );

                    break;
                case 'organisation':

                    var tableId = 'organisations_Table';
                    var file_name = this.sel_competition_name + ' - Manage Registration - Organisation List ' + str_export_tme + '.xlsx';
                    var sheet_name = 'Manage Reg - Organisation List';
                    this.render_xlsx( tableId, file_name, sheet_name );

                    break;
                case 'player':
                    var tableId = 'player_Table';
                    var file_name = this.sel_competition_name + ' - Manage Registration - Player List ' + str_export_tme + '.xlsx';
                    var sheet_name = 'Manage Reg - Player List';

                    this.render_xlsx( tableId, file_name, sheet_name );

                  break;
                case 'registrationReport':
                    var tableId = 'registrationReport_Table';
                    var file_name = this.sel_competition_name + ' - '+ this.sel_organisation_item.name1 + " - " + str_export_tme + '.xlsx';
                    var sheet_name = this.sel_organisation_item.name1;

                    this.render_xlsx( tableId, file_name, sheet_name );

                  break;

            }

          },        
          render_xlsx(tableId, file_name, sheetname )  {

            let workbook = XLSX.utils.book_new();
            let tbl = document.getElementById(tableId);                    
            let sheet1 = XLSX.utils.table_to_sheet(tbl);
            let sel_index = 0;
            /*
            for( let x = 0; x < this.competitions.data.length; x++ ){
                if( this.competitions.data[x].id == this.sel_competition_id  ){
                    sel_index = x;
                    break;
                }
            }
            let competition_name = this.competitions.data[sel_index].name1;
            */
            XLSX.utils.book_append_sheet(workbook, sheet1, sheetname); 

            XLSX.writeFile(workbook, file_name); 

          },


        },
        mounted() {
            console.log('Mainboard Master - Component mounted.')

            this.$refs.player.addEventListener('canplay', () => {
                axios.post('/api/mainboard/updateSysparam',{ data : {
                    'isOpenForGrading' : 'true',
                    'MasterMusic_isPlaying' : 'true',
                    'MasterMusic_timeStart' : Date.now(),
                    'MasterMusic_duration' : this.$refs.player.duration,
                    'MasterMusic_currentTime' : this.$refs.player.currentTime,
                }});
            });
            this.$refs.player.addEventListener('pause', () => {
                axios.post('/api/mainboard/updateSysparam',{ data : {
                    'MasterMusic_isPlaying' : 'false',
                    'MasterMusic_currentTime' : this.$refs.player.currentTime,
                }});
            });
            this.$refs.player.addEventListener('play', () => {
                axios.post('/api/mainboard/updateSysparam',{ data : {
                    'MasterMusic_isPlaying' : 'true',
                    'MasterMusic_duration' : this.$refs.player.duration,
                    'MasterMusic_currentTime' : this.$refs.player.currentTime,
                }});
            });            
            this.$refs.player.addEventListener('ended', () => {
                axios.post('/api/mainboard/updateSysparam',{ data : {
                    'MasterMusic_isPlaying' : 'false',
                    'MasterMusic_currentTime' : this.$refs.player.currentTime,
                }});
            });
        },
        unmounted() {
          this.$refs.player.removeEventListener('canplay');
          this.$refs.player.removeEventListener('pause');
          this.$refs.player.removeEventListener('play');
          this.$refs.player.removeEventListener('ended');
        },
        created() {
            this.$Progress.start();

            /*
            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;
            
            if( this.$route.params.competition_id !== undefined && this.$route.params.return_path !== undefined ){
              
              this.switchScreen('bib-list',this.competition_id);

            }else{
              this.loadCompetitions();
            }
            */

            //this.loadCategories();
            //this.loadTags();

            this.loadMasterList();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {

        },
    }
</script>

