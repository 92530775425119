<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Competition List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Sort</th>
                      <th>Name1</th>
                      <th>Organiser</th>
                      <th>Competition Date</th>
                      <th>isOpened</th>
                      <th>isVisible</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="Competition in competitions.data" :key="Competition.id">

                      <td>{{Competition.id}}</td> 
                      <td>{{Competition.sort}}</td> 
                      <td>{{Competition.name1}}</td>
                      <td>{{Competition.organiser}}</td>
                      <!--<td>{{Competition.organiser | truncate(90, '...')}}</td>-->
                      <td>{{Competition.competition_date}}</td>
                      <td>
                        <b-badge v-if="Competition.isopened==='true'" variant="success">Open</b-badge>
                        <b-badge v-if="Competition.isopened==='false'" variant="danger">Close</b-badge>
                      </td>
                      <td>
                        <b-badge v-if="Competition.isvisible==='true'" variant="success">Visible</b-badge>
                        <b-badge v-if="Competition.isvisible==='false'" variant="danger">Hidden</b-badge>
                      </td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                        <a href="#" @click="editModal(Competition)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteCompetition(Competition.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        /
                        <router-link :to="{ path: '/competitionEvents/'+Competition.id, params: { competition_id: Competition.id }}">
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <button type="button" class="btn btn-sm btn-primary">
                            <i class="fa fa-plus-square"></i>
                            Events
                        </button>
                        </router-link>
                        <!--
                        /
                        <router-link :to="{ path: '/competitionSchedule/'+Competition.id, params: { competition_id: Competition.id }}">
                        <button type="button" class="btn btn-sm btn-primary">
                            <i class="fas fa-clipboard-list"></i>
                            Schedule
                        </button>
                        </router-link>                        
                        /
                        <button type="button" class="btn btn-sm btn-primary" @click="newModalGenBid(Competition.id)">
                            <i class="fas fa-running"></i>
                            Bib Gernation
                        </button>
                        -->

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Competition</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Competition</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetition() : createCompetition()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Sort</label>
                            <input v-model="form.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sort') }">
                            <has-error :form="form" field="sort"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name1</label>
                            <input v-model="form.name1" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name1') }">
                            <has-error :form="form" field="name1"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name2</label>
                            <input v-model="form.name2" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name2') }">
                            <has-error :form="form" field="name2"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <vue-editor v-model="form.description" name="description"></vue-editor>
                            <!--
                            <input v-model="form.description" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                            -->
                        </div>
                        <div class="form-group">
                            <label>Organiser</label>
                            <input v-model="form.organiser" type="text" name="organiser"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('organiser') }">
                            <has-error :form="form" field="organiser"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Competition Date</label>
                            <input v-model="form.competition_date" type="date" name="competition_date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('competition_date') }">
                            <has-error :form="form" field="competition_date"></has-error>
                        </div>
                        
                        <div class="form-group">
                            <label>Open For Registration</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                v-model="form.isopened"
                                :options="iscompetitionopen_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="isopened"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>

                        <div class="form-group">
                            <label>Is Visible</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                v-model="form.isvisible"
                                :options="isYesNo_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="isvisible"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>                        

                        <div class="form-group">
                            <label>Is Schedule Open To Public</label>

                            <b-form-group
                              label=""
                            >
                              <b-form-radio-group
                                v-model="form.isScheduleOpenToPublic"
                                :options="isYesNo_options"
                                button-variant="outline-primary"
                                name="isScheduleOpenToPublic"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>     
                        <div class="form-group">
                            <label>Is Age Calucated by Year Only?</label>

                            <b-form-group
                              label=""
                            >
                              <b-form-radio-group
                                v-model="form.isAgeCalYearOnly"
                                :options="isYesNo_options"
                                button-variant="outline-primary"
                                name="isAgeCalYearOnly"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>     
                        
                        <div class="form-group">
                          <label>Replace Event Banner</label>
                          <b-form-file v-model="form.event_banner_file" name="event_banner_file" accept="image/*"></b-form-file>
                        </div>                        

                        <div class="form-group">
                            <label>Event Banner Preivew</label>
                            <img style="width: 100%;" v-bind:src="form.banner_base64"/>
                        </div>                        

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->

        <!-- Modal -->
        <div class="modal fade" id="modalGenBid" tabindex="-1" role="dialog" aria-labelledby="modalGenBid" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Bid Generation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetition() : createCompetition()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Starting Bib Number</label>
                            <input v-model="formGenBib.startBibNumber" type="text" name="startBibNumber"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('startBibNumber') }">
                            <has-error :form="formGenBib" field="startBibNumber"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Starting Time [AM/PM] HH:MM</label>
                            <input v-model="formGenBib.startTime" type="time" name="startTime"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('startTime') }">
                            <has-error :form="formGenBib" field="startTime"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Stat - Number of Entry</label>
                            <input v-model="formGenBib.numOfEntry" type="text" name="numOfEntry"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEntry') }">
                            <has-error :form="formGenBib" field="numOfEntry"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Stat - Number of Event</label>
                            <input v-model="formGenBib.numOfEvent" type="text" name="numOfEvent"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEvent') }">
                            <has-error :form="formGenBib" field="numOfEvent"></has-error>
                        </div>                        
                        <div class="form-group">
                            <label>Stat - Total Event Time</label>
                            <input v-model="formGenBib.totalEventTime" type="text" name="totalEventTime"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('totalEventTime') }">
                            <has-error :form="formGenBib" field="totalEventTime"></has-error>
                        </div>                        
                        <div class="form-group">
                            <label>Stat - Last Generation Time</label>
                            <input v-model="formGenBib.numOfEntry" type="text" name="numOfEntry"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEntry') }">
                            <has-error :form="formGenBib" field="numOfEntry"></has-error>
                        </div>                        

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success" @click="runGeneration" >Run Generation</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import { VueEditor } from "vue2-editor";
    import VueRouter from 'vue-router';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter, VueEditor
        },
        data () {
            return {
                editmode: false,
                competitions : {},
                form: new Form({
                    id : '',
                    sort : '',
                    name1 : '',
                    name2: '',
                    description: '',
                    organiser:  '',
                    competition_date: '',
                    isopened: '',
                    isvisible: '',
                    isScheduleOpenToPublic: '',
                    isAgeCalYearOnly:'',
                    banner_base64:'',
                    event_banner_file:[],
                }),
                formGenBib: new Form({
                    competition_id : '',
                    startBibNumber : '',
                    startTime : '',
                }),                
                iscompetitionopen_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],
                isYesNo_options: [
                    { text: 'Yes', value: 'true' },
                    { text: 'No', value: 'false' },
                  ],                
                
                //categories: [],
              
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/competition?page=' + page).then(({ data }) => (this.competitions = data.data));

              this.$Progress.finish();
          },
          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition?img=true").then(({ data }) => (this.competitions = data.data));
            // }
          },
          loadCompetitionEvent(id){
            console.log('hihi');
            this.$router.go('/competitionEvents');
          },
          editModal(Competition){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(Competition);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          runGeneration(){
                axios.post('/api/competition/generateEvent', {
                        'competition_id' : this.formGenBib.competition_id,
                        'startBibNumber' : this.formGenBib.startBibNumber,
                        'startTime' : this.formGenBib.startTime,
                     })
                    .then(response => {

                        console.log(response.data);

                    });
          },
          newModalGenBid(id){
                this.formGenBib.reset();              
                this.formGenBib.competition_id = id;
                this.formGenBib.startBibNumber = 1;
                this.formGenBib.startTime = '10:00';
                axios.post('/api/competition/getGenerateEventStat', { 'competition_id' : id})
                    .then(response => {

                        console.log(response.data);

                    });

              $('#modalGenBid').modal('show');
          },
          createCompetition(){
              this.$Progress.start();

              this.form.post('api/competition')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCompetitions();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCompetition(){

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'banner_base64'){
                        postdata.append(k,this.form[k]);
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }


              axios.post('/api/competition/updateEntry', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadCompetitions();

                  })
                  .catch(() => {
                      this.$Progress.fail();
                  });                  

              /*
              this.form.post('api/competition/updateEntry')
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadCompetitions();
              })
              .catch(() => {
                  this.$Progress.fail();
              });
              */

          },
          deleteCompetition(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/competition/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadCompetitions();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Competitions - Component mounted.')
        },
        created() {
            this.$Progress.start();

            this.loadCompetitions();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
