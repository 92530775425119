<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Competition List</h3>

                <div class="card-tools">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Organiser</th>
                      <th>Competition Date</th>
                      <th>Registered Nos.</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="Competition in competitions.data" :key="Competition.id">

                      <td>{{Competition.name1}}</td>
                      <td>{{Competition.organiser}}</td>
                      <!--<td>{{Competition.organiser | truncate(90, '...')}}</td>-->
                      <td>{{Competition.competition_date}}</td>
                      <td>{{Competition.count_registrations}}</td>
                      <td>
                        <b-badge v-if="Competition.isopened==='true'" variant="success">Open</b-badge>
                        <b-badge v-if="Competition.isopened==='false'" variant="danger">Close</b-badge>
                      </td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                        <router-link :to="{ path: '/eventRegistrationEvents/'+Competition.id+'/'+'eventRegistrations', params: { competition_id: Competition.id }}">
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <button type="button" class="btn btn-sm btn-primary">
                            <i class="fa fa-plus-square"></i>
                            Registration
                        </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <!--
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Competition</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Competition</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetition() : createCompetition()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Sort</label>
                            <input v-model="form.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sort') }">
                            <has-error :form="form" field="sort"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name1</label>
                            <input v-model="form.name1" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name1') }">
                            <has-error :form="form" field="name1"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name2</label>
                            <input v-model="form.name2" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name2') }">
                            <has-error :form="form" field="name2"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <input v-model="form.description" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Organiser</label>
                            <input v-model="form.organiser" type="text" name="organiser"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('organiser') }">
                            <has-error :form="form" field="organiser"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Competition Date</label>
                            <input v-model="form.competition_date" type="date" name="competition_date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('competition_date') }">
                            <has-error :form="form" field="competition_date"></has-error>
                        </div>
                        
                        <div class="form-group">
                            <label>Open For Registration</label>

                            <b-form-group
                              label="Button style radios with outline-primary variant and size lg"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-2"
                                v-model="form.isopened"
                                :options="iscompetitionopen_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                size="lg"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        -->
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                editmode: false,
                competitions : {},
                form: new Form({
                    id : '',
                    sort : '',
                    name1 : '',
                    name2: '',
                    description: '',
                    organiser:  '',
                    competition_date: '',
                    isopened: '',
                }),
                iscompetitionopen_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],
                
                
                //categories: [],
              
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/competition?page=' + page).then(({ data }) => (this.competitions = data.data));

              this.$Progress.finish();
          },
          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition").then(({ data }) => (this.competitions = data.data));
            // }
          },
          loadCompetitionEvent(id){
            console.log('hihi');
            this.$router.go('/competitionEvents');
          },
          editModal(Competition){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(Competition);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createCompetition(){
              this.$Progress.start();

              this.form.post('api/competition')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCompetitions();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCompetition(){
              this.$Progress.start();
              this.form.put('api/competition/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadCompetitions();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteCompetition(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/competition/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadCompetitions();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Competitions - Component mounted.')
        },
        created() {
            this.$Progress.start();

            this.loadCompetitions();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
