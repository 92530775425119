<template>
    <section class="content">
        <div class="container-fluid">

            <router-link :to="{ path: '/eventboard'}">
                <b-button variant="primary">Back</b-button>
            </router-link>

            <div id="eventFrame" >
            <b-jumbotron 
                header-level="5" 
                v-for="Competition in competitions.data" :key="Competition.id"
            >
                <template #header>{{Competition.name1}}</template>

                <template #lead>
                    {{Competition.name1}}
                </template>

                <hr class="my-4">
                    <span v-html="Competition.description"></span>
                <hr class="my-4">                


                <b-card-group deck>

                    <b-card bg-variant="dark" text-variant="white" title="立即報名 Registration">
                        <b-card-text>
                        </b-card-text>
                        <router-link v-if="$gate.isUser()" :to="{ path: '/eventRegistrationEvents/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary">Registration <b-badge variant="success">{{Competition.count_registrations}}</b-badge></b-button>
                        </router-link>
                        <router-link v-if="$gate.isAdminOrOrganiser()" :to="{ path: '/organisationRegistrationEvents/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary">Registration <b-badge variant="success">{{Competition.count_registrations}}</b-badge></b-button>
                        </router-link>
                    </b-card>

                    <b-card bg-variant="dark" text-variant="white" title="參賽號碼 Bib Number">
                        <b-card-text>
                        </b-card-text>
                        <router-link v-if="$gate.isUser()" :to="{ path: '/userRegistrationBib/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary" :disabled="Competition.isScheduleOpenToPublic != 'true'">View</b-button>
                        </router-link>
                        <router-link v-if="$gate.isAdminOrOrganiser()" :to="{ path: '/organisationRegistrationBib/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary" :disabled="Competition.isScheduleOpenToPublic != 'true'">View</b-button>
                        </router-link>
                    </b-card>

                    <b-card bg-variant="dark" text-variant="white" title="賽程 Schedule">
                        <b-card-text>
                        </b-card-text>
                        <router-link v-if="$gate.isUser()" :to="{ path: '/userRegistrationSchedule/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary" :disabled="Competition.isScheduleOpenToPublic != 'true'">View</b-button>
                        </router-link>
                        <router-link v-if="$gate.isAdminOrOrganiser()" :to="{ path: '/organisationRegistrationSchedule/' + Competition.id + '/' + 'eventboard', params: {  competition_id: Competition.id , return_path: 'dashboard' }  }">                    
                            <b-button variant="primary" :disabled="Competition.isScheduleOpenToPublic != 'true'">View</b-button>
                        </router-link>
                    </b-card>                
                
                </b-card-group>

            </b-jumbotron>
            </div>

        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import { BJumbotron, BImg, BCard, BCardGroup, BCardText, BButton, BBadge } from 'bootstrap-vue';
    export default {
        components: {
            BJumbotron, BImg, BCard, BCardGroup, BCardText, BButton, BBadge
        },
        data () {
            return {
                competition_id:'',
                competitions : {},                
            }
        },
        methods: {

          loadCompetitions(){

                axios.post('/api/competition/getCompetitionInfoCount', {
                        'competition_id' : this.competition_id,
                     })
                    .then(response => {

                        this.competitions = response.data;

                    });

          },

        },        
        mounted() {
            //console.log('Component mounted.')
            this.competition_id = this.$route.params.competition_id;
            this.loadCompetitions();
        }
    }
</script>

<style scoped>

</style>
