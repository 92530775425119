<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div v-if="displaymode=='competition'" class="card">
              <div class="card-header">
                <h3 class="card-title">Bib Number Generation</h3>

                <div class="card-tools">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Sort</th>
                      <th>Name1</th>
                      <th>Organiser</th>
                      <th>Competition Date</th>
                      <th>Registration Nos.</th>
                      <th>Empty Bib Left</th>
                      <th>Last Generation</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="Competition in competitions.data" :key="Competition.id">

                      <td>{{Competition.id}}</td> 
                      <td>{{Competition.sort}}</td> 
                      <td>{{Competition.name1}}</td>
                      <td>{{Competition.organiser}}</td>
                      <!--<td>{{Competition.organiser | truncate(90, '...')}}</td>-->
                      <td>{{Competition.competition_date}}</td>
                      <td>{{Competition.count_registrations}}</td>
                      <td>{{Competition.count_empty_bib}}</td>
                      <td>{{Competition.bib_generated_at}}</td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                       
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('bib-list',Competition.id)">
                            <i class="fas fa-scroll"></i>
                            Bib List
                        </button>
                       
                        /
                        <button type="button" class="btn btn-sm btn-primary" @click="newModalGenBid(Competition.id)">
                            <i class="fas fa-running"></i>
                            Bib Gernation
                        </button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div v-if="displaymode=='bib-list'" class="card">
              <div class="card-header">
                <h3 class="card-title">Bib List</h3>

                <div class="card-tools">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->

                    <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition')">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>    

                    <b-dropdown id="dropdown-right" text="Export" variant="primary" class="m-3">
                      <b-dropdown-item href="#" @click="exportXlsx()">xlsx</b-dropdown-item>
                    </b-dropdown>                                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table id="tblBib" class="table table-hover">
                  <thead>
                    <tr>
                      <th>Bib</th>
                      <th>Organisation</th>
                      <th>Name</th>
                      <th>Chi Name</th>
                      <th>DOB</th>
                      <th>Registration Nos.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="aBib in bibList.data" :key="aBib.bib">

                      <td>{{aBib.bib}}</td> 
                      <td>{{aBib.organisation_name}}</td> 
                      <td>{{aBib.player_name}}</td>
                      <td>{{aBib.player_chi_name}}</td>
                      <td>{{aBib.dob}}</td>
                      <td>{{aBib.reg_nos}}</td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <!--
                        <button type="button" class="btn btn-sm btn-primary">
                            <i class="fa fa-plus-square"></i>
                            Bib List
                        </button>
                        /
                        <button type="button" class="btn btn-sm btn-primary" @click="newModalGenBid(Competition.id)">
                            <i class="fas fa-running"></i>
                            Bib Gernation
                        </button>
                        -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="bibList" @pagination-change-page="getBibResults"></pagination>
              </div>
            </div>
            <!-- /.card -->

          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Competition</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Competition</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetition() : createCompetition()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Sort</label>
                            <input v-model="form.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sort') }">
                            <has-error :form="form" field="sort"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name1</label>
                            <input v-model="form.name1" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name1') }">
                            <has-error :form="form" field="name1"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Name2</label>
                            <input v-model="form.name2" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name2') }">
                            <has-error :form="form" field="name2"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <input v-model="form.description" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Organiser</label>
                            <input v-model="form.organiser" type="text" name="organiser"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('organiser') }">
                            <has-error :form="form" field="organiser"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Competition Date</label>
                            <input v-model="form.competition_date" type="date" name="competition_date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('competition_date') }">
                            <has-error :form="form" field="competition_date"></has-error>
                        </div>
                        
                        <div class="form-group">
                            <label>Open For Registration</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-2"
                                v-model="form.isopened"
                                :options="iscompetitionopen_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                size="lg"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->

        <!-- Modal -->
        <div class="modal fade" id="modalGenBid" tabindex="-1" role="dialog" aria-labelledby="modalGenBid" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Bid Generation</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetition() : createCompetition()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Starting Bib Number</label>
                            <input v-model="formGenBib.startBibNumber" type="text" name="startBibNumber"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('startBibNumber') }">
                            <has-error :form="formGenBib" field="startBibNumber"></has-error>
                        </div>
                        <!--
                        <div class="form-group">
                            <label>Starting Time [AM/PM] HH:MM</label>
                            <input v-model="formGenBib.startTime" type="time" name="startTime"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('startTime') }">
                            <has-error :form="formGenBib" field="startTime"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Stat - Number of Entry</label>
                            <input v-model="formGenBib.numOfEntry" type="text" name="numOfEntry"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEntry') }">
                            <has-error :form="formGenBib" field="numOfEntry"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Stat - Number of Event</label>
                            <input v-model="formGenBib.numOfEvent" type="text" name="numOfEvent"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEvent') }">
                            <has-error :form="formGenBib" field="numOfEvent"></has-error>
                        </div>                        
                        <div class="form-group">
                            <label>Stat - Total Event Time</label>
                            <input v-model="formGenBib.totalEventTime" type="text" name="totalEventTime"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('totalEventTime') }">
                            <has-error :form="formGenBib" field="totalEventTime"></has-error>
                        </div>                        
                        <div class="form-group">
                            <label>Stat - Last Generation Time</label>
                            <input v-model="formGenBib.numOfEntry" type="text" name="numOfEntry"
                                class="form-control" :class="{ 'is-invalid': formGenBib.errors.has('numOfEntry') }">
                            <has-error :form="formGenBib" field="numOfEntry"></has-error>
                        </div>                        
                        -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success" @click="runGeneration" >Run Generation</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    import * as XLSX from 'xlsx';    

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'competition',
                editmode: false,
                competitions : {},
                bibList : {},
                sel_competition_id : '',
                form: new Form({
                    id : '',
                    sort : '',
                    name1 : '',
                    name2: '',
                    description: '',
                    organiser:  '',
                    competition_date: '',
                    isopened: '',
                }),
                formGenBib: new Form({
                    competition_id : '',
                    startBibNumber : '',
                    startTime : '',
                }),                
                iscompetitionopen_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],


                //categories: [],
              
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {
          switchScreen(screen_id, competition_id = -1){
              this.sel_competition_id = competition_id;
              switch( screen_id ){
                  case 'competition':

                    this.loadCompetitions();
                    break;
                  case 'bib-list':
                    this.loadBibList();
                    break;
              }
              this.displaymode = screen_id;
          },
          exportXlsx(){

            let workbook = XLSX.utils.book_new();

            let tbl = document.getElementById('tblBib');

            let sheet1 = XLSX.utils.table_to_sheet(tbl);

            //let sheet1 = XLSX.utils.aoa_to_sheet(data1);

            let sel_index = 0;
            for( let x = 0; x < this.competitions.data.length; x++ ){
              if( this.competitions.data[x].competition_id == this.sel_competition_id  ){
                sel_index = x;
                break;
              }
            }

            let competition_name = this.competitions.data[sel_index].name1;
            let export_time = new Date();
            let str_export_tme = export_time.toLocaleString();
            let file_name = competition_name + ' - Bib List ' + str_export_tme + '.xlsx';


            XLSX.utils.book_append_sheet(workbook, sheet1, 'Bib List'); 

            XLSX.writeFile(workbook, file_name); 


          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/competition/indexBib?page=' + page).then(({ data }) => (this.competitions = data.data));

              this.$Progress.finish();
          },
          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition/indexBib").then(({ data }) => (this.competitions = data.data));
            // }
          },
          getBibResults(page = 1) {

              this.$Progress.start();
              
                //axios.get('api/competition/getBibList?page=' + page).then(({ data }) => (this.bibList = data.data));
                axios.get("/api/competition/getBibList",{ params: {'page' : page, competition_id: this.sel_competition_id}} ).then(({ data }) => (this.bibList = data.data));

              this.$Progress.finish();
          },
          loadBibList(){
            // if(this.$gate.isAdmin()){
                axios.get("/api/competition/getBibList",{ params: {competition_id: this.sel_competition_id}} ).then(({ data }) => (this.bibList = data.data));
            // }
          },          
          loadCompetitionEvent(id){
            console.log('hihi');
            this.$router.go('/competitionEvents');
          },
          editModal(Competition){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(Competition);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          runGeneration(){
                axios.post('/api/competition/generateBib', {
                        'competition_id' : this.formGenBib.competition_id,
                        'startBibNumber' : this.formGenBib.startBibNumber,
                        'startTime' : this.formGenBib.startTime,
                     })
                    .then(response => {

                        if( response.data.data.num_bib_indexed != null && response.data.data.last_bib_number != null ){

                            if( response.data.data.num_bib_indexed > 0 ){
                                Swal.fire(
                                    'Generated!',
                                    'Number of Bib generated : '+ response.data.data.num_bib_indexed + '\n\r<br/>'+
                                    'Last Bib Number is ' + response.data.data.last_bib_number,
                                    'success'
                                );
                            }else{
                                Swal.fire(
                                    'Executed!',
                                    'No registration found!',
                                    'warning'
                                );
                            }

                        }else{
                            
                            Toast.fire({
                                icon: 'error',
                                title: 'Some error occured! Please try again'
                            });

                        }

                        // Fire.$emit('AfterCreate');
                        $('#modalGenBid').modal('hide');
                        this.loadCompetitions();
                    });
          },
          newModalGenBid(id){
                this.formGenBib.reset();              
                this.formGenBib.competition_id = id;
                this.formGenBib.startBibNumber = 1;
                this.formGenBib.startTime = '10:00';
                axios.post('/api/competition/getGenerateEventStat', { 'competition_id' : id})
                    .then(response => {

                        console.log(response.data);

                    });

              $('#modalGenBid').modal('show');
          },
          createCompetition(){
              this.$Progress.start();

              this.form.post('api/competition')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadCompetitions();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCompetition(){
              this.$Progress.start();
              this.form.put('api/competition/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadCompetitions();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteCompetition(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/competition/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadCompetitions();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Competitions - Component mounted.')

        },
        created() {
            this.$Progress.start();

            this.loadCompetitions();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
