<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div v-if="displaymode=='competition'" class="card">
              <div class="card-header">
                <h3 class="card-title">Payment</h3>

                <div class="card-tools">
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <!--<th>ID</th>-->
                      <th>Name</th>
                      <th>Organiser</th>
                      <th>Competition Date</th>
                      <th>Reg Nos</th>
                      <th>Total Amount</th>
                      <th>Approved Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="Competition in competitions.data" :key="Competition.id">

                      <!--<td>{{Competition.id}}</td> -->
                      <td>{{Competition.name1}}</td>
                      <td>{{Competition.organiser}}</td>
                      <!--<td>{{Competition.organiser | truncate(90, '...')}}</td>-->
                      <td>{{Competition.competition_date}}</td>
                      <td>{{Competition.count_registrations}}</td>
                      <td>{{Competition.total_fee == null ? 0 : Competition.total_fee}}</td>
                      <td>{{Competition.total_fee_approved == null ? 0 : Competition.total_fee_approved }}</td>
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
                      <td>
                        
                       
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <!--
                        <button type="button" class="btn btn-sm btn-primary"  @click="switchScreen('bib-list',Competition.id)">
                            <i class="fas fa-scroll"></i>
                            Payment List
                        </button>
                        -->

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->

            <div v-if="displaymode=='bib-list'" class="card">
              <div class="card-header">
                <h3 class="card-title">Payment List</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition')">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-table
                  :items="bibList.data"
                  :fields="bibList_fields"
                  primary-key="bib"
                  responsive="sm"
                  stacked="sm"
                >

                  <template #cell(actions)="row">
                    <a href="#" @click="editModal(row.item)">
                        <i class="fa fa-edit blue"></i>
                    </a>
                    /
                    <a href="#" @click="deletePayment(row.item.id)">
                        <i class="fa fa-trash red"></i>
                    </a>
                  </template>

                  <template slot="bottom-row" slot-scope="data">
                    <td>Total</td>
                    <td></td>
                    <td>{{totalAmountPayment}}</td>
                    <td></td>
                    <td>{{totalAmountApproval}}</td>
                    <td></td>
                  </template>                  


                </b-table>
<!--
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Bib</th>
                      <th>Organisation</th>
                      <th>Name</th>
                      <th>Chi Name</th>
                      <th>DOB</th>
                      <th>Registration Nos.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="aBib in bibList.data" :key="aBib.bib">

                      <td>{{aBib.bib}}</td> 
                      <td>{{aBib.organisation_name}}</td> 
                      <td>{{aBib.player_name}}</td>
                      <td>{{aBib.player_chi_name}}</td>
                      <td>{{aBib.dob}}</td>
                      <td>{{aBib.reg_nos}}</td>
-->                      
                      <!-- <td><img v-bind:src="'/' + Competition.photo" width="100" alt="Competition"></td> -->
<!--                      
                      <td>
-->                        
                        <!--<button type="button" class="btn btn-sm btn-primary" @click="loadCompetitionEvent(Competition.id)">-->
                        <!--
                        <button type="button" class="btn btn-sm btn-primary">
                            <i class="fa fa-plus-square"></i>
                            Bib List
                        </button>
                        /
                        <button type="button" class="btn btn-sm btn-primary" @click="newModalGenBid(Competition.id)">
                            <i class="fas fa-running"></i>
                            Bib Gernation
                        </button>
                        -->
<!--                        
                      </td>
                    </tr>
                  </tbody>
                </table>
-->                
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="bibList" @pagination-change-page="getBibResults"></pagination>
              </div>
            </div>
            <!-- /.card -->

          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Payment</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Payment</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updatePayment() : createPayment()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Payment Date</label>
                            <input v-model="form.payment_date" type="date" name="payment_date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('payment_date') }">
                            <has-error :form="form" field="payment_date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Payment Type</label>
                              <b-form-select v-model="form.payment_type" name="payment_type" class="mb-3">
                                <b-form-select-option value="ATM Transfer">ATM Transfer</b-form-select-option>
                                <b-form-select-option value="Online/Mobile Banking">Online/Mobile Banking</b-form-select-option>
                                <b-form-select-option value="Chqeue Deposit">Chqeue Deposit</b-form-select-option>
                                <b-form-select-option value="Cash Deposit">Cash Deposit</b-form-select-option>
                                <b-form-select-option value="Others">Others</b-form-select-option>
                              </b-form-select>                              
                            <has-error :form="form" field="payment_type"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Payment Amount</label>
                            <input v-model="form.payment_amt" type="text" name="payment_amt"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('payment_amt') }">
                            <has-error :form="form" field="payment_amt"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Remarks</label>
                            <input v-model="form.description" type="text" name="remarks"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('remarks') }">
                            <has-error :form="form" field="remarks"></has-error>
                        </div>

                        <div class="form-group">
                          <label>Replace Payment File</label>
                          <b-form-file v-model="form.payment_file" name="payment_file" accept="image/*"></b-form-file>
                        </div>                        

                        <div class="form-group">
                            <label>Payment File Preivew</label>
                            <img style="width: 100%;" v-bind:src="form.payment_file_base64"/>
                        </div>                             

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- Modal End -->

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import VueRouter from 'vue-router';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, VueRouter
        },
        data () {
            return {
                displaymode: 'competition',
                editmode: false,

                competitions : {},

                bibList : {},
                bibList_fields: [
                  
                  {
                    label: 'Name',
                    key: 'player_name',
                    sortable: true,
                  },
                  {
                    label: 'Chi Name',
                    key: 'payer_chi_name',
                    //sortable: true,
                  },
                  {
                    label: 'Reg Nos',
                    key: 'count_registrations',
                    sortable: true,
                  },
                  {
                    label: 'Total Amount',
                    key: 'total_fee',
                    sortable: true,
                  },
                  {
                    label: 'Approval Amount',
                    key: 'total_fee_approved',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                  

                ],

                sel_competition_id : '',
                form: new Form({
                    id : '',
                    payment_date : '',
                    payment_type : '',
                    payment_amt: '',
                    remarks: '',
                    payment_file:  [],
                    payment_file_base64: '',
                }),

                
                competition_id:'',
                return_path:'',                
                
                //categories: [],
              
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {
          switchScreen(screen_id, competition_id = -1){
              this.sel_competition_id = competition_id;
              switch( screen_id ){
                  case 'competition':

                    if( this.return_path !== undefined ){
                      this.$router.push('/' + this.return_path )                      
                    }                    
                    this.loadCompetitions();
                    break;
                  case 'bib-list':
                    this.loadBibList();
                    break;
              }
              this.displaymode = screen_id;
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/competition/indexPayment?page=' + page).then(({ data }) => (this.competitions = data.data));

              this.$Progress.finish();
          },
          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition/indexPayment").then(({ data }) => (this.competitions = data.data));
            // }
          },
          getBibResults(page = 1) {

              this.$Progress.start();
              
                //axios.get('api/competition/getBibList?page=' + page).then(({ data }) => (this.bibList = data.data));
                axios.get("/api/competition/getPaymentList",{ params: {'page' : page, competition_id: this.sel_competition_id}} ).then(({ data }) => (this.bibList = data.data));

              this.$Progress.finish();
          },
          loadBibList(){
            // if(this.$gate.isAdmin()){
                axios.get("/api/competition/getPaymentList",{ params: {competition_id: this.sel_competition_id}} ).then(({ data }) => (this.bibList = data.data));
            // }
          },          
          loadCompetitionEvent(id){
            //console.log('hihi');
            this.$router.go('/competitionEvents');
          },
          editModal(Competition){
              console.log(Competition);
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(Competition);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },

          createPayment(){

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'payment_file_base64'){
                        postdata.append(k,this.form[k]);
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }
              postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/competitionPayment/', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.loadBibList();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                  

          },
          updatePayment(){
              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'payment_file_base64'){
                        postdata.append(k,this.form[k]);
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }
              postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/competitionPayment/updateEntry', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.loadBibList();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });                    
                      }
                      this.$Progress.fail();
                  });                  

          },
          deletePayment(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/competitionPayment/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your payment has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadBibList();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('Competitions - Component mounted.')


        },
        created() {
            this.$Progress.start();

            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;
            
            if( this.$route.params.competition_id !== undefined && this.$route.params.return_path !== undefined ){
              
              this.switchScreen('bib-list',this.competition_id);

            }else{
              this.loadCompetitions();
            }

            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
          totalAmountPayment: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + parseInt(item.payment_amt));
            }, 0);   
          },
          totalAmountApproval: function() {
            if( typeof this.bibList.data === 'undefined')
              return;
            return this.bibList.data.reduce((total, item) => {
              return (total + isNaN(parseFloat(item.approval_amt)) ? 0 : parseFloat(item.approval_amt) );
            }, 0);          
          },
        },
    }
</script>
