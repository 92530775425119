<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrOrganiser()">
              <div class="card-header">
                <h3 class="card-title"><span id="organisation_name"></span> <br> Organisation User List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Chi Name</th>
                      <th>Email</th>
                      <th>Contact</th>
                      <th>DOB</th>
                      <th>Nick Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">

                      <td class="text-capitalize">{{user.type}}</td>
                      <td>{{user.name}}</td>
                      <td>{{user.chi_name}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.phone}}</td>
                      <td>{{user.dob}}</td>
                      <td>{{user.nick_name}}</td>

                      <td>

                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrOrganiser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" @input="autofillNickName()" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Chinese Name</label>
                            <input v-model="form.chi_name" type="text" name="chi_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('chi_name') }">
                            <has-error :form="form" field="chi_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nick Name</label>
                            <input v-model="form.nick_name" type="text" name="nick_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nick_name') }">
                            <has-error :form="form" field="nick_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Contact Number</label>
                            <input v-model="form.phone" type="text" name="phone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Date of birth</label>
                            <input v-model="form.dob" type="date" name="dob"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('dob') }">
                            <has-error :form="form" field="dob"></has-error>
                        </div>

                        <div class="form-group" v-show="form.type != 'nologin' && form.email_verified_at == null">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                            <has-error :form="form" field="password"></has-error>
                        </div>

                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <option value="organiser">Organiser</option>
                                <option value="user">Standard User</option>
                                <option value="nologin">No Login</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    chi_name: '',
                    nick_name: '',
                    dob: '',
                    password:'',
//                    organisation_access_code: '',
                    email: '',
                    email_verified_at:'',
//                    password: '',
//                    email_verified_at: '',
                }),
                organisationItems: [],                
            }
        },
        methods: {

            getResults(page = 1) {


                  this.$Progress.start();

                    if(this.$gate.isAdminOrOrganiser()){
                        axios.get("api/user/listOrganisationUsers").then(({ data }) => (this.users = data.data));
                    }
                    this.loadOrganisations();

                  this.$Progress.finish();
            },
            autofillNickName(){
                if( !this.editmode ){
                    this.form.nick_name = this.form.name.toLowerCase().replace(/ /g,'') + + Math.floor(Math.random() * 100);
                    this.form.email = this.form.name.toLowerCase().replace(/ /g,'') + '@nologin';
                }
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                //this.form.put('api/user/updateOrganisationUsers'+this.form.id)
                this.form.post('api/user/updateOrganisationUsers')
                //axios.post("api/user/updateOrganisationUsers", this.form)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                this.form.errors.clear();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                this.form.errors.clear();
                this.form.type="nologin";
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.id = id;
                                this.form.post('api/user/deleteOrganisationUsers').then(()=>{
                                //this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'User has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
      

          loadUsers(){
            this.$Progress.start();

            if(this.$gate.isAdminOrOrganiser()){
              axios.get("/api/user/getUserOrganisationName").then(({ data }) => {$('#organisation_name').html('['+data.data.name+']');});
              axios.get("/api/user/listOrganisationUsers").then(({ data }) => (this.users = data.data));
            }
            //this.loadOrganisations();

            this.$Progress.finish();
          },
          
          createUser(){

              this.form.post('api/user/createOrganisationUsers')
              //this.form.post('api/user')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            console.log('Organisation User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
