<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><span id="competition_title"></span> Event List **</h3>

                <div class="card-tools">
                  
                  <router-link :to="{ path: '/competitions'}">
                    <button type="button" class="btn btn-sm btn-primary">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>
                  </router-link>
<!--        
                  <button type="button" class="btn btn-sm btn-primary" @click="getSchedule">
                     <i class="fas fa-clipboard-list"></i>
                      Get Schedule
                  </button>

                  <button type="button" class="btn btn-sm btn-primary" @click="newTimeSchModal">
                     <i class="fas fa-stopwatch"></i>
                      Time Scheduling
                  </button>
-->
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <!--<th>ID</th>-->
                      <th>Sort</th>
                      <!--<th>Sch. Time</th>-->
                      <th>Run(sec)</th>
                      <th>Group</th>
                      <th>Type</th>
                      <th>Age Group</th>
                      <th>Dance</th>
                      <th>Round</th>
                      <!--<th>Zone</th>-->
                      <th>Player Nos.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(CompetitionEvent, index) in competitionEvents.data" :key="CompetitionEvent.id">

                      <!--<td>{{CompetitionEvent.id}}</td> -->
                      <td>{{CompetitionEvent.sort}}</td> 
                      <!--<td>{{CompetitionEvent.gen_event_est_time}}</td>-->
                      <td>{{CompetitionEvent.duration_in_second}}</td>
                      <td>{{CompetitionEvent.event_group_title}}</td>
                      <td>{{CompetitionEvent.event_type_title}}</td>
                      <td>{{CompetitionEvent.event_age_group_title}}</td>
                      <td>{{CompetitionEvent.dance}}</td>
                      <td>{{CompetitionEvent.round}}</td>
                      <!--<td>{{CompetitionEvent.zone}}</td>-->
                      <td>{{CompetitionEvent.count_registrations}}</td>
                      <!-- <td><img v-bind:src="'/' + CompetitionEvent.photo" width="100" alt="CompetitionEvent"></td> -->
                      <td>
                        
                        <a href="#" @click="editModal(CompetitionEvent)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteCompetitionEvent(CompetitionEvent.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        /
                        <a v-if="!(index == 0 && competitionEvents.current_page == 1)" href="#" @click="swapSortCompetitionEvent((competitionEvents.current_page-1)*competitionEvents.per_page+index-1)">
                            <i class="fas fa-arrow-circle-up green"></i>
                        </a>
                        <a v-if="index == 0 && competitionEvents.current_page == 1" href="#">
                            <i class="fas fa-circle green"></i>
                        </a>
                        /
                        <a v-if="!(competitionEvents.current_page == competitionEvents.last_page && competitionEvents.total % competitionEvents.per_page == index+1)" href="#" @click="swapSortCompetitionEvent((competitionEvents.current_page-1)*competitionEvents.per_page+index)">
                            <i class="fas fa-arrow-circle-down purple"></i>
                        </a>
                        <a v-if="competitionEvents.current_page == competitionEvents.last_page && competitionEvents.total % competitionEvents.per_page == index+1" href="#">
                            <i class="fas fa-circle purple"></i>
                        </a>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="competitionEvents" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Competition Event</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Competition Event</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateCompetitionEvent() : createCompetitionEvent()">
                    <div class="modal-body">

                        <input v-model="form.competition_id" type="hidden" name="competition_id"
                              class="form-control" :class="{ 'is-invalid': form.errors.has('competition_id') }">


                        <div class="form-group">
                            <label>Sort</label>
                            <input v-model="form.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sort') }">
                            <has-error :form="form" field="sort"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Group</label>
                            <select name="event_group_id" @change="loadEventAgeGroups()" v-model="form.event_group_id" id="event_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_group_id') }">
                                <option v-for="option in eventGroupItems" v-bind:value="option.event_group_id" v-bind:key="option.event_group_id" v-bind:selected="option.event_group_id == form.event_group_id" >
                                    [{{ option.sort }}] {{ option.title }}
                                </option>
                            </select>
                            <has-error :form="form" field="event_group_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Type</label>
                            <select
                                v-if="editmode == true"
                                name="event_type_id" @change="loadEventAgeGroups()" v-model="form.event_type_id" id="event_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_type_id') }">
                                <option v-for="option in eventTypeItems" v-bind:value="option.event_type_id" v-bind:key="option.event_type_id" v-bind:selected="option.event_type_id == form.event_type_id" >
                                    [{{ option.sort }}] {{ option.title }} - {{ option.dance }}
                                </option>
                            </select>
                            <select
                                v-if="editmode == false"
                                name="event_type_id" @change="loadEventAgeGroups()" v-model="form.event_type_id" id="event_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_type_id') }" multiple>
                                <option v-for="option in eventTypeItems" v-bind:value="option.event_type_id" v-bind:key="option.event_type_id" v-bind:selected="option.event_type_id == form.event_type_id" >
                                    [{{ option.sort }}] {{ option.title }} - {{ option.dance }}
                                </option>
                            </select>
                            <has-error :form="form" field="event_type_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Age Group</label>
                            <select name="event_age_group_id" v-model="form.event_age_group_id" id="event_age_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_age_group_id') }" multiple>
                                <option v-for="option in eventAgeGroupItems" v-bind:value="option.event_age_group_id" v-bind:key="option.event_age_group_id" v-bind:selected="option.event_age_group_id == form.event_age_group_id" >
                                    [{{ option.sort }}] {{ option.title }}
                                </option>
                            </select>
                            <has-error :form="form" field="event_age_group_id"></has-error>
                        </div>
<!--
                        <div class="form-group">
                            <label>Scheduled Time</label>
                            <input v-model="form.gen_event_est_time" type="text" name="gen_event_est_time"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('gen_event_est_time') }">
                            <has-error :form="form" field="gen_event_est_time"></has-error>
                        </div>
-->
<!--
                        <div class="form-group">
                            <label>Zone</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-round"
                                v-model="form.zone"
                                :options="zone_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>    
-->
                        <div class="form-group">
                            <label>Round</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-round"
                                v-model="form.round"
                                :options="round_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>                    
                        <div class="form-group">
                            <label>Open For Registration</label>

                            <b-form-group
                              label=""
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-isopenreg"
                                v-model="form.isopenreg"
                                :options="isopenreg_options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="outline-primary"
                                name="radio-btn-outline"
                                buttons
                              ></b-form-radio-group>
                            </b-form-group>

                        </div>    

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- End Modal -->
        <!-- Modal -->
        <div class="modal fade" id="timeSch" tabindex="-1" role="dialog" aria-labelledby="genSch" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Generate Time Schedule</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="timeSchCompetitionEvent()">
                    <div class="modal-body">

                        <input v-model="formTimeSch.competition_id" type="hidden" name="competition_id"
                              class="form-control" :class="{ 'is-invalid': form.errors.has('competition_id') }">

                        <template>

                        <div class="form-group">
                            <label>Starting Sort Number</label>
                            <input v-model="formTimeSch.sort" type="text" name="sort"
                                class="form-control" :class="{ 'is-invalid': formTimeSch.errors.has('sort') }">
                            <has-error :form="formTimeSch" field="sort"></has-error>
                        </div>

                        <div class="form-group">

                            <label>Start Time[HH:MM]</label>

                            <b-form-group
                              label=""
                              
                            >
                              <b-input-group left="Mobile*">
                                <b-form-input
                                  type="text"
                                  v-model="formTimeSch.startTime"
                                  v-mask="'##:##'"
                                  placeholder="__:__" />
                              </b-input-group>                        

                            </b-form-group>

                        </div>
                        </template>

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-success">Run Scheduling</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <!-- End Modal -->

    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';
    import Multiselect from 'vue-multiselect';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge, Multiselect
        },
        data () {
            return {
                editmode: false,
                competitionEvents : {},
                competition_id : '',
                form: new Form({
                    id : '',
                    sort : '',
                    competition_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  [],
                    isopenreg: '',
                    round: '',  
                    zone: '',             
                    gen_event_est_time: '',     
                }),
                formTimeSch: new Form({
                  competition_id:'',
                  sort:'',
                  startTime:'',
                }),
                iscompetitionopen_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],
                round_options: [
                    { text: 'Final', value: 'final' },
                    { text: 'Semi-Final', value: 'semi-final' },
                    { text: 'Heats', value: 'heats' },
                    { text: 'Show', value: 'show' },
                  ],
                isopenreg_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],     
                zone_options: [
                    { text: 'Empty', value: '' },
                    { text: 'A', value: 'A' },
                    { text: 'B', value: 'B' },
                    { text: 'C', value: 'C' },
                    { text: 'D', value: 'D' },
                  ],                                   
                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                //tag:  '',
                //autocompleteItems: [],
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('/api/competitionEvent', { params: {'page' : page, competition_id: this.$route.params.competition_id}}).then(({ data }) => (this.competitionEvents = data.data));

              this.$Progress.finish();
          },
          loadCompetiionInfo(){

            axios.post('/api/competition/getCompetitionInfo', { competition_id : this.$route.params.competition_id})
                .then(response => {
                  var $title = "["+response.data.data.organiser+"] / " +
                               "["+response.data.data.competition_date+"] / " +
                               "["+response.data.data.name1+"] - ";
                  $('#competition_title').html($title);

                });

          },
          loadEventGroups(){
              if(this.$gate.isAdmin()){
                axios.post("/api/competitionEvent/getEventGroupInfo").then(({ data }) => (this.eventGroupItems = data.data));
              }
          },     
          loadEventAgeGroups(){
              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.form.competition_id,
                  'event_group_id' : this.form.event_group_id,
                  'event_type_id' : this.form.event_type_id,
                  'iseditmode' : this.editmode,
                };

                this.$Progress.start();

                axios.post("/api/competitionEvent/getEventAgeGroupInfo",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));

                this.$Progress.finish();    


              }
          },     
          loadEventTypes(){
              if(this.$gate.isAdmin()){
                axios.post("/api/competitionEvent/getEventTypeInfo").then(({ data }) => (this.eventTypeItems = data.data));
              }
          },     
          loadMaxSort(){
              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.form.competition_id,
                };
                axios.post("/api/competitionEvent/getMaxSort",$postdata).then(({ data }) => (this.form.sort = (data.data) ));
              }
          },            
          loadCompetitionEvents(){
            if(this.$gate.isAdmin()){
              var sel_page = 1;
              if( this.competitionEvent !== undefined ){
                sel_page = this.competitionEvent.current_page;
              }
              axios.get("/api/competitionEvent",{ params: {'page' : sel_page, competition_id: this.$route.params.competition_id}} ).then(({ data }) => (this.competitionEvents = data.data));
            }
          },
          swapSortCompetitionEvent($swapindex){

              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.competition_id,
                  'swap_index' : $swapindex,
                };

                this.$Progress.start();
                axios.post('/api/competitionEvent/swapSort',$postdata)
                .then((data) => {

                  if(data.data.success){

                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });

                    this.loadCompetitionEvents();

                    this.$Progress.finish();


                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                  }

                })
                .catch(() => {
                    this.$Progress.fail();
                });

              }            
          },
          editModal(CompetitionEvent){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(CompetitionEvent);

              this.$Progress.start();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.$Progress.finish();    

          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              this.form.competition_id = this.$route.params.competition_id;
              this.form.isopenreg = true;
              this.form.round = 'final';
              $('#addNew').modal('show');

              this.$Progress.start();
              this.loadMaxSort();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.$Progress.finish();              

          },
          newTimeSchModal(){
              this.formTimeSch.reset();
              this.formTimeSch.competition_id = this.$route.params.competition_id;
              this.formTimeSch.startTime = "10:00";
              this.formTimeSch.sort = "1";
              $('#timeSch').modal('show');
          },
          timeSchCompetitionEvent(){
              this.$Progress.start();
              
              axios.post('/api/competitionEvent/timeScheduling', {
                      'competition_id' : this.formTimeSch.competition_id,
                      'startTime' : this.formTimeSch.startTime,
                      'sort' : this.formTimeSch.sort,
                    })
                  .then(response => {

                      $('#timeSch').modal('hide');

                      Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });

                      this.loadCompetitionEvents();

                      this.$Progress.finish();

                  });

          },
          getSchedule(){

                var $postdata = {
                  'competition_id' : this.competition_id,
                };

                this.$Progress.start();
                axios.post('/api/competitionEvent/getCompetitionSchedule',$postdata)
                .then((data) => {

                });

          },
          createCompetitionEvent(){
              this.$Progress.start();

              this.form.post('/api/competitionEvent')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });

                  this.loadCompetitionEvents();

                  this.$Progress.finish();


                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateCompetitionEvent(){
              this.$Progress.start();
              this.form.put('/api/competitionEvent/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });

                  this.loadCompetitionEvents();
                  this.loadEventGroups();

                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteCompetitionEvent(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/competitionEvent/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your event has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadCompetitionEvents();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('CompetitionEvent Events - Component mounted.')
            //console.log(this.$route.params.competition_id);
            this.competition_id = this.$route.params.competition_id;
            this.loadCompetiionInfo();
        },
        created() {
            this.$Progress.start();

            this.loadCompetitionEvents();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
