<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">User List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">

                <b-row>
                  <b-col lg="6" class="my-1">                
                    <b-form-group
                      label="Filter"
                      label-for="users_filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="users_filter-input"
                          v-model="users_filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!users_filter" @click="users_filter = ''">Clear</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>     
                  </b-col>
                </b-row>

                <div>

                <b-table
                  :items="users.data"
                  :fields="users_fields"
                  :sort-by.sync="users_sortBy"
                  :sort-desc.sync="users_sortDesc"
                  :filter="users_filter"
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                  ref="users_Table"
                >

                  <template #cell(type)="row">
                    <span class="text-capitalize">{{row.item.type}}</span>
                  </template>
                  <template #cell(name)="row">
                    <span class="text-capitalize">{{row.item.name}}</span>
                  </template>
                  <template #cell(email_verified_at)="row">
                    <span :inner-html.prop="row.item.email_verified_at | yesno"></span>
                  </template>

                  <template #cell(actions)="row">
                    <a href="#" @click="editModal(row.item)">
                        <i class="fa fa-edit blue"></i>
                    </a>
                    /
                    <a href="#" @click="deleteUser(row.item.id)">
                        <i class="fa fa-trash red"></i>
                    </a>
                  </template>

                </b-table>
                </div>

                <!--
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>DOB</th>
                      <th>Organisation</th>
                      <th>Email Verified?</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">

                      <td>{{user.id}}</td>
                      <td class="text-capitalize">{{user.type}}</td>
                      <td class="text-capitalize">{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.dob}}</td>
                      <td>{{user.short_name}}</td>
                      <td :inner-html.prop="user.email_verified_at | yesno"></td>
                      <td>{{user.created_at}}</td>

                      <td>

                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                   </tbody>
                </table>
                   -->
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Chinese Name</label>
                            <input v-model="form.chi_name" type="text" name="chi_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('chi_name') }">
                            <has-error :form="form" field="chi_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nick Name</label>
                            <input v-model="form.nick_name" type="text" name="nick_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nick_name') }">
                            <has-error :form="form" field="nick_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Contact Number</label>
                            <input v-model="form.phone" type="text" name="phone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date of birth</label>
                            <input v-model="form.dob" type="date" name="dob"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('dob') }">
                            <has-error :form="form" field="dob"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Organisation</label>
                            <select name="organisation_access_code" v-model="form.organisation_access_code" id="organisation_access_code" class="form-control" :class="{ 'is-invalid': form.errors.has('organisation_access_code') }">
                                <option v-for="option in organisationItems" v-bind:value="option.access_code" v-bind:key="option.access_code" v-bind:selected="option.access_code == form.organisation_access_code" >
                                    {{ option.name1 }}
                                </option>
                            </select>
                            <has-error :form="form" field="organisation_access_code"></has-error>
                        </div>

                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <!--<option value="admin">Admin</option>-->
                                <option value="organiser">Organiser</option>
                                <option value="user">Standard User</option>
                                <option value="parent">Parent</option>
                                <option value="opstaff">OpStaff</option>
                                <option value="nologin">No Login</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                users : {},
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    chi_name: '',
                    nick_name: '',
                    dob: '',
                    phone:'',
                    organisation_access_code: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                }),
                organisationItems: [],        
                
                
                users_selected: [],
                users_filter: null,
                users_filterOn: [],
                users_totalRows: 1,
                users_currentPage: 1,                
                users_sortBy : 'count_registrations',
                users_sortDesc : true,
                users : {},
                users_fields: [
                  
                  {
                    label: 'ID',
                    key: 'id',
                    sortable: true,
                  },
                  {
                    label: 'Type',
                    key: 'type',
                    sortable: true,
                  },
                  {
                    label: 'name',
                    key: 'name',
                    sortable: true,
                  },
                  {
                    label: 'Email',
                    key: 'email',
                    sortable: true,
                  },
                  {
                    label: 'Phone',
                    key: 'phone',
                    sortable: true,
                  },
                  {
                    label: 'DOB',
                    key: 'dob',
                    sortable: true,
                  },
                  {
                    label: 'Organisation',
                    key: 'organisation_access_code',
                    sortable: true,
                  },
                  {
                    label: 'Email Verified',
                    key: 'email_verified_at',
                    sortable: true,
                  },
                  {
                    label: 'Created',
                    key: 'created_at',
                    sortable: true,
                  },
                  {
                    label: 'Actions',
                    key: 'actions',
                    //sortable: true,
                  },
                  

                ],

            }
        },
        methods: {

            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));

                  this.$Progress.finish();
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },

          loadOrganisations(){
              if(this.$gate.isAdmin()){

                axios.get("/api/user/listOrganisationAccessCode").then(({ data }) => (this.organisationItems = data.data));

              console.log(this.organisationItems);
              }
          },          

          loadUsers(){
            this.$Progress.start();

            if(this.$gate.isAdmin()){
              axios.get("api/user").then(({ data }) => (this.users = data.data));
            }
            this.loadOrganisations();
            this.$Progress.finish();
          },
          
          createUser(){

              this.form.post('api/user')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadUsers();
            this.$Progress.finish();
        }
    }
</script>
