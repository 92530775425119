<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <!--
                  <router-link :to="{ path: '/organisationRegistrations'}">
                    <button type="button" class="btn btn-sm btn-primary">
                        <i class="fas fa-caret-square-left"></i>
                        Back
                    </button>
                  </router-link>                  
                  -->
                  <button type="button" class="btn btn-sm btn-primary" @click="switchScreen('competition')">
                      <i class="fas fa-caret-square-left"></i>
                      Back
                  </button>                     
                  <br>
                  <span id="organisation_name"></span> <br><span id="competition_title"></span> Registration <br>
                </h3>
                <div class="card-tools">
                  Grouping:
                  <b-button-group size="sm">
                      <b-button v-if="groupEventMode"  variant="outline-success" @click="switchGroupEventMode">By Users</b-button>
                      <b-button v-if="groupEventMode" variant="success">By Events</b-button>
                      <b-button v-if="!groupEventMode"  variant="success">By Users</b-button>
                      <b-button v-if="!groupEventMode" variant="outline-success" @click="switchGroupEventMode">By Events</b-button>
                  </b-button-group>


                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>

              <!-- /.card-header -->
              <div v-if="groupEventMode" class="card-body table-responsive p-0">
                
                <!--
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Event Group</th>
                      <th>Event Type</th>
                      <th>Dance</th>
                      <th>Age Group</th>
                      <th>Applicant</th>
                      <th>Partners</th>
                      <th>Fee</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(EventRegistration, index) in competitionEvents.data" :key="EventRegistration.id">

                      <td>{{EventRegistration.event_group_title}}</td>
                      <td>{{EventRegistration.event_type_title}}</td>
                      <td>{{EventRegistration.dance}}</td>
                      <td>{{EventRegistration.event_age_group_title}}</td>
                      <td>{{EventRegistration.reg_name}}</td>
                      <td>{{EventRegistration.partners}}</td>
                      <td>{{EventRegistration.fee}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(EventRegistration)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteEventRegistration(EventRegistration.id)">
                            <i class="fa fa-trash red"></i>
                        </a>

                      </td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{total}}</td>
                      <td></td>
                    </tr>                    
                  </tbody>
                </table>
                -->

                <b-row>
                  <b-col lg="6" class="my-1">                
                    <b-form-group
                      label="Filter"
                      label-for="competitionEvents_filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="competitionEvents_filter-input"
                          v-model="competitionEvents_filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!competitionEvents_filter" @click="competitionEvents_filter = ''">Clear</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>     
                  </b-col>
                </b-row>

                <div>
                <b-table
                  :items="competitionEvents.data"
                  :fields="competitionEvents_fields"
                  :filter="competitionEvents_filter"                  
                  primary-key="id"
                  responsive="sm"
                  stacked="sm"
                >

                  <template #cell(actions)="row">
                      <a href="#" @click="editModal(row.item)">
                          <i class="fa fa-edit blue"></i>
                      </a>
                      /
                      <a href="#" @click="deleteEventRegistration(row.item.id)">
                          <i class="fa fa-trash red"></i>
                      </a>
                  </template>

                </b-table>
                </div>

              </div>
              <!-- /.card-body -->
              <!-- /.card-header -->
              <div v-if="!groupEventMode" class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Chi Name</th>
                      <th>Email</th>
                      <th>DOB</th>
                      <th>Reg Nos.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(EventRegistration, index) in competitionEvents.data" :key="EventRegistration.id">

                      <td>{{EventRegistration.name}}</td>
                      <td>{{EventRegistration.chi_name}}</td>
                      <td>{{EventRegistration.email}}</td>
                      <td>{{EventRegistration.dob}}</td>
                      <td>{{EventRegistration.reg_nos}}</td>
                      <!--<td>{{EventRegistration.count_partners}}</td>-->
                      <!-- <td><img v-bind:src="'/' + EventRegistration.photo" width="100" alt="EventRegistration"></td> -->
                      <td>
                        <!--
                        <a href="#" @click="editModal(EventRegistration)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteEventRegistration(EventRegistration.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        -->

                      </td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{total}}</td>
                      <td></td>
                    </tr>                    
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->


              <div class="card-footer">
                  <pagination :data="competitionEvents" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Registration</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Registration</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="onCloseModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateEventRegistration() : createEventRegistration()">
                    <div class="modal-body">

                        <input v-model="form.competition_id" type="hidden" name="competition_id">
                        <input v-model="form.id" type="hidden" name="id">

                        <!--
                        <div class="form-group">
                            <label>Your Date of Birth</label>
                            <input v-model="form.user_dob" id="user_dob" type="date" name="user_dob" class="form-control" disabled>
                        </div>
                        <div class="form-group">
                            <label>Competition Date</label>
                            <input v-model="form.competition_date" id="competition_date" type="date" name="competition_date" class="form-control" disabled>
                        </div>
                        <div class="form-group">
                            <label>Your Age </label>
                            <input v-model="form.user_age" id="user_age" type="text" name="user_age" class="form-control" disabled>
                        </div>
                        -->

                        <div class="form-group">
                            <label>Applicant </label>
                            <vue-tags-input
                              v-model="reg_user_id"
                              placeholder="Add Nick Name"
                              :tags="form.reg_user_ids"
                              :add-only-from-autocomplete="true"
                              :autocomplete-items="filteredApplicantItems"
                              :max-tags="1"
                              @tags-changed="newTags => updateApplicants(newTags)"
                            />
                            <has-error :form="form" field="reg_user_ids"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Group</label>
                            <select name="event_group_id" @change="loadEventAgeGroups()" v-model="form.event_group_id" id="event_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_group_id') }">
                                <option v-for="option in eventGroupItems" v-bind:value="option.event_group_id" v-bind:key="option.event_group_id" v-bind:selected="option.event_group_id == form.event_group_id" >
                                    {{ option.title }}
                                </option>
                            </select>
                            <has-error :form="form" field="event_group_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Type</label>
                            <select name="event_type_id" @change="loadEventAgeGroups()" v-model="form.event_type_id" id="event_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_type_id') }">
                                <option v-for="option in eventTypeItems" v-bind:value="option.event_type_id" v-bind:key="option.event_type_id" v-bind:selected="option.event_type_id == form.event_type_id" >
                                    {{ option.title }} - {{ option.dance }}
                                </option>
                            </select>
                            <has-error :form="form" field="event_type_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Event Age Group</label>
                            <select name="event_age_group_id" @change="onChangeEventAgeGroup" v-model="form.event_age_group_id" id="event_age_group_id" class="form-control" :class="{ 'is-invalid': form.errors.has('event_age_group_id') }">
                                <option v-for="option in eventAgeGroupItems" v-bind:value="option.event_age_group_id" v-bind:key="option.event_age_group_id" v-bind:selected="option.event_age_group_id == form.event_age_group_id" >
                                    {{ option.title }} [{{ option.age_min }} - {{ option.age_max }}]
                                </option>
                            </select>
                            <has-error :form="form" field="event_age_group_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Competition Event ID</label>
                            <input v-on:keyup="onEnterCompetitionEventIDLookup" v-model="form.competition_event_id" id="competition_event_id" type="text" name="competition_event_id" class="form-control">
                        </div>                        

                        <div class="form-group" v-show="max_partners > 1">
                            <label>Group Name</label>
                            <input v-model="form.group_name" id="group_name" type="text" name="group_name" class="form-control">
                        </div>

                        <div class="form-group" v-show="isPerformanceOptionShow">
                            <label>Performance Title 表演名稱</label>
                            <input v-model="form.performance_title" id="performance_title" type="text" name="performance_title" class="form-control">
                        </div>
                        <div class="form-group" v-show="isPerformanceOptionShow">
                            <label>Performance Description 表演簡介</label>
                            <input v-model="form.performance_description" id="performance_description" type="text" name="performance_description" class="form-control">
                        </div>
                        <div class="form-group" v-show="isPerformanceOptionShow">
                            <label>Estimated Performance Time (Seconds) 預計時間</label>
                            <input v-model="form.performance_durationInSeconds" id="performance_durationInSeconds" type="text" name="performance_durationInSeconds" class="form-control">
                        </div>
                        <div class="form-group" v-show="isPerformanceOptionShow">
                          <label>Performance Music (Max 8MB)</label>
                          <b-form-file v-model="form.performance_music_path" name="performance_music_path" accept="audio/*"></b-form-file>
                        </div>                        

                        <div class="form-group" v-show="isPerformanceMusicLoaded">
                            <label>Performance Music Preivew</label>
                            <audio ref="player" controls="" style="vertical-align: middle">
                                <source v-bind:src="performance_music_url">
                                Your browser does not support the audio element.
                            </audio>                            
                        </div>                               

                        <div class="form-group">
                            <label>Partner / Teamate <b-badge variant="success"><span id="max_ppl"></span></b-badge> </label>
                            <vue-tags-input
                              v-model="partner"
                              placeholder="Add Nick Name"
                              :tags="form.partners"
                              :add-only-from-autocomplete="true"
                              :autocomplete-items="filteredItems"
                              :max-tags="max_partners"
                              @tags-changed="updatePartners => form.partners = updatePartners"
                            />
                            <has-error :form="form" field="partners"></has-error>
                        </div>

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="onCloseModal" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import { BFormRadioGroup, BFormGroup, BBadge } from 'bootstrap-vue';

    export default {
      components: {
          VueTagsInput, BFormRadioGroup, BFormGroup, BBadge
        },
        data () {
            return {
                groupEventMode: true,
                editmode: false,
                competition_id : '',
                competiionInfo : {},
                user_dob : '',

                competitionEvents_filter : null,
                competitionEvents : {},
                competitionEvents_fields:[
                  {
                    label: 'ID',
                    key: 'competition_event_id',
                    sortable: true,
                  },
                  {
                    label: 'Event Group',
                    key: 'event_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Event Type',
                    key: 'event_type_title',
                    sortable: true,
                  },
                  {
                    label: 'Dance',
                    key: 'dance',
                    sortable: true,
                  },
                  {
                    label: 'Age Group',
                    key: 'event_age_group_title',
                    sortable: true,
                  },
                  {
                    label: 'Applicant',
                    key: 'reg_name',
                    sortable: true,
                  },
                  {
                    label: 'Partners',
                    key: 'partners',
                    sortable: true,
                  },
                  {
                    label: 'Fee',
                    key: 'fee',
                    sortable: true,
                  },
                  {
                    label: 'Action',
                    key: 'actions',
                    //sortable: true,
                  },                                      
                ],


                performance_music_url:'',
                isPerformanceOptionShow: false,                
                isPerformanceMusicLoaded: false, 

                form: new Form({
                    id : '',
                    sort : '',
                    user_dob : '',
                    competition_date : '',
                    user_age : '',
                    competition_id : '',
                    competition_event_id : '',
                    event_group_id: '',
                    event_type_id: '',
                    event_age_group_id:  '',
                    reg_user_ids: [],
                    group_name: '',
                    performance_title:'',
                    performance_description:'',
                    performance_music_path:[],
                    performance_durationInSeconds:'',                    
                    partners : [],
                    reg_user_id : '',
                }),
                iscompetitionopen_options: [
                    { text: 'Open For Registration', value: 'true' },
                    { text: 'Close', value: 'false' },
                  ],
                eventGroupItems : [],
                eventAgeGroupItems : [],
                eventTypeItems : [],
                //categories: [],
                partner:  '',
                reg_user_id: '',
                autocompleteItems: [],
                autocompleteApplicantItems: [],
                max_partners: 0,
                debounce: null, 
                
                return_path:'',                   

            }
        },

        watch: {
          'partner': 'loadPartners',
          'reg_user_id': 'loadApplicant',
        },        
        methods: {

          switchScreen(screen_id, competition_id = -1){
              this.sel_competition_id = competition_id;
              switch( screen_id ){
                  case 'competition':

                    if( this.return_path !== undefined ){
                      this.$router.push('/' + this.return_path )                      
                    }                    
                    this.loadCompetitions();
                    break;
                  default:
                    this.$router.push('/organisationRegistrations' );
                    break;
              }
              this.displaymode = screen_id;
          },

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('/api/eventRegistration', { 
                        params: {
                          'page' : page, 
                          'competition_id': this.$route.params.competition_id,
                          'groupEventMode' : this.groupEventMode, 
                        }}
                ).then(({ 
                          data }) => (this.competitionEvents = data.data)
                );

              this.$Progress.finish();
          },
          loadEventRegistrations(){
            //if(this.$gate.isAdmin()){
              
              var sel_page = 1;
              if( this.competitionEvent !== undefined ){
                sel_page = this.competitionEvent.current_page;
              }
              //axios.get("/api/eventRegistration",{ params: {'page' : sel_page, competition_id: this.$route.params.competition_id, 'groupEventMode' : this.groupEventMode}} ).then(({ data }) => (this.competitionEvents = data.data));

              axios.get('/api/eventRegistration', { 
                        params: {
                          'page' : sel_page, 
                          'competition_id': this.$route.params.competition_id,
                          'groupEventMode' : this.groupEventMode, 
                        }}
                ).then(({ 
                          data }) => (this.competitionEvents = data.data)
                );



            //}
          },  
          switchGroupEventMode(){
            this.groupEventMode = !this.groupEventMode;
            this.getResults();
          },       
          loadCompetiionInfo(){

            axios.post('/api/competition/getCompetitionInfo', { competition_id : this.$route.params.competition_id})
                .then(response => {
                  var $title = "["+response.data.data.organiser+"] / " +
                               "["+response.data.data.competition_date+"] / " +
                               "["+response.data.data.name1+"] - ";
                  this.competiionInfo = response.data.data;
                  $('#competition_title').html($title);

                });

            axios.get("/api/profile/getDob").then(
                ({ data }) => {
                    this.user_dob = data.data;
                }
            );            

            axios.get("/api/user/getUserOrganisationName").then(({ data }) => {$('#organisation_name').html('['+data.data.name+']');});

          },
          loadEventGroups(){

            if( this.editmode ){
              var $postdata = {
                'event_group_id' : this.form.event_group_id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfo",$postdata).then(({ data }) => (this.eventGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,
              };

              axios.post("/api/eventRegistration/getEventGroupInfoO",$postdata).then(({ data }) => (this.eventGroupItems = data.data));

            }
          },     
          loadEventAgeGroups(){

            if( this.editmode ){
              var $postdata = {
                'event_age_group_id' : this.form.event_age_group_id,
              };

              axios.post("/api/eventRegistration/getEventAgeGroupInfo",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));
            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'event_type_id' : this.form.event_type_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                 
              };

              this.$Progress.start();
              this.loadEventTypes();
              axios.post("/api/eventRegistration/getEventAgeGroupInfoO",$postdata).then(({ data }) => (this.eventAgeGroupItems = data.data));

            }


            this.$Progress.finish();    

          },     
          loadEventTypes(){

            if( this.editmode ){
              var $postdata = {
                'event_type_id' : this.form.event_type_id,
              };

              axios.post("/api/eventRegistration/getEventTypeInfo",$postdata).then(({ data }) => {
                this.eventTypeItems = data.data;

                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );

                    if( a.grade_mode == 'rank'){
                      this.isPerformanceOptionShow = false;
                    }else{
                      this.isPerformanceOptionShow = true;
                    }                    
                  }
                });

              });

            }else{

              if( this.form.reg_user_ids.length < 1 ){
                return;
              }

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                
              };

              axios.post("/api/eventRegistration/getEventTypeInfoO", $postdata).then(({ data }) => {
                this.eventTypeItems = data.data;
                this.eventTypeItems.map( a => {
                  if( this.form.event_type_id == a.event_type_id ){
                    this.max_partners = parseInt(a.ppl)-1;
                    $('#max_ppl').html('Max. Partners: ' + this.max_partners );
                  }
                  if( a.grade_mode == 'rank'){
                    this.isPerformanceOptionShow = false;
                  }else{
                    this.isPerformanceOptionShow = true;
                  }                             
                });

              });
            }



          },     
          loadEventPartners(){

            if( this.editmode ){
              var $postdata = {
                'event_registration_id' : this.form.id,
              };


              axios.post("/api/eventRegistration/getEventRegistrationPlayerU", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $partner_input = [];

                  this.autocompleteItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $partner_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $partner_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });

                  this.form.partners = $partner_input;
              })

              axios.post("/api/eventRegistration/getEventRegistrationApplicantO", $postdata).then(response => {
                  //this.form.partners = response.data;
                  var $applicant_input = [];

                  this.autocompleteApplicantItems = response.data.data.map(a => {

                      if(this.$gate.isAdminOrOrganiser()){              
                        $applicant_input.push({ text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name });
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        $applicant_input.push({ text: a.nick_name, id: a.nick_name });
                        return { text: a.nick_name, id: a.nick_name };
                      }
                  });

                  this.form.reg_user_ids = $applicant_input;
              })


              //axios.post("/api/eventRegistration/getEventRegistrationPlayerU",$postdata).then(({ data }) => (this.form.partners = data.data));
            }else{
            }

          },    
          onChangeEventAgeGroup(e){
            //this.competition_event_id = 
            this.eventAgeGroupItems.forEach((item,index)=>{
              console.log(item);
              if( item.event_age_group_id == e.target.value ){
                this.form.competition_event_id = item.competition_event_id;
              }
            });
          },
          onEnterCompetitionEventIDLookup(e){
            
            if( e.keyCode == 107 && this.form.event_group_id == "" ){

              this.form.competition_event_id = this.form.competition_event_id.substring(0,this.form.competition_event_id.length-1);

              var $postdata = {
                'competition_id' : this.form.competition_id,
                'event_group_id' : this.form.event_group_id,
                'event_type_id' : this.form.event_type_id,
                'reg_user_id' : this.form.reg_user_ids[0].id,                      
                'competition_event_id' : this.form.competition_event_id,
              };              
              axios.post("/api/eventRegistration/lookupCompetitionEventId", $postdata).then(response => {

                this.eventGroupItems = response.data.data.group;
                if( this.eventGroupItems != null && this.eventGroupItems.length > 0 ){
                  this.form.event_group_id = this.eventGroupItems[0].event_group_id;
                }

                this.eventTypeItems = response.data.data.type;

                if( this.eventTypeItems != null && this.eventTypeItems.length > 0 ){

                  this.form.event_type_id = this.eventTypeItems[0].event_type_id;
                  
                  this.eventTypeItems.map( a => {
                    if( this.form.event_type_id == a.event_type_id ){
                      this.max_partners = parseInt(a.ppl)-1;
                      $('#max_ppl').html('Max. Partners: ' + this.max_partners );

                      if( a.grade_mode == 'rank'){
                        this.isPerformanceOptionShow = false;
                      }else{
                        this.isPerformanceOptionShow = true;
                      }                    
                    }
                  });
                  

                }


                this.eventAgeGroupItems = response.data.data.age;

                if( this.eventAgeGroupItems != null && this.eventAgeGroupItems.length > 0 ){
                  this.form.event_age_group_id = this.eventAgeGroupItems[0].event_age_group_id;
                }

              });              
            }
          },
          loadFormDefaultValues(){
              //this.form.user_dob = this.user_dob;
              //this.form.competition_date = this.competiionInfo.competition_date;
              //this.form.user_age = new Number( (new Date(this.competiionInfo.competition_date).getTime() - new Date(this.user_dob).getTime()) / 31536000000).toFixed(0);
          },   
          updatePartners(newTags) {
            this.autocompleteItems = [];
            this.partners = newTags;
          },          
          updateApplicants(newTags) {
            this.autocompleteApplicantItems = [];
            this.form.reg_user_ids = newTags;

            if( this.form.reg_user_ids.length > 0 ){
              this.form.reg_user_id = this.form.reg_user_ids[0].id;
            }

            this.eventGroupItems = [];
            this.eventTypeItems = [];
            this.eventAgeGroupItems = [];
            
            this.loadEventGroups();
          },          
          loadPartners(){

              if (this.partner.length < 2  ) return;

              var $postdata = {
                'partner_input' : this.partner,
              };

              clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                
                axios.post("/api/eventRegistration/getPartnerAList",$postdata).then(response => {
                    this.autocompleteItems = response.data.data.map(a => {
                      if(this.$gate.isAdminOrOrganiser()){              
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        return { text: a.nick_name, id: a.nick_name };
                      }                      
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
              }, 500);

          },          
          loadApplicant(){

              if (this.reg_user_id.length < 2  ) return;

              var $postdata = {
                'partner_input' : this.reg_user_id,
              };

              clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                
                axios.post("/api/eventRegistration/getPartnerAList",$postdata).then(response => {
                    this.autocompleteApplicantItems = response.data.data.map(a => {
                      if(this.$gate.isAdminOrOrganiser()){              
                        return { text: a.name + ' [ Nickname: ' + a.nick_name + ' / DOB: ' + a.dob + ']', id: a.nick_name };
                      }else{
                        return { text: a.nick_name, id: a.nick_name };
                      }                      
                    });
                }).catch(() => console.warn('Oh. Something went wrong'));
              }, 500);

          },          
          swapSortCompetitionEvent($swapindex){

              if(this.$gate.isAdmin()){

                var $postdata = {
                  'competition_id' : this.competition_id,
                  'swap_index' : $swapindex,
                };

                this.$Progress.start();
                axios.post('/api/competitionEvent/swapSort',$postdata)
                .then((data) => {

                  if(data.data.success){

                    Toast.fire({
                          icon: 'success',
                          title: data.data.message
                      });

                    this.loadEventRegistrations();

                    this.$Progress.finish();


                  } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });

                    this.$Progress.failed();
                  }

                })
                .catch(() => {
                    this.$Progress.fail();
                });

              }            
          },
          editModal(EventRegistration){
              this.$Progress.start();
              this.editmode = true;
              this.form.reset();
              this.partner = '';
              this.partners = [];
              this.form.partners = [];
              this.autocompleteItems = [];
              this.isPerformanceOptionShow = false;              
              this.isPerformanceMusicLoaded = false;

              $('#addNew').modal('show');
              this.form.fill(EventRegistration);
              console.log(this.form);

              this.$Progress.start();
              this.loadFormDefaultValues();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.loadEventPartners();

              axios.post('/api/eventRegistration/getMusicFile/'+EventRegistration.id,{}, { responseType: 'blob' }).then((res) => { 

                const current_blob = new Blob([res.data],{type: res.headers['content-type']});
                //console.table(new Blob([res.data],{type: res.headers['content-type']}).type);
                //this.performance_music_url = URL.createObjectURL(current_blob);
                this.performance_music_url = URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                //this.performance_music_url = URL.createObjectURL(new Blob([res.data]));

                this.$refs.player.load();
                this.$refs.player.controls = true;
                this.isPerformanceMusicLoaded = true;

              });

              this.$Progress.finish();    

          },
          newModal(){

              if( !this.$gate.isAdmin() && this.competiionInfo.isopened == 'false' ){
                Toast.fire({
                    icon: 'error',
                    title: 'Sorry, registration is now closed!'
                });
                return;
              }

              this.editmode = false;
              this.form.reset();
              this.partner = '';
              this.partners = [];
              this.form.partners = [];
              this.autocompleteItems = []
              this.form.competition_id = this.$route.params.competition_id;

              this.eventGroupItems = [];
              this.eventTypeItems = [];
              this.eventAgeGroupItems = [];

              this.isPerformanceOptionShow = false;
              this.isPerformanceMusicLoaded = false;
              URL.revokeObjectURL(this.performance_music_url);
              this.performance_music_url = '';

              $('#addNew').modal('show');

              this.$Progress.start();
              this.loadFormDefaultValues();
              this.loadEventGroups();
              this.loadEventTypes();
              this.loadEventAgeGroups();
              this.$Progress.finish();              

          },
          onCloseModal(){
            this.$refs.player.pause();
            URL.revokeObjectURL(this.performance_music_url);
            this.performance_music_url = '';
          },          
          createEventRegistration(){

              if( this.form.reg_user_ids.length < 1 ){
                Toast.fire({
                    icon: 'error',
                    title: 'Applicant is missing!'
                });
                return;
              }

              if( this.max_partners != this.form.partners.length ){
                let $person_left = this.max_partners - this.form.partners.length;
                Toast.fire({
                    icon: 'error',
                    title: $person_left + ' partner(s) missing, please check!',
                });
                return;                
              }


              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'partners'){
                        postdata.append(k,this.form[k]);
                      }else{
                        postdata.append(k,JSON.stringify(this.form[k]));
                      }
                      //console.log("Key is " + k + ", value is " + this.form[k]);
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

             //console.log(postdata);

              axios.post('/api/eventRegistration',  postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.onCloseModal();

                      this.loadEventRegistrations();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else if(error.response.status == 413){
                        Toast.fire({
                            icon: 'error',
                            title: 'File Too Large',
                        }); 
                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message,
                        });                    
                      }
                      this.$Progress.fail();
                  });                  
              

              /*
              this.$Progress.start();

              this.form.post('/api/eventRegistration')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });

                  this.loadEventRegistrations();

                  this.$Progress.finish();


                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
              */
          },
          updateEventRegistration(){

              if( this.form.reg_user_ids.length < 1 ){
                Toast.fire({
                    icon: 'error',
                    title: 'Applicant is missing!'
                });
                return;
              }

              if( this.max_partners != this.form.partners.length ){
                let $person_left = this.max_partners - this.form.partners.length;
                Toast.fire({
                    icon: 'error',
                    title: $person_left + ' partner(s) missing, please check!',
                });
                return;                
              }              

              let postdata = new FormData();
              this.$Progress.start();

              for (var k in this.form){
                  if (this.form.hasOwnProperty(k)) {
                      if( k != 'partners'){
                        postdata.append(k,this.form[k]);
                      }else{
                        postdata.append(k,JSON.stringify(this.form[k]));
                      }
                  }
              }
              //postdata.append('competition_id',this.sel_competition_id);

              axios.post('/api/eventRegistration/updateEntry', postdata, 
                  {
                    headers: {
                        'Content-Type': 'multipart/form-data',              
                    },
                  })
                  .then(response => {

                      // success
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');

                      this.onCloseModal();

                      this.loadEventRegistrations();

                  })
                  .catch((error) => {

                      if(error.response.status == 422){
                        /*
                        console.log(Object.keys(error.response.data.errors));
                        console.log(error.response.data.errors[Object.keys(error.response.data.errors)[0]]);
                        */
                        /*
                        $.each(error.response.data.errors, function(key, value) {
                          this.form.errors[key] = value;
                        });
                        //this.form.errors = error.response.data.errors;
                        console.log(this.form.errors);
                        */
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.errors[Object.keys(error.response.data.errors)[0]],
                        });                    


                      }else if(error.response.status == 413){
                        Toast.fire({
                            icon: 'error',
                            title: 'File Too Large',
                        }); 
                      }else{
                        Toast.fire({
                            icon: 'error',
                            title: error.response.data.message,
                        });                    
                      }
                      this.$Progress.fail();
                  });                   

              /*
              this.$Progress.start();
              this.form.put('/api/eventRegistration/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });

                  this.loadEventRegistrations();
                  this.loadEventGroups();

                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

              })
              .catch(() => {
                  this.$Progress.fail();
              });
              */

          },
          deleteEventRegistration(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/eventRegistration/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'The registration has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadEventRegistrations();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            console.log('EventRegistration Events - Component mounted.')
            //console.log(this.$route.params.competition_id);
            this.competition_id = this.$route.params.competition_id;
            this.loadCompetiionInfo();
        },
        created() {
            this.$Progress.start();

            this.competition_id = this.$route.params.competition_id;
            this.return_path = this.$route.params.return_path;

            this.loadEventRegistrations();
            //this.loadCategories();
            //this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.partner.toLowerCase()) !== -1;
            });
          },
          filteredApplicantItems() {
            return this.autocompleteApplicantItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.reg_user_id.toLowerCase()) !== -1;
            });
          },
          total() {
            if( typeof this.competitionEvents.data === 'undefined')
              return;
            if( this.groupEventMode ){
              return this.competitionEvents.data.reduce((total, item) => {
                return (total + parseInt(item.fee));
              }, 0);
            }else{
              return this.competitionEvents.data.reduce((total, item) => {
                return (total + parseInt(item.reg_nos));
              }, 0);
            }
          }          
        },
    }
</script>
