<template>
    <section class="content">
        <div class="container-fluid">
            <div>
            <b-card-group deck>

                <b-card
                    v-for="Competition in competitions.data" :key="Competition.id"
                    :title="Competition.name1"
                    img-alt="Image"
                    :img-src="Competition.banner_base64"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
                >
                    <router-link :to="{ path: '/dashboard/' + Competition.id , params: { competition_id: Competition.id }}">
                        <b-button variant="primary">進入 Enter</b-button>
                    </router-link>
                    <b-card-text>
                        <span v-html="Competition.description"></span>
                    </b-card-text>

                </b-card>            
            </b-card-group>
            </div>

        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import { BCardGroup, BCard, BCardText, BButton } from 'bootstrap-vue';
    export default {
        components: {
            BCardGroup, BCard, BCardText, BButton
        },        
        data () {
            return {
                competitions : {},                
            }
        },
        methods: {

          loadCompetitions(){
            // if(this.$gate.isAdmin()){
              axios.get("api/competition/getCurrentCompetition").then(({ data }) => (this.competitions = data.data));
            // }
          },

        },

        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.loadCompetitions();            
        },
    }
</script>

<style scoped>
#event-frame-1{
  
  background-size: cover;
}
</style>
